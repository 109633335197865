import { ButtonSubmit, useTranslations, useCustomerCooptationCreate, useEnv, GenericDialog } from "@ephatta/components"
import { TClientIdentity } from "@ephatta/services"
import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material"
import { FormEvent, useEffect, useState } from "react"
import CustomDialog, { CustomDialogProps } from "../../../components/shared/CustomDialog"
import CustomInput from "../../../components/shared/CustomInput"
import { useCustomerCooptationFormData } from "../hooks/useCustomerCooptationFormData"

export const InviteFriendByEmailDialog = (props: Props) => {

    const theme = useTheme()

    // ============ TRANSLATION ============
    const [
        INVITE_YOUR_FRIENDS_BY_EMAIL_POPUP_TITLE,
        SENDS_INVITATION_TO_YOUR_FRIENDS,
    ] = useTranslations(i18nKeys)
    // ============ END TRANSLATION ========

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");

    const env = useEnv<TClientIdentity>()
    const { formData, getTextFieldProps, isValid, setFormData } = useCustomerCooptationFormData()
    const createMutation = useCustomerCooptationCreate()

    useEffect(() => {
        if (createMutation.isSuccess) {
            createMutation.reset()
            setFormData({
                fromId: env.userId,
                toEmail: '',
                type: ''
            })
            props.onSubmited?.()
        }
    }, [createMutation])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isValid()) {
            createMutation.mutate(formData)
        }
    }

    return (
        <CustomDialog
            {...props}
            maxWidth={'md'}
            fullWidth={true}
            title={INVITE_YOUR_FRIENDS_BY_EMAIL_POPUP_TITLE}
            titleProps={{
                alignFlex: 'flex-start',
            }}
        >
            <form noValidate onSubmit={handleSubmit}>
                <Box>
                    <Stack
                        spacing={1}
                        alignItems={'stretch'}
                        justifyContent={'space-between'}
                        direction={isMedium ? 'column' : 'row'}
                    >
                        <Box
                            className={'full-width'}
                        >
                            <CustomInput
                                placeholder={'exemple@gmail.com'}
                                fullWidth={true}
                                borderColor={theme.palette.primary.main}
                                {...getTextFieldProps('toEmail')}
                            ></CustomInput>
                        </Box>

                        <ButtonSubmit
                            type="submit"
                            variant={'contained'}
                            color={'secondary'}
                            size={'large'}
                            isSubmit={createMutation.isLoading}
                            sx={{
                                color: 'white',
                                fontWeight: 'bold',
                                width: isMedium ? '100%' : '400px',
                                padding: { xs: 'auto', md: '0px 20px' },
                                fontSize: isSmall ? '12px' : '16px'
                            }}
                        >
                            { SENDS_INVITATION_TO_YOUR_FRIENDS }
                        </ButtonSubmit>
                    </Stack>
                </Box>
            </form>
        </CustomDialog>
    )
}

const i18nKeys = [
    "RegistrationNotMicasa.Page.FindFriend.InviteByMail",
    "RegistrationNotMicasa.Page.FindFriend.SendAnInvitation",
]

type Props = Omit<CustomDialogProps, 'children'> & {
    onSubmited?: () => void
}
