import { useTranslation } from 'react-i18next';
import { TMangoPayAccountPayoutPayload } from "@ephatta/services"
import { useEphattaMutation } from "hooks"
import { useMangoPayService } from "../useMangoPayService"

export const useMangoPayPayout = () => {

    const service = useMangoPayService()
    const {t} = useTranslation()
    
    return useEphattaMutation((payload: TMangoPayAccountPayoutPayload) => {
        return service.payout(payload)
    }, 
    {
        showSuccessMessage: true,
        successMessage: t("Page.MyWallet.MyJackPot.Payout.Success.Label")
    })
}
