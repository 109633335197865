import { atom } from 'recoil';

/**
 * Selectors are re-run when an atom/selector it uses (depends on) changes.
 * Use this atom in the selector to force update it.
 * See Environment.ts to check how to do it.
 */
export const ForceReloadEnv = atom({
    key: "User.Env.ForceReloadEnv",
    default: 0
});