import { useEphattaMutation, useTranslations } from "hooks"
import { useCustomerRelationshipService } from "./useCustomerRelationshipService"

export const useCustomerRelationshipAffiliation = () => {
    const [
        ERROR_MESSAGE
    ] = useTranslations(i18n)
    const service = useCustomerRelationshipService()
    return useEphattaMutation(
        async (payload: any) => {
            const result = await service.affiliateUser(payload)
            if(!result){
                throw 'AFFILIATION_ERROR'
            }
        },
        {
            errorMessage: ERROR_MESSAGE
        }
    )
}

const i18n = [
    "Generic.Components.Affiliation.FillCode.ErrorMessage"
]