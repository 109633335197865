import '@ephatta/components/dist/index.css'
import { Root } from '@ephatta/components'
import Routes from './pages/Routes'
import { Theme } from './config/Theme'
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import 'react-phone-number-input/style.css'
import { es , fr } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css/thumbs";
import "swiper/css/free-mode";

import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DndProvider backend={HTML5Backend}>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                    <Root 
                        theme={Theme}
                        loginPath="/login"
                        initialPath="/"
                        routes={Routes}
                        loggedRoutes={loggedRoutes}
                        loggedUnAuthorizedRootes={loggedUnAuthorizedRootes} 
                        mappingRoutes={routeMapping}
                        />
                </GoogleOAuthProvider>
                
            </DndProvider>
        </LocalizationProvider>
    )
}

const loggedRoutes: Array<string> = [
    '/myprofile',
    '/advert/create',
    '/my-favorites',
    '/my-favorites/:id',
    '/my-network',
    '/registration-not-micasa',
    '/my-announcements',
    '/my-reservations',
    '/my-profile',
    '/my-inbox',
    '/my-inbox/:id',
    '/my-wallet',
    '/public-profile/:id',
    '/advert/availability/:id',
    '/advert/subscription/:id',
    '/advert/:id',
    '/find-casa',
    '/email-confirm'
]

const loggedUnAuthorizedRootes = [
    '/login',
    '/forgot-password'
]

const routeMapping = {
    '/estates/:id': '/advert/:id',
    '/estate/:id': '/advert/:id',
    '/public/estate/:id': '/advert/:id'
}

export default App;
