import { TLoginRequest } from "@ephatta/services";
import { useEphattaMutation } from "../..";
import { useLoginService } from "./useLoginService";

export const useLogin = () => {
  const loginService = useLoginService();

  return useEphattaMutation(
    (payload: TLoginRequest) =>
      loginService.login({
        ...payload,
        applicationId: parseInt(process.env.REACT_APP_ID!),
      }),
    {
      log: (data, variables): string | {message: string, userId: string} | false => {
        return {
          message: `Nouvelle connexion: <a href="/mo-user">${variables?.userName}</a>`,
          userId: (data as any).getProfilResult.value.userId
        }
      },
    }
  );
};
