import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAdvertEnvironments } from "./useAdvertEnvironments"

export const useAdvertEnvironmentOptions = () => {

    const { i18n } = useTranslation()
    const { data: options } = useAdvertEnvironments()

    return useMemo((): Array<TAdvertEnvironmentOptions> => {
        return options?.sort((item1, item2) => {
            const position1 = item1.values.find((item) => item.culture === i18n.language)?.position || 0
            const position2 = item2.values.find((item) => item.culture === i18n.language)?.position || 0
            return position1 - position2
        })?.map((item) => ({ 
            value: item.id, 
            label: item.values.find((item1) => item1.culture === i18n.language)?.value || '' 
        })) || []
    }, [options])
} 

export type TAdvertEnvironmentOptions = {
    label: string,
    value: number
}

