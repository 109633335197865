import { TCustomerFavoriteSharedCreatePayload } from '@ephatta/services';
import { useEphattaMutation, useTranslations } from '../../..';
import { useCustomerFavoriteService } from './useCustomerFavoriteService';

export const useCustomerFavoriteCreateShared = () => {

    const [
        SUCCESS,
    ] = useTranslations(i18n)

    const service = useCustomerFavoriteService()
    return useEphattaMutation(
        (payload: TCustomerFavoriteSharedCreatePayload) => service.createShared(payload)
    , {
        successMessage: SUCCESS
    })
}

const i18n = [
    'Page.MyFavorites.Details.PopIn.Share.SearchWithMiCasien.OrCooptation.Success.Label',
]
