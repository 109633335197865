import { TAdvertComment } from "@ephatta/services"
import { useEphattaMutation } from "hooks"
import { useTranslations } from "hooks/translation"
import { useAdvertCommentService } from "./useAdvertCommentService"

export const useAdvertCommentCreate = () => {
    const service = useAdvertCommentService()
    const [
        SUBMIT_SUCCESS
    ] = useTranslations(i18n)

    return useEphattaMutation((payload: TAdvertComment) => service.create(payload), {
        successMessage: SUBMIT_SUCCESS
    })
}

const i18n = [
    'Client.Opinion.Complain.Comment.Submit.Success.Message'
]
