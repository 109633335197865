import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {  Theme, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";


const CustomButton = ({label, ...others}: { label: string } & Partial<LoadingButtonProps>) => {

    const classes = useStyles();


    return (
        <>
            <LoadingButton
                variant={'contained'}
                color={'secondary'}
                size={'large'}
                loadingPosition='start'
                {...others}
            >
                <Typography
                    color={'white'}
                    className={classes.labelbuttonResp}
                >
                    { label }
                </Typography>
            </LoadingButton>
        </>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    labelbuttonResp: {
        '&.MuiTypography-body1': {
            fontWeight:'700',
            fontFamily:'Montserrat',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.875rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.68rem',
            }
        }
    }
}));

export default CustomButton;