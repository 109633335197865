
export class HtmlRendererWebComponent extends HTMLElement {
    constructor() {
        // Always call super first in constructor
        super();
        this.attachShadow({mode: 'open'});
        if (this.shadowRoot) {
            this.shadowRoot.innerHTML = `<slot></slot>`;
        }
    }
}
