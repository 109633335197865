import { ThemeOptions } from "@mui/material";
import { DefaultTheme } from "@ephatta/components";

const primary = {
    main: '#061566',
    light: '#ffffff'
}

const secondary = {
    main: '#80cab1',
    light: '#f4f4f4'
}

const info = {
    main: '#8A8A8A',
    light: '#8A8A8A'
}

const gray = '#DEE2E5'

const gray1 = "#6b6c6f"

const text = {
    primary: '#061566',
    secondary: '#80cab1',
    disabled: 'rgba(138, 138, 138, 1)'
}


const SMALL_DEVICE = '@media only screen and (max-width: 600px)'
const MEDIUM_DEVICE = '@media only screen and (max-width: 900px)'
const LARGE_DEVICE = '@media only screen and (max-width: 1200px)'
const VERY_LARGE_DEVICE = '@media only screen and (min-width: 600px)'

export const Theme: ThemeOptions = {
    ...DefaultTheme,
    palette: {
        primary,
        secondary,
        info,
        text,
    },
    typography: {
        fontFamily: [
            "Montserrat",
            "Circe",
            "Roboto",
            "Helvetica Neue",
            "Arial",
            "sans-serif"
        ].join(","),
        fontSize: 16,
        h1: {
            fontWeight: '700',
            /* Large devices and up */
            [VERY_LARGE_DEVICE]: {
                fontSize: '22.4px',
            },
            /* Small devices and up */
            [SMALL_DEVICE]: {
                fontSize: '22.4px',
            },
        },

        h2: {
            fontWeight: '700',
            fontSize: '19.6px',
        },

        h3: {
            fontWeight: '700',
            fontSize: '18px',
        },

        h4: {
            fontWeight: '700',
            fontSize: '14px'
        },
        h5: {
            fontWeight: '700',
            fontSize: '14px'
        },
        body1: {
            fontWeight: '500',
            [SMALL_DEVICE]: {
                fontSize: '14px',
            },
            /* Large devices and up */
            [VERY_LARGE_DEVICE]: {
                fontSize: '14px',
            },
            '& b': {
                fontWeight: '700'
            }
        },
        body2: {
            fontWeight: '500',
            [SMALL_DEVICE]: {
                fontSize: '0.68rem', // 11px
            },
            /* Large devices and up */
            [VERY_LARGE_DEVICE]: {
                fontSize: '1.031rem', // 16.5px
            },
        },

        subtitle1:{
            fontFamily: 'Montserrat',
            fontWeight: '600',
            color: gray1,
            fontSize: '0.75rem'
        },
        subtitle2:{
            fontFamily: 'Montserrat',
            color: gray1,
            [SMALL_DEVICE]: {
                fontSize: '0.68rem', // 11px
            },
            /* Large devices and up */
            [VERY_LARGE_DEVICE]: {
                fontSize: '0.813rem',        
           },
        }
    },

    components: {
        MuiTypography:{
            styleOverrides:{
                root:{
                    '&.font-family-bradley':{
                        fontFamily: 'Bradley Hand, Handlee, sans-serif',
                        fontWeight: '800',
                        color: text.primary
                    },
                    '&.text-underline':{
                        textDecoration: 'underline'
                    },
                    '&:hover .text-underline':{
                        textDecoration: 'none'
                    },

                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                '& a': {
                    [SMALL_DEVICE]: {
                        fontSize: '0.562rem', // 9px
                    },
                    /* Large devices and up */
                    [VERY_LARGE_DEVICE]: {
                        fontSize: '0.937rem', // 15px
                    },
                },
            }
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    [VERY_LARGE_DEVICE]: {
                        maxWidth: '1070px',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    },
                    // [LARGE_DEVICE]: {
                    //     maxWidth: '1033px'
                    // },
                    // [MEDIUM_DEVICE]: {
                    //     paddingLeft: '16px',
                    //     paddingRight: '16px'
                    // }
                }
            },
        },
        MuiPaper: {

        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: primary.main
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 10
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover:not(.Mui-selected)': {
                        backgroundColor: secondary.main,
                        color: secondary.light,
                        '& svg': {
                            color: secondary.light
                        }
                    },
                    '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: secondary.main,
                        color: secondary.light,
                        '& svg': {
                            color: secondary.light
                        }
                    }
                },
                selected: {

                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    '&.border-gray .MuiOutlinedInput-notchedOutline': {
                        borderColor: gray
                    },
                    '&:hover.border-hover-gray .MuiOutlinedInput-notchedOutline': {
                        borderColor: gray
                    },
                    '&.border-gray .Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: gray
                    },
                },
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:before":{
                        borderColor: primary.main,
                    },
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: primary.main,
                    },
                    '& .MuiInputBase-input': {
                        borderColor: primary.main,
                    },
                    '&:hover' : {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: primary.main,
                            borderWidth: '1.5px'
                        }
                    },
                    [SMALL_DEVICE]: {
                        padding: 0,
                        fontSize: '0.75rem', // 12px
                    },
                    [VERY_LARGE_DEVICE]: {
                        fontSize: '0.937rem', // 15px
                    },
                },
                input: {
                    [SMALL_DEVICE]: {
                        padding: '11px 21px 14px 21px',
                        fontSize: '0.75rem', // 12px
                    },
                    [VERY_LARGE_DEVICE]: {
                        padding: '9px 11px',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: primary.main,
                    },
                },
                notchedOutline: {
                    borderType: 'solid',
                    borderColor: primary.main,
                    borderWidth: '1.5px',
                    borderRadius: '3px',
                },
                focused: {
                    borderColor: primary.main,
                    borderWidth: '1.5px',
                },
            },
        },
        MuiSelect:{
            styleOverrides:{
                outlined:{
                    '& ~ .MuiOutlinedInput-notchedOutline':{
                        borderColor: primary.main
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    zIndex: 'auto',
                    '&.Mui-focused': {
                        color: primary.main,
                        fontWeight: 600
                    },
                    /* Small devices (portrait tablets and large phones, 600px and up) */
                    '@media only screen and (max-width: 600px)': {
                        fontSize: '12px',
                    },
                },
                shrink: {
                    color: primary.main,
                    fontWeight: 600
                }
            }
        },
        MuiButton: {
            defaultProps:{
                disableRipple : true
            },
            styleOverrides: {
                root: {
                    [SMALL_DEVICE]: {
                        padding: '8.5px 28px',
                        fontSize: '0.708rem', // 11.34px
                    },
                    /* Large devices and up */
                    [VERY_LARGE_DEVICE]: {
                        padding: '9px 60px',
                        textTransform: 'uppercase',
                        fontSize: '0.813rem', // 13px
                    },
                    '&.text-transform-none':{
                        textTransform: 'none',
                    },
                    '&.cta-outlined-large':{
                        fontWeight: 500,
                        [SMALL_DEVICE]: {
                            fontSize: '0.75rem',
                            paddingTop: '0.75rem',
                            paddingBottom: '0.75rem'
                        },
                        /* Large devices and up */
                        [VERY_LARGE_DEVICE]: {
                            fontSize: '0.813rem', // 13px
                            paddingTop: '0.781rem',
                            paddingBottom: '0.781rem',
                        },
                    },
                    '&.cta-medium':{
                        [SMALL_DEVICE]:{
                            fontSize:'0.75rem',
                            paddingLeft:'7px',
                            paddingRight:'7px',
                            paddingTop:'12px',
                            paddingBottom:'12px'
                        },
                        [VERY_LARGE_DEVICE]:{
                            fontSize:'0.969rem',
                            
                        }
                    },
                    '&.cta-font-size-13': {
                        fontSize: '0.813rem', // 13px
                    },
                    '&.cta-font-size-15': {
                        [SMALL_DEVICE]:{
                           fontSize:'0.75rem' //12px
                        },
                        [VERY_LARGE_DEVICE]:{
                            fontSize:'0.969rem' //15.5px
                        }
                    },
                    '&.cta-dialog':{
                        [SMALL_DEVICE]:{
                            paddingBottom:'5px',
                            paddingTop:'5px'
                        },
                        [VERY_LARGE_DEVICE]:{
                            paddingBottom:'2.75px',
                            paddingTop:'2.75px'
                        }
                    }
                },
                contained: {
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    boxShadow: 'none',
                    [SMALL_DEVICE]:{
                        lineHeight:'0.813rem'
                    }
                },
                outlined: {
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    [SMALL_DEVICE]: {
                        textTransform: 'uppercase',
                        fontSize: '0.581rem', // 9.3px
                        lineHeight:'0.813rem'
                    },
                    /* Large devices and up */
                    [VERY_LARGE_DEVICE]: {
                        fontSize: '0.813rem', // 13px
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: secondary.main,
                        color: secondary.light,
                    }
                },
                containedSecondary: {
                    color: primary.light,
                    '&:hover': {
                        backgroundColor: primary.main,
                        color: primary.light
                    }
                },
                outlinedSecondary: {
                    '&:hover': {
                        backgroundColor: secondary.main,
                        color: secondary.light,
                        '& img':{
                            filter: 'brightness(0) saturate(100%) invert(100%)'
                        }
                    }
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    pr: 2,
                    '&.MuiCheckbox-root':{
                        color: primary.main,
                    },
                    '& .MuiSvgIcon-root':{ 
                        [SMALL_DEVICE]: { fontSize: 16 }, 
                        [VERY_LARGE_DEVICE]: { fontSize: 20 },
                    },
                    '&.Mui-checked': {
                        color: secondary.main,
                      },
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'space-between'
                    },
                },
                indicator: {
                    display: 'none'
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fbfbfb',
                    padding: '5px 45px',
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    transitionProperty: 'box-shadow',
                    transitionDuration: '300ms',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    transitionDelay: '0ms',
                    '&.Mui-selected': {
                        backgroundColor: primary.light,
                        borderColor: primary.light,
                    }
                },

            },
        },
        MuiDialog:{
            styleOverrides:{
                root:{
                    '& .MuiDialog-paper': {
                        [SMALL_DEVICE]:{
                            minWidth:'298px'
                        }
                    }
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid #dddee0 `,
                    backgroundColor: '#f9fafb',
                    padding: '10px'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: `1px solid #dddee0 `,
                    backgroundColor: '#f9fafb',
                    padding: '10px'
                }
            }
        },
    },
}
