import { useTranslations } from "@ephatta/components"
import { Box, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles";
import { useMemo } from "react";


export const PageNotFound = () => {

    const [
        TITLE,
        DESCRIPTION,
    ] = useTranslations(i18n)
    const theme = useTheme()
    const isSmall = useMediaQuery("(max-width:600px)");

    const height = useMemo(()=>{
        if(isSmall){
            return 'calc(100vh-254px)'
        }
        return 'calc(100vh-181px)'
    },[isSmall])

    return (
        <Box p={2} pt={5} textAlign="center" m="auto" height={height} >
            <img src="/assets/img/404-error.png" style={{ maxWidth: '100%' }} />
            <Typography
                variant="h1"
                className="font-family-bradley"
                color={"primary"}
                mt="60px"
            >
                { TITLE }
            </Typography>
            <Typography variant="body1" color="primary">
                { DESCRIPTION }
            </Typography>
        </Box>
    )
}

const i18n = [
    'Client.Page.Error.404.Title',
    'Client.Page.Error.404.Description',
]
