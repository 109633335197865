import { useSetRecoilState } from "recoil"
import { SnackbarItem } from "../../components/snackbar/SnackbarList"
import { SnackbarListState } from "../../state/SnackbarListState"

/**
 * Hooks that display an Alert message into main viewport
 */
export const useDisplayAlert = (): UseDisplayAlert => {

    const setList = useSetRecoilState(SnackbarListState)
    
    const addAlert = (item: SnackbarItem) => {
        setList((l) => {
            if (!l.some((item1) => item.id && item.id === item1.id)) {
                return [...l, item]
            }
            return l
        })
    }

    return addAlert
}

type UseDisplayAlert = (item: SnackbarItem) => void
