import { useEphattaMutation } from "hooks/utils/useEphattaMutation"
import { useLoginService } from "./useLoginService"



export const useSendConfirmPhone = () => {
    const loginService = useLoginService()

    return useEphattaMutation(
        ({userId, phone} : Payload) => loginService.sendConfirmPhone(userId, phone),
        {
            successMessage: 'Code de confirmation envoyé'
        }
    )
}
type Payload = {
    userId: string
    phone: string
}
