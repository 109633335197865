import { TMangoPayCardDeactivePayload } from "@ephatta/services"
import { useEphattaMutation } from "../../.."
import { useMangoPayService } from "../useMangoPayService"

export const useMangoPayDeactivateCard = () => {

    const service = useMangoPayService()
    
    return useEphattaMutation<unknown | null>(({ cardId, ...payload }: { cardId: string } & TMangoPayCardDeactivePayload) => {
        return service.deactivateCard(cardId, payload)
    })
}
