import { useEffect } from "react";
import { UseMutationResult } from "react-query";
import { useEphattaMutationOptions } from ".";
import { useEventInsert } from "../data/event/useEventInsert";
import { useEphattaMutationLogActivated } from "./useEphattaMutationLogActivated";

export const useEphattaMutationLogger = <TData, TError = any>(
  _mutation: UseMutationResult<TData, TError>,
  config?: useEphattaMutationOptions<TData, TError, unknown>
) => {
  const mutationLogActivated = useEphattaMutationLogActivated();
  const mutation = useEventInsert();

  useEffect(() => {
    if (!mutationLogActivated) {
      return;
    }

    if (_mutation.isSuccess && !mutation.isLoading) {
      const message = config?.log?.(_mutation.data, _mutation.variables);
      
      if (!message) {
        return;
      }

      _mutation.reset();

      if (typeof message !== "string") {
        mutation.mutate({
          message: message.message,
          userId: message.userId
        });
      } else {
        mutation.mutate({
          message
        });
      }
    }
  }, [mutationLogActivated, _mutation, config]);

  useEffect(() => {
    if (mutation.isError || mutation.isSuccess) {
      mutation.reset();
    }
  }, [mutation]);

  return mutation;
  
};