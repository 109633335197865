import { useEnv } from "@ephatta/components"
import { TClientIdentity } from "@ephatta/services"
import { Avatar, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react"
import { Link } from "react-router-dom"
import { TProfileMenu, useProfileMenus } from "../hooks/useProfileMenus"
import { useProfileMenusNotMember } from "../hooks/useProfileMenusNotMember"

export const NavBarUserMenu = () => {

    const env = useEnv<TClientIdentity>()
    const profileMenusMember = useProfileMenus()
    const profileMenusNotMember = useProfileMenusNotMember()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        console.log(window.matchMedia('(hover: none)').matches)
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
        if (!window.matchMedia('(hover: none)').matches) {
            setAnchorEl(e.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton sx={{ padding: 0 }} onClick={handleClick} onMouseEnter={handleMouseEnter}>
                <Avatar
                    style={{ width: 28, height: 28 }}
                    src={env.pictureUrl || "/assets/img/avatar/avatar-default.png"}
                />
            </IconButton>

            {/* Profile menus */}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    variant: "outlined",
                    onMouseLeave: handleClose
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                {env.isMember &&
                    profileMenusMember.map((menu: TProfileMenu, index: number) => {
                        return menu?.label ? (
                            <MenuItem
                                key={index}
                                sx={{
                                    "&:hover:not(.Mui-selected)": {
                                        backgroundColor: "primary.light",
                                        color: "secondary.main"
                                    }
                                }}
                                onClick={handleClose}
                            >
                                <Link to={menu?.url || "#"}>
                                    <Typography color="secondary" fontSize={{xs:"0.9rem", md:"1.093rem"}}>
                                        {menu.label}
                                    </Typography>
                                </Link>
                            </MenuItem>
                        ) : (
                            <Divider key={index} sx={{ my: 2, backgroundColor: 'secondary.main' }} />
                        )
                    })
                }


                {!env.isMember &&
                    profileMenusNotMember.map((menu: TProfileMenu, index: number) => {
                        return menu?.label ? (
                            <MenuItem
                                key={index}
                                sx={{
                                    "&:hover:not(.Mui-selected)": {
                                        backgroundColor: "primary.light",
                                        color: "secondary.main"
                                    }
                                }}
                                onClick={handleClose}
                            >
                                <Link to={menu?.url || "#"}>
                                    <Typography color="secondary" fontSize={{xs:"0.9rem", md:"1.093rem"}}>
                                        {menu.label}
                                    </Typography>
                                </Link>
                            </MenuItem>
                        ) : (
                            <Divider key={index} sx={{ my: 2, backgroundColor: 'secondary.main' }} />
                        )
                    })
                }
            </Menu>
        </>
    )
}
