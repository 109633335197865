import _ from "lodash"

export const useCheckEmptyNess = () => {
  return (value: any) => {
    if(typeof value === 'string') {
        return value.trim().length === 0
    }
    if(typeof value === 'number') {
        return value === 0
    }
    if(typeof value === 'object') {
        return _.isEmpty(value)
    }
    if(typeof value === 'boolean' && value !== false && value !== true) {
        return true
    }
    return !Boolean(value)
}
}
