import { useCallback } from "react"
import { useMangoPayRequest } from "./useMangoPayRequest"

export const useMangoPayCardRegistration = () => {

    const mangoPayRequest = useMangoPayRequest()

    const cardRegistration = useCallback(async (payload: MangoPayCardRegistrationPayload): Promise<MangoPayCardRegistrationResponse> => {

        return mangoPayRequest<MangoPayCardRegistrationPayload, MangoPayCardRegistrationResponse>({
            apiUrl: 'cardregistrations',
            payload: payload
        })
    }, [])

    return cardRegistration
}

export type MangoPayCardRegistrationPayload = {
    UserId: string
    Currency: string
    CardType: string
}

export type MangoPayCardRegistrationResponse = {
    AccessKey: string
    CardId?: string
    CardRegistrationURL: string
    CardType: string
    CreationDate: number
    Currency: string
    Id: string
    PreregistrationData: string
    RegistrationData?: string
    ResultCode?: number
    ResultMessage?: string
    Status: string
    Tag?: string
    UserId: string
}
