import { TAdvertCalendarAvailability } from "@ephatta/services"
import { useCallback, useMemo } from "react"
import { useInfiniteQuery, useQueryClient } from "react-query"
import { useAdvertAvailabilityService } from "./useAdvertAvailabilityService"
import { useAdvertGetById } from "../useAdvertGetById"
import _ from "lodash"
import { useLocation } from "react-router-dom"

export const useAdvertAvailabilities = (payload: TAdvertCalendarAvailability, refetchOnWindowFocus: boolean = false) => {
    const service = useAdvertAvailabilityService()
    const { data, isFetching } = useAdvertGetById(payload.advertId!, true)

    const queryClient = useQueryClient()

    const location = useLocation()
    const isAdvertAvailabilityPage = useMemo(() => /\/advert\/availability/.test(location.pathname), [location])

    const query = useInfiniteQuery([cacheKey, payload.advertId, isAdvertAvailabilityPage, data?.advert?.iCal], (params) => {
        const parameters = _.cloneDeep(params?.pageParam || payload)
        parameters.closedDays = false
        if (isAdvertAvailabilityPage && data && !data.advert?.iCal) {
            parameters.closedDays = true
        }
        return service.getAll(parameters)
    }, {
        refetchOnWindowFocus,
        enabled: !isFetching && Boolean(data)
    })

    const invalidateQuery = useCallback(() => {
        queryClient.invalidateQueries(cacheKey)
    }, [])

    return {
        ...query,
        invalidateQuery
    }
}

const cacheKey = 'Adverts.Availabilities'
