import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Logout = React.lazy(() => import('./Logout'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/logout',
        element: <Logout />,
    },
]

export default Routes
