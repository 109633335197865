import { TCustomerRelationshipDetailsPayload } from "@ephatta/services"
// import { useEphattaQuery } from "../../.."
import { useCustomerRelationshipService } from "./useCustomerRelationshipService"
import { useInfiniteQuery, useQueryClient } from "react-query"
import { useCallback, useMemo } from "react"
import _ from "lodash"


export const useCustomerSuggestions = (payload: TCustomerRelationshipDetailsPayload, suspense=false, enabled = true)=> {
    const service = useCustomerRelationshipService()
    const queryClient = useQueryClient()
    const infinityQuery = useInfiniteQuery([cacheKey, payload], (params) => {
        return service.getSuggestions(params?.pageParam || payload)
    }, { suspense, enabled })

    const data = useMemo(() => infinityQuery.data?.pages?.flat(), [infinityQuery.data?.pages])

    const isLastFetchEmpty = useMemo(() => {
        const lastElement = _.cloneDeep(infinityQuery.data?.pages)?.pop()
        return lastElement === undefined || lastElement.length < (payload.perPage || 0)
    }, [infinityQuery.data?.pages, payload.perPage])

    const invalidateQuery = useCallback(() => {
        queryClient.invalidateQueries(cacheKey)
    }, [])

    return {
        ...infinityQuery,
        data,
        isLastFetchEmpty,
        invalidateQuery
    }
    //return useEphattaQuery(["Customer.Suggestions", payload], () => service.getSuggestions(payload),{suspense})
} 

const cacheKey = "Network.Contact"
