import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Home = React.lazy(() => import('./Home'))
const Maintenance = React.lazy(() => import('../../components/layout/Maintenance'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/maintenance',
        element : <Maintenance />
    },
    {
        path: '/:url',
        element: <Home />,
    }
]

export default Routes
