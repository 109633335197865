import {TextField, TextFieldProps, useMediaQuery} from "@mui/material";

const CustomizedInput = (props: Partial<TextFieldProps>) => {

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    return (
        <>
            <TextField
                {...props}
                variant="standard"
                sx={{
                    '& input' :  {
                        paddingTop: '5px',
                        paddingBottom: '4px',
                        fontSize: isSmall ? '12px' : '15px',
                    }
                }}
            />
        </>
    )
}

export default CustomizedInput;