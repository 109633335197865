import React from "react"
import { Grid, GridProps, LinearProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"

export const MainLoader = ({ src, ...props }: Props) => {
    const classes = useStyle()
    return (
        <Grid className={classes.mainLoader} container justifyContent="center" alignItems="center" {...props}>
            <Grid item>
                <img src={src} />
                <LinearProgress />
            </Grid>
        </Grid>
    )
}

MainLoader.defaultProps = {
    src: "/assets/img/logo.png"
}

type Props = GridProps & {
    src?: string
}

const useStyle = makeStyles((theme) => ({
    mainLoader: {
        position: 'relative',
        height: '75vh',
        '& img': {
            width: 240,
            marginBottom: 10
        }
    }
}))
