import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAdvertTypes } from "./useAdvertTypes"

export const useAdvertTypeOptions = () => {

    const { i18n } = useTranslation()
    const { data: options } = useAdvertTypes()

    return useMemo((): Array<TAdvertTypeOptions> => {
        return options?.map((item) => ({ 
            value: item.id, 
            label: item.values.find((item1) => item1.culture === i18n.language)?.value || '' 
        })) || []
    }, [options])
} 

export type TAdvertTypeOptions = {
    label: string,
    value: number
}

