import { TClientIdentity, TCustomerFavorite } from "@ephatta/services"
import { useEnv } from "hooks/env"
import { useCallback } from "react"
import { useCustomerFavoriteSearch } from "./useCustomerFavoriteSearch"

export const useCustomerFavorite = (suspense?: boolean) => {

    const env = useEnv<TClientIdentity>()
    const { data: favorites, ...rest } = useCustomerFavoriteSearch({ UserId: env?.userId }, suspense)

    const check = useCallback((adverId: string): TCustomerFavorite | undefined => {
        return favorites?.reduce((result, item) => {
            const _result = item.adverts.find((item1) => item1.id === adverId)
            if (_result) {
                return item
            }
            return result
        }, undefined)
    }, [favorites])

    return {
        check,
        ...rest
    }
}
