import { useEphattaMutation } from "hooks/utils"
import { useCallback } from "react"
import { useMangoPayRequest } from "../useMangoPayRequest"


export const useMangopaySubmitKycDocument = () => {

    const mangoPayRequest = useMangoPayRequest()

    const submitKycDocument = useCallback((payload: TMangoPayKycSubmitPayload) => {
        return mangoPayRequest<TMangoPayKycSubmitPayload>({
            method: 'PUT',
            apiUrl: `users/${payload.userId}/kyc/documents/${payload.KYCDocumentId}`,
            payload: {
              Status: payload.Status,
            }
        })
    }, [])
    return useEphattaMutation(submitKycDocument)
}


type TMangoPayKycSubmitPayload = {
    userId?: string,
    KYCDocumentId?: string,
    Status?: string,
}