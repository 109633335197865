

import { useUrlParamsDecode } from "./useUrlParamsDecode"

export const useUrlParamsValue = <TData = Record<string, any>>(): TData => {
    const decodeUrl = useUrlParamsDecode()
    return decodeUrl(window.location.href) as TData
}

export type TUrlParamsValue = Record<string, any>
