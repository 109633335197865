import { useCallback } from "react"

export const usePhoneFormat = () => {
    return useCallback((text: string, hash: string = "0 00 00 00 00"): string => {
        let i = -1
        if(!!text === false){
            return ""
        }
        return hash.split("").map(e => {
            if (e === "0") {
                i++
                return text?.[i] ?? ""
            }
            return e
        }).join("").trim()
    }, [])
}
