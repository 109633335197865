import { TClientIdentity } from "@ephatta/services"
import { useCheckEmptyNess } from "@hooks/utils"
import { useCustomerDetails, useRuntimeEnv } from "hooks/data"
import { useMemo } from "react"

export const useMangoPayInformations = (suspense: boolean = false) => {
    const env = useRuntimeEnv<TClientIdentity | null>()
    const checkEmpty = useCheckEmptyNess()

    const { data: customerDetails } = useCustomerDetails(env?.userId!, suspense)

    const mangoPayKycDocumentState = useMemo(() =>  customerDetails?.mangopayAccount?.kycDocumentState, [env, customerDetails])

    const mangoPayIsKyc = useMemo(() => customerDetails?.mangopayAccount?.isKyc, [env, customerDetails])

    const mangoPayId = useMemo(() => customerDetails?.mangopayAccount?.id!, [customerDetails])

    const mangoPayKycSent = useMemo(() =>
        customerDetails?.mangopayAccount?.kycDocumentState !== undefined && customerDetails?.mangopayAccount?.kycDocumentState !== null,
        [env, customerDetails])

    const canFinish = useMemo(() => {
        return (customerDetails?.mangopayAccount && mangoPayId && (mangoPayKycSent || !checkEmpty(mangoPayKycDocumentState)) && env?.phoneConfirmed) as boolean
    }, [mangoPayId, mangoPayKycSent, mangoPayKycDocumentState])

    const hasBadge = useMemo(() => {
        return (env?.phoneConfirmed && env?.emailConfirmed && mangoPayKycSent) as boolean
    }, [customerDetails , env])

    return {
        mangoPayKycDocumentState,
        mangoPayIsKyc,
        mangoPayId,
        canFinish,
        mangoPayKycSent,
        hasBadge
    }
}
