import React, { Suspense } from "react"
import { PageSkeleton } from "./PageSkeleton"

export const GenericSuspense = (props: GenericSuspenseProps) => {

    return (
        <Suspense fallback={props.fallback || <PageSkeleton />}>
            { props.children }
        </Suspense>
    )
}

type GenericSuspenseProps = {
    children: React.ReactNode
    fallback?: React.ReactNode
}
