import React from 'react'
import { useFormData, usePasswordStandardValidation, useTranslations } from "@ephatta/components"
import { TChangePasswordPayload } from "@ephatta/services"

export const useChangePasswordFormData = () => {

    const [
        ERROR_NOT_MATCHED,
    ] = useTranslations(i18n)
    const passwordValidation = usePasswordStandardValidation()

    return useFormData<Partial<TChangePasswordPayload>>({
        formData : {
        },
        required: ['password', 'newPassword', 'passwordConfirmation'],
        validate: (name: string, value: any, formData) => {
            if (name === 'newPassword') {
                const { hasError, message } = passwordValidation({ password: value })
                return hasError && <span dangerouslySetInnerHTML={{ __html: message }}></span>
            }

            if (name === 'passwordConfirmation' && value !== formData?.newPassword) {
                return ERROR_NOT_MATCHED
            }
        }
    })
}

const i18n = [
    "Client.Register.NotMatchedPassword.Error.Description",
]
