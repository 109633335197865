import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

type TBoxRadiusedItem = {
    title: string,
    bordered: boolean,
    children: React.ReactNode,
    outsideBoxChildren?: React.ReactNode,
    marginTitle?:{xs?:string , sm?:string},
}

const BoxBorderedItem = ({ title, bordered, children, outsideBoxChildren , marginTitle }: TBoxRadiusedItem) => {
    const theme = useTheme();


    return (
        <Box>
            <Stack
            // spacing={isSmall ? 0.5 : 3}
            >
                <Typography
                    variant="h2"
                    color={'primary'}
                >
                    {title}
                </Typography>
                <Box sx={{ mt: outsideBoxChildren ? "20px" : 0 }}>
                    {outsideBoxChildren}
                </Box>
                <Box
                    padding={bordered ? 2 : 0}
                    sx={{
                        border: bordered ? `1px solid ${theme.palette.secondary.main}` : 'none',
                        borderRadius: "6px",
                        mt: { xs: '15px', sm: "25px" }
                    }}
                >
                    {children}
                </Box>
            </Stack>
        </Box>
    )
}

export default BoxBorderedItem;