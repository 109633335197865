import {Box, Button, Theme, Typography, useTheme} from "@mui/material";
import {useGoogleTagsManagerEvent, useTranslations} from "@ephatta/components";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const HomeJumbotron = () => {
    const theme = useTheme();

    // ====== TRANSLATIONS ===========
    const [
        TRUST_COMMUNITY_TITLE,
        SUBTITLE,
        LOGIN_BUTTON,
        SIGNUP_BUTTON,
    ]  = useTranslations(i18nKeys);

    // ======= END TRANSLATIONS ======

    const classes = useStyle()
    const pushEvent = useGoogleTagsManagerEvent()

    return (
        <>
            <Box className={classes.navBox}>

                <Box className={classes.navContent}>

                    <Box>
                        <img src={'/assets/img/logo/mi-casa-logo1.png'} style={{ height: 192 }} width="100%" alt="MiCasaEsTuCasa"/>
                    </Box>

                    <Box>
                        <Box>
                            <Typography
                                className={classes.navMainText}
                                variant="h4"
                                component="h1"
                                color="secondary">
                                <Typography component="span" className={classes.navMainTitle} fontWeight="inherit">{ TRUST_COMMUNITY_TITLE }</Typography><br/>
                                {SUBTITLE}
                            </Typography>
                        </Box>
                        <Box display="flex" gap='10px' mt='30px' sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                            <Link to="/login" onClick={() => pushEvent({ event: 'cta-seconnecter' })}>
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    component="span"
                                    className={`${classes.button} cta-font-size-13`}>
                                    { LOGIN_BUTTON }
                                </Button>
                            </Link> 

                            <Link to="/registration" onClick={() => pushEvent({ event: 'cta-inscription-home' })}>
                                <Button
                                    variant={'contained'}
                                    color={'secondary'}
                                    component="span"
                                    className={`${classes.button} ${classes.buttonWhite} cta-font-size-13`}>
                                    { SIGNUP_BUTTON }
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    navBox: {
        background: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center'
    },
    navContent: {
        display: 'flex',
        gap: '100px',
        alignItems: 'flex-end',
        maxWidth: 800,
        padding: '20px 20px 70px',
        [theme.breakpoints.down('sm')]: {
            gap: '40px',
        }
    },
    navMainTitle: {
        color: theme.palette.primary.light,
        [theme.breakpoints.down('sm')]: {
            gap: '40px',
        }
    },
    navMainText: {
        '&.MuiTypography-root, & .MuiTypography-root': {
            fontWeight: 600,
            fontSize: '20px'
        }
    },
    button: {
        '&.MuiButton-root': {
            width: '135px',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        }
    },
    buttonWhite: {
        '&.MuiButton-root': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.light}`
        }
    }
}))

const i18nKeys = [
    "Home.Page.TrustCommunity.Title",
    "Home.Page.TrustCommunity.SubTitle",
    "Client.Header.Login.Label",
    "Client.Header.SignUp.Label"
]

export default HomeJumbotron;