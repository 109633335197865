import { EphattaRouteProps } from '@ephatta/components'
import { MainLayout } from '../components/layout/MainLayout'
import HomeRoutes from './home/Routes'
import AccountRoutes from './account/Routes'
import StaticPage from './static-page/Routes'
import MyEmptyNetworkRoutes from './my-network/Routes'
import MyFavoritesRoutes from './my-favorites/Routes'
import ReservationsRoutes from './reservations/Routes'
import MyPublicProfile from './public-profile/Routes'
import MyRegistrationRoutes, { RegistrationNotMicasaRoutes } from './registration/Routes'
import OpinionRoutes from './opinion/Routes'
import MyProfileRoutes from './my-profile/Routes'
import AnnouncementRoutes from './announcement/Routes'
import MyInboxMessageRoutes from './my-inbox-message/Routes'
import MyWalletRoutes from './my-wallet/Routes'
import FindCasaRoutes from './find-casa/Routes'
import LandingRoutes from './landing/Routes'
import { RegistrationLayout } from '../components/layout/RegistrationLayout'

const Routes: EphattaRouteProps[] = [
    {
        path: '/',
        element: <MainLayout />,
        title: "Micasa.title",
        children: [
            ...HomeRoutes,
            ...AccountRoutes,
            ...MyEmptyNetworkRoutes,
            ...MyFavoritesRoutes,
            ...ReservationsRoutes,
            ...MyPublicProfile,
            ...OpinionRoutes,
            ...AnnouncementRoutes,
            ...MyProfileRoutes,
            ...MyInboxMessageRoutes,
            ...FindCasaRoutes,
            ...MyInboxMessageRoutes,
            ...MyWalletRoutes,
            ...LandingRoutes,
            ...StaticPage,
            ...RegistrationNotMicasaRoutes
        ]
    },
    {
        path:'/',
        element: <RegistrationLayout />,
        children:[
            ...MyRegistrationRoutes
        ]
    }
]

export default Routes
