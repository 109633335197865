import { Box, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useCallback, useMemo } from "react"
import { Link } from "react-router-dom"

export const SocialMediaContents = (props: Props) => {

    const classes = useStyles()

    const href = useCallback((link: string) => props?.noLink ? {} : {href: link}, [props?.noLink])

    const LinkState = useMemo(()=>props?.noLink ? 'div' : Link, [props?.noLink])

    return (
        <Box className={`socialMedia ${classes.socialMedia}`}>
            <a {...href("https://micasaestucasa.blog")} target="_blank" title="Le Mag">
                <img src="/assets/img/icon/le-mag.svg" className="icon" style={{ height: '22px' }} width="100%" alt="Le Mag'" />
            </a>
            
            <hr className={classes.divider} />

            <Box className={classes.socialMediaFont} display="flex" gap="12px">
                
                <a {...href("https://www.facebook.com/groups/estucasa")} target="_blank" title="Facebook">
                    <img src="/assets/img/icon/facebook.svg" className="icon" height="100%" width="100%" alt="Facebook" />
                </a>

                <a {...href("https://www.instagram.com/micasaestucasa.site/")} target="_blank" title="Instagram">
                    <img src="/assets/img/icon/instagram.svg" className="icon" height="100%" width="100%" alt="Instagram" />
                </a>

                <a {...href("https://www.linkedin.com/company/mi-casa-es-tu-casa-reseau-de-confiance")} target="_blank" title="LinkedIn">
                    <img src="/assets/img/icon/linked-in.svg" className="icon" height="100%" width="100%" alt="LinkedIn" />
                </a>

                {
                    props.hideLogo !== true &&
                    <LinkState to="/help" style={{ marginLeft: '30px' }} title="Help">
                        <img src="/assets/img/icon/help.svg" className="icon"height="100%" width="100%" alt="Help"  />
                    </LinkState>
                }
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        socialMedia: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '12px',
            '& .icon': {
                height: '13px'
            },
        },
        socialMediaFont: {
            '& svg': {
                fontSize: 20
            }
        },
        divider: {
            height: '14px',
            margin: 0,
            padding: 0,
            width: '1px',
            backgroundColor: theme.palette.secondary.main,
            border: 0
        }
    }
})

type Props = {
    hideLogo?: boolean
    noLink?: boolean
}
