import React from 'react'
import { Box, Button, Typography, TypographyProps } from "@mui/material"

export const GenericSuspenseError = ({ element, message, retry, TypographyProps, onRetryClick }: GenericSuspenseErrorProps) => {

    return (
        <Box 
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display="flex" 
            justifyContent="center"
            alignItems="center">
            
            {
                !element && 
                <Box>
                    <Typography textAlign="center" {...TypographyProps}>
                        {
                            message || "Il y a eu une erreur lors de l'affichage de la page"
                        }
                    </Typography>
                    <Box mt="20px" display="flex" justifyContent="center">
                        <Button onClick={onRetryClick} data-testid="generic-suspense-error-retry-btn" variant="contained" color="primary">
                            { retry || 'Re-essayer' }
                        </Button>
                    </Box>
                </Box>
            }

            {
                element
            }
        </Box>
    )
}

export type GenericSuspenseErrorProps = {
    message?: string
    retry?: string
    TypographyProps?: TypographyProps
    element?: React.ReactElement
    onRetryClick?: () => void
}
