var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { convertJSONToFormData } from '../../utils/ConvertJSONToFormData';
import { HttpService } from '../../utils/HttpService';
import logger from "../../utils/Logger";
import { BaseService } from '../BaseService';
var AdvertBookingService = /** @class */ (function (_super) {
    __extends(AdvertBookingService, _super);
    function AdvertBookingService(params) {
        var _this = _super.call(this, params) || this;
        _this.controllerUrl = "".concat(process.env.REACT_APP_API_URL);
        return _this;
    }
    AdvertBookingService.prototype.getAll = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.get("".concat(this.controllerUrl, "/announcement/booking"), payload || {}, {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_1 = _a.sent();
                        logger.error(err_1, { method: this.getAll.name });
                        throw err_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.GetByUserId = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.get("".concat(this.controllerUrl, "/announcement/booking/user/").concat(userId), {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_2 = _a.sent();
                        logger.error(err_2, { method: this.getAll.name });
                        throw err_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.getBookingById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.get("".concat(this.controllerUrl, "/announcement/booking/").concat(id), {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_3 = _a.sent();
                        logger.error(err_3, { method: this.getBookingById.name });
                        throw err_3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.listBookingStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.get("".concat(this.controllerUrl, "/announcement/booking/status"), {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_4 = _a.sent();
                        logger.error(err_4, { method: this.listBookingStatus.name });
                        throw err_4;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.create = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/announcement/booking"), convertJSONToFormData(payload), {
                                headers: [
                                    { key: 'Content-Type', value: '' }
                                ]
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_5 = _a.sent();
                        logger.error(err_5, { method: this.create.name });
                        throw err_5;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.update = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.put("".concat(this.controllerUrl, "/announcement/booking/").concat(payload.id), payload)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_6 = _a.sent();
                        logger.error(err_6, { method: this.update.name });
                        throw err_6;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.guestCancellation = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.put("".concat(this.controllerUrl, "/announcement/booking/").concat(payload.bookingId, "/guestCancellation"), payload)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_7 = _a.sent();
                        logger.error(err_7, { method: this.guestCancellation.name });
                        throw err_7;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.ownerCancellation = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.put("".concat(this.controllerUrl, "/announcement/booking/").concat(payload.bookingId, "/ownerCancellation"), {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_8 = _a.sent();
                        logger.error(err_8, { method: this.ownerCancellation.name });
                        throw err_8;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.ownerAcceptation = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.put("".concat(this.controllerUrl, "/announcement/booking/").concat(payload.bookingId, "/ownerAcceptation"), {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_9 = _a.sent();
                        logger.error(err_9, { method: this.ownerAcceptation.name });
                        throw err_9;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.payment = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.post("".concat(this.controllerUrl, "/announcement/booking/").concat(payload.bookingId, "/payment"), payload)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.text()];
                    case 2:
                        err_10 = _a.sent();
                        logger.error(err_10, { method: this.ownerAcceptation.name });
                        throw err_10;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AdvertBookingService.prototype.delete = function (advertId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, HttpService.delete("".concat(this.controllerUrl, "/announcement/booking/").concat(advertId), {})];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                    case 2:
                        err_11 = _a.sent();
                        logger.error(err_11, { method: this.delete.name });
                        throw err_11;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return AdvertBookingService;
}(BaseService));
export { AdvertBookingService };
