import _ from "lodash";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUrlParamsEncode } from "./useUrlParamsEncode";
import { useUrlParamsValue } from "./useUrlParamsValue"

export const useUrlParamsState = <TValue extends {} = Record<string, any>>(defaultValue?: TValue) => {

    const urlParamsValue = useUrlParamsValue<TValue>()
    const navigate = useNavigate()
    const encode = useUrlParamsEncode()

    useEffect(() => {
        defaultValue && navigate(`${window.location.pathname}?${encode({ ...defaultValue, ...urlParamsValue })}`, { replace: true })
    }, [])

    const setUrlParams = useCallback((key: string | SetUrlParamsCallback<TValue>, value?: any) => {
        let newValue: TValue = urlParamsValue
        if (typeof key === 'string') {
            newValue = { ...urlParamsValue, [key]: value }
        }
        else if (typeof key === 'function'){
            newValue = key(_.cloneDeep(urlParamsValue))
        }

        if (!_.isEqual(urlParamsValue, newValue)) {
            navigate(`${window.location.pathname}?${encode(newValue)}`)
        }
    }, [urlParamsValue])

    return {
        urlParams: urlParamsValue,
        setUrlParams
    }
}

export type SetUrlParamsCallback<TValue> = (prevValue: TValue) => TValue
