
export const useBase64toFileConverter = () => {
    const urlToFile = async (url : string, filename: string, mimeType?: string) => {
        const res = await fetch(url);
        const buf = await res.arrayBuffer();
        return new File([buf], filename, { type: mimeType });
    };
    return {
        urlToFile
    }
}

