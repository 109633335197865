import React from 'react'
import { TAdvertAvailability, TAvailabilitiesCollection } from "@ephatta/services"
import { DateRangePickerDay, DateRangePickerDayProps } from "@mui/lab"
import { Box, styled, Tooltip,  tooltipClasses,  TooltipProps,  Typography, useTheme } from "@mui/material"
import moment from "moment"
import { useCallback } from "react"

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: "-2px 4px 24px -3px rgba(0,0,0,0.28)",
      padding: "10px",
      borderRadius: "7px",
      fontSize: "14px",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color:"white",
        boxShadow: "-2px 4px 24px -3px rgba(0,0,0,0.28)",
    }
  }));
  
export const useAdvertAvailabilityRenderDay = (availabilities: TAvailabilitiesCollection, create?:boolean) => {

    const isNew = useCallback((date: Date) => {
        return moment(date).isAfter(moment(), 'days')
    }, [])

    const isCheckoutOnly = useCallback((availability: TAdvertAvailability) => {
        return availability.isCheckoutOnly && !Boolean(create)
    },[create])

    const getDateStyle = useCallback((availability: TAdvertAvailability) => {
        
        if(isCheckoutOnly(availability)) {
            return { color: 'primary', textDecoration: 'none', fontWeight: 'normal' }
        }
        

        if(availability.available == false || availability.origine === "booking" || availability.origine === 'synchro') {
            return { color: 'info.main', textDecoration: 'line-through', fontWeight: 'normal' }
        }

        if(availability.available) {
            return {fontWeight: 'bold'}
        }


        return { color: "primary"}

    }, [])


    const renderDay = useCallback(( 
        date: Date, 
        dateRangePickerDayProps: DateRangePickerDayProps<Date>

    ): JSX.Element => {
        const availability = availabilities?.[moment(date).format('YYYY/MM/DD')]
        if (!availability) {
            const isCreate = Boolean(create)

            if(!isNew(date)) {
                return (
                    <div>
                        <DateRangePickerDay {...dateRangePickerDayProps} sx={{color: 'info.main', textDecoration: 'line-through' , fontWeight: 'normal'}} />
                    </div>
                )
            }

            if(isCreate) {
                return (
                    <div>
                        <DateRangePickerDay {...dateRangePickerDayProps} sx={{textDecoration: 'none' , fontWeight: 'bold'}} />
                    </div>
                )
            }

            if(dateRangePickerDayProps.outsideCurrentMonth) {
                return (
                    <div>
                        <DateRangePickerDay {...dateRangePickerDayProps} sx={{color: '#061566 !important', textDecoration: 'none' , fontWeight: 'bold'}} />
                    </div>
                )
            }

            return (
                <LightTooltip title="Contactez le propriétaire pour connaitre la disponibilité et le tarif à cette date." arrow placement='top'>
                    <div>
                        <DateRangePickerDay {...dateRangePickerDayProps} sx={{color: '#061566 !important', textDecoration: 'none' , fontWeight: 'bold'}} />
                    </div>
                </LightTooltip>
            )
        }

        let style = getDateStyle(availability)

        if (dateRangePickerDayProps.selected) {
            style.color = 'primary.light'
        }

        if(isCheckoutOnly(availability)){
            return (
                <LightTooltip title={'Départ uniquement'} arrow placement='top'>
                    <div>
                        <AvailableDate 
                            dateRangePickerDayProps={dateRangePickerDayProps} 
                            date={date}
                            isCheckoutOnly={isCheckoutOnly}
                            availability={availability}
                            style={style}
                        />
                    </div>
                </LightTooltip>
            )
        }
        
        return (
            <AvailableDate 
                dateRangePickerDayProps={dateRangePickerDayProps} 
                date={date}
                isCheckoutOnly={isCheckoutOnly}
                availability={availability}
                style={style}
            />
        )

    }, [availabilities])

    return renderDay
}

// A separate component for fixing bug on firefox
const AvailableDate = (props: availableDateProps) => {

    const theme = useTheme()

    return (
        <DateRangePickerDay {...props.dateRangePickerDayProps} sx={props?.isCheckoutOnly?.(props?.availability!) ? {"&.MuiButtonBase-root.Mui-disabled":{color: theme.palette.primary.main}} : {"&.MuiButtonBase-root.Mui-disabled":{color: theme.palette.primary.main, fontWeight: 'bold'}}}>
            <Box display="flex" flexDirection="column" gap="5px">
                <Typography 
                    fontWeight={props.style.fontWeight} 
                    fontSize="15px" 
                    color={props.style.color}
                    sx={props.style}
                >
                    { moment(props.date).date() }
                </Typography>
                {
                    props?.availability?.price! > 0 &&
                    <Typography 
                        variant="caption" 
                        fontWeight="bold" 
                        fontSize="8px"
                        mt="-12px" 
                        color={props.style.color}
                    >
                        { props?.availability?.price! }€
                    </Typography>
                }
            </Box>
        </DateRangePickerDay>
    )
}

type availableDateProps = {
    dateRangePickerDayProps: DateRangePickerDayProps<Date>,
    date: Date,
    isCheckoutOnly?: (availability: TAdvertAvailability) => boolean,
    availability?: TAdvertAvailability | undefined,
    style?: any,
}