import { Button, IconButton, SnackbarProps, Snackbar, AlertProps } from '@mui/material'
import { useRecoilState } from 'recoil'
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React,{ useCallback } from 'react';
import _ from 'lodash'
import { SnackbarListState } from '../../state/SnackbarListState';

export const SnackbarList = ({ limit }: Props) => {

    const [list, setList] = useRecoilState(SnackbarListState)

    const removeFromList = (index: number) => {
        setList((l) => {
            const list = [...l]
            list.splice(index, 1)
            return list
        })
    }

    const handleActionClick = (item: SnackbarItem, index: number) => {
        removeFromList(index)
        item.action?.onClick?.(item)
    }

    const renderAction = (item: SnackbarItem, index: number) => {
        return (
            <React.Fragment>
                <Button 
                    color="inherit" 
                    size="small"
                    onClick={() => handleActionClick(item, index)}>
                    { item.action?.label }
                </Button>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => removeFromList(index)}>
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </React.Fragment>
        )
    }

    const getList = useCallback(() => {
        if (limit && list.length > limit) {
            return list.slice(list.length - (limit || 0))
        }

        return list
    }, [list])

    return (
        <React.Fragment>
            {
                getList().map((item, index) => (
                    <Snackbar 
                        key={index}
                        open={true}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        {..._.omit(item, ['AlertProps', 'action'])}
                        style={{ top: index > 0 ? (70 * index) + 10 : undefined, ...item.style }}
                        onClose={() => removeFromList(index)}>
                        <Alert 
                            severity="error"
                            action={item.action && renderAction(item, index)} 
                            onClose={() => removeFromList(index)}
                            {...item.AlertProps}>
                            { item.label }
                        </Alert>
                    </Snackbar>
                ))
            }
        </React.Fragment>
    )
}

type Props = {
    /** Snackbar limit */
    limit?: number
}

export type SnackbarItem = Omit<SnackbarProps, 'action'> & {
    /** Uniq id that will make the alert unique */
    id?: string

    AlertProps?: AlertProps

    severity?: AlertProps['severity']

    label: AlertProps['children']

    action?:  {
        label: string
        onClick?: (item: SnackbarItem) => void
    }
}
