import { useEnv } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";
import {Avatar, Box, Stack, Typography, useMediaQuery} from "@mui/material";

const AvatarWithTitle = ({ title, avatar, pt }: { title?: string, avatar?: string, pt?:number }) => {
    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    const env = useEnv<TClientIdentity | null>()

    return (
        <Box
            paddingTop={pt ? 0 : isSmall ? 2 : 2}
        >
            <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
            >
                <Avatar
                    src={avatar || env?.pictureUrl || '/assets/img/avatar/avatar-default.png'}
                    sx={{
                        width: isSmall ? 28 : 32,
                        height: isSmall ? 28 : 32
                    }}
                />
                <Typography variant="h2" color={'secondary'}>
                    { title }
                </Typography>
            </Stack>
        </Box>
    )
}

export default AvatarWithTitle;