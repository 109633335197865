import { useAdvertIsActif } from '@ephatta/components';
import { PageNotFound } from 'components/layout/PageNotFound';
import React from 'react'
import { Outlet, useParams } from 'react-router';

const AdvertDetailsAccesLayer = () => {
  const { id: advertId } = useParams();
    const isActif = useAdvertIsActif(advertId!)

    if (advertId && isActif === false) {
        return (<PageNotFound />)
    }

    return (
        <Outlet />
    )

}

export default AdvertDetailsAccesLayer