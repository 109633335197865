import { useEnv, useFormData } from "@ephatta/components"
import { TClientIdentity } from "@ephatta/services"

export const useEmailResendFormData = () => {

    const env = useEnv<TClientIdentity>()
    return useFormData({
        formData: {
            email: env?.email
        },
        required: [
            'email'
        ],
    })
}
