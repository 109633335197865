import _ from "lodash"
import moment from "moment"
import { useMemo } from "react"
import { useAdvertAvailabilities } from "./useAdvertAvailabilities"

export const useAdvertAvailabilityPrice = (props: Props) => {
    const endDate = useMemo(() => moment(props.endDate).subtract(1, 'days').format('YYYY/MM/DD'), [props.endDate])
    const { data } = useAdvertAvailabilities({
        advertId: props.advertId,
        startDate: props?.startDate,
        endDate: endDate,
    })

    const availabilities = useMemo(() => {
        const array = data?.pages?.flat()
        return _.uniqBy(array, 'id')
    },[data])
    return useMemo(() => {
        const total = availabilities?.reduce((total, item) => {
            if (moment(item.date).isBetween(moment(props.startDate), endDate, 'days', '[]')) {
                total += item.price
            }
            return total
        }, 0) || 0
        return total
    }, [availabilities, props])
}

type Props = {
    advertId: string
    startDate: string
    endDate: string
}