import {Box, IconButton, TextField, TextFieldProps} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useState} from "react";

const CustomPasswordInput = (props: Partial<TextFieldProps>) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Box>
            <TextField
                type={showPassword ? 'text' : 'password'}
                color={'info'}
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                            { showPassword ? <VisibilityOffIcon sx={
                                {
                                    color: '#DEE2E5',
                                }
                            }/> : <VisibilityIcon sx={
                                {
                                    color: '#DEE2E5',
                                }
                            }
                            /> }
                        </IconButton>
                    ),
                }}
                {...props}
                />
        </Box>
    )
}


export default CustomPasswordInput;