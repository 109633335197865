import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const useAxeptioSetting = () => {
    const { i18n } = useTranslation()

    useEffect(() => {

        // @ts-ignore
        window.axeptioSettings = {
            clientId: process.env.REACT_APP_AXEPTIO_CLIENT_ID,
            cookiesVersion: `${process.env.REACT_APP_COOKIE_NAME}-${i18n.language.toLowerCase()}`,
        };
        
        (function(d, s) {
            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            // @ts-ignore
            e.async = true; 
            // @ts-ignore
            e.src = "//static.axept.io/sdk.js";
            // @ts-ignore
            t.parentNode.insertBefore(e, t);
        })(document, "script")
    }, [i18n])

}
