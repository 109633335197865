import React from 'react'
import { EphattaRouteProps } from "@ephatta/components"

const FindCasaPage = React.lazy(() => import('./FindCasaResult'))

const Routes: Array<EphattaRouteProps> = [
    {
        path: '/find-casa',
        title: "Micasa.FindCasa.title",
        element: <FindCasaPage/>
    }
]

export default Routes