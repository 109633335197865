import { TClientIdentity } from "@ephatta/services"
import { useCustomerDetails } from "hooks/data"
import { useEnv } from "hooks/env"
import { useMemo } from "react"

export const useMangoPayTravelWalletId = () => {
    const env = useEnv<TClientIdentity | null>()
    const { data: customerDetails } = useCustomerDetails(env?.userId!)
    return useMemo(() => customerDetails?.mangopayAccount?.travelWalletId!, [customerDetails])
}
