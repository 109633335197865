import { TCustomerCooptationCreatePayload } from '@ephatta/services';
import { useEphattaMutation } from '../../..';
import { useCustomerCooptationService } from './useCustomerCooptationService';

export const useCustomerCooptationCreate = () => {
    const service = useCustomerCooptationService()
    return useEphattaMutation(
        (payload: TCustomerCooptationCreatePayload) => service.create(payload)
    , {
        successMessage: 'Invitation envoyée avec succès'
    })
} 