import { TChangePasswordPayload } from "@ephatta/services"
import { useTranslation } from "react-i18next"
import { useEphattaMutation } from "../.."
import { useLoginService } from "./useLoginService"

export const useChangeUserPassword = () => {
    const loginService = useLoginService()

    const { t } = useTranslation()

    return useEphattaMutation(
        (payload: TChangePasswordPayload) => loginService.changePassword({
            ...payload,
            applicationId: parseInt(process.env.REACT_APP_ID!)
        }),
        {
            successMessage: t("Page.MyProfile.ConnexionInformation.ChangePassord.Success.Label")
        }
    )
}
