import { useCheckEmptyNess } from "@hooks/utils";
import _ from "lodash";
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../utils/useDebounce";
import { useFormDataError } from "./useFormDataError";
import { useFormDataErrorHelper } from "./useFormDataErrorHelper";
import { useGlobalFormData } from "./useGlobalFormData";
import { useGlobalFormDataError } from "./useGlobalFormDataError";

export const useFormData = <TData = any>(props: Props<TData>): UseFormData<TData> => {

    const {t} = useTranslation()
    const globalStateId = useMemo(() => props.id || _.uniqueId(), [props.id])
    const [formData, setFormData] = useGlobalFormData(globalStateId, props.formData);
    const [editedField, setEditedField] = useState<string | undefined>(undefined)
    const [errors, setErrors] = useGlobalFormDataError(globalStateId)
    const formDataError = useFormDataError(globalStateId)
    const formDataErrorHelper = useFormDataErrorHelper(globalStateId)

    const handleInputChange = (key: string, value: any) => {
        if(props?.liveValidation?.includes(key)) {
            setEditedField(key)
        }
        setFormData((v) => ({ ...v, [key]: value,  }))
    }
    const debounce = useDebounce()

    const checkEmptyness = useCheckEmptyNess()

    useEffect(()=>{
            if(props?.liveValidation?.includes(editedField!)){
                debounce(()=>{
                    props?.liveValidation?.forEach((item)=>{
                        if((!checkEmptyness(formDataError?.getError(item)))){
                            let err1 = props?.validate?.(item, formData[item], formData)
                            let err2 = props?.required?.includes(item) && checkEmptyness(formData?.[item]) ? t(requiredErrorMessage) : undefined
                            if(!Boolean(err1) && !Boolean(err2)){
                                setErrors((v) => (_.omit(v, item)))
                            }
                        }
                    })
                }, 300)
            }
    },[formData])
    
    const evalErrors = useCallback(() => {
        let _errors: ErrorType = {}
        Object.keys(formData as any).forEach((key) => {
            const value = formData[key]
            const error = props.validate?.(key, value, formData)
            if (error) {
                _errors[key] = error
            }
        })

        props.required?.forEach((key) => {
            const value = formData[key]
            if (value === null || value === undefined || value === '') {
                _errors[key] = t(requiredErrorMessage)
            }
        })

        return _errors
    }, [formData])

    /**
     * It will scroll into the invalid element that has the same "name" or "id" attribute 
     */
    const isValid = (render: boolean = true): boolean => {
        const _errors = evalErrors()
        if (render) {
            setErrors(_errors)
            // scroll to invalid element
            Object.keys(_errors).forEach((key) => {
                const element = document.getElementById(key) || document.querySelector(`[name=${key}]`)
                element && debounce(() => {
                    element.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" })
                }, 100)
            })
        }
        return _.isEmpty(_errors)
    }

    const getTextFieldProps = useCallback((name: string): FieldProps => {
        return {
            name,
            error: errors?.[name] !== undefined,
            helperText: errors?.[name],
            value: formData[name] || '',
            required: props.required?.includes(name),
            onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
                if (checked !== undefined) {
                    return handleInputChange(name, checked)
                }
                handleInputChange(name, e.target.value)
            }
        }

    }, [errors, formData])

    return {
        formData,
        setFormData,
        handleInputChange,
        isValid,
        getTextFieldProps,
        errors,
        ...formDataError,
        ...formDataErrorHelper
    }
}

const requiredErrorMessage = 'Generic.FormData.RequiredField.label'

type Props<TData = any> = {
    id?: string

    formData: TData,
    /**
     * Array that contain all required fields
     */
    required?: Array<string>
    /**
     * Function that check if the entiere form is valid
     * @param name Field name
     * @param value field value
     * @param formData all form data
     * @returns The new error message
     */
    validate?: (name: string, value: any, formData?: TData) =>  string | React.ReactNode | undefined | void

    /**
     * Array that contain field to a live validation
     */
    liveValidation?: Array<string>
}

export type UseFormData<TData> = {
    formData: TData
    setFormData: Dispatch<SetStateAction<TData>>
    /** Callback that will set form hooks global value */
    handleInputChange: (key: string, value: any) => void

    getTextFieldProps: (name: string) => FieldProps
    /**
     * Function that will check if formData is valid
     * It will check if required field is empty and show error
     * Then will execute "validate" function
     * @param render false will prevent rendering of components
     */
    isValid: (render?: boolean) => boolean

    errors?: ErrorType

    getError: (name: string) => string | React.ReactNode | undefined

    getErrorHelper: (name: string) => string | React.ReactNode | undefined

    hasError?: boolean
}

type FieldProps = {
    name: string
    value: any
    error?: boolean
    required?: boolean
    helperText?: string | React.ReactNode,
    onChange?: ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => void)
        | ((e: React.ChangeEvent<HTMLInputElement>, value?: string) => void)
        | ((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
}

type ErrorType = Record<string, string | ReactNode>
