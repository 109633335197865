import { useEphattaMutation } from "hooks";
import { useAdvertService } from "./useAdvertService";

export const useDeletePicture = () => {
    const service = useAdvertService()
    return useEphattaMutation(
        ({id}: TPayload) => service.deletePicture(id!)
    )
}

type TPayload = {
    id?: string
}