import { useUrlSearchQuery } from "hooks"
import _ from "lodash"
import { useMemo } from "react"
import { TUrlFilter } from "."

export const useUrlFilterValue = <TData = TUrlFilter>() => {
    const searchQuery = useUrlSearchQuery()
    
    return useMemo((): TData => {
        const query: Record<string, any> = {}
        searchQuery.forEach((value: string, key) => {
            query[key] = value
        })
        return _.omitBy(query as any, (v) => _.isEmpty(v)) as TData
    }, [searchQuery])
}
