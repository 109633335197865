import { useCallback } from "react"
import { useQueryClient } from "react-query"

export const useRuntimeEnvInvalidate = () => {
    const queryClient = useQueryClient()

    return useCallback(() => {
        queryClient.invalidateQueries('User.RuntimeEnv')
    }, [queryClient])
}
