import { useEphattaMutation } from "hooks/utils"
import { useTranslation } from "react-i18next"
import { useLoginService } from "./useLoginService"

export const useConfirmEmailRequestbyApplicationId = () => {
    const loginService = useLoginService()
    const {t} = useTranslation()
    
    return useEphattaMutation(({email, applicationId}:TConfirmEmailPayload) => loginService.confirmEmailRequestbyApplicationId(email, applicationId), {
        successMessage: t("Client.Login.ConfirmYourEmail.Sent.Success")
    })
}
type TConfirmEmailPayload = {
    email: string,
    applicationId: number
}