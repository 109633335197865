import { TextFieldProps, TextField } from "@mui/material"
import { useRef, useState, useMemo, useEffect, useCallback } from "react";
import _ from "lodash";
import React from "react";

/**
 * @docs : https://developers.google.com/maps/documentation/javascript/place-autocomplete?hl=fr
 * @styles are set in index.css
 */
type TProps = {
    textFieldProps?: TextFieldProps
    onResults?: (results: TPlaceResults) => void
    boxLimit?: Array<number>
    renderInput?: JSX.Element
}

export const GoogleAutoCompleteInput = (props: TProps) => {

    const { boxLimit, textFieldProps, onResults, renderInput } = props

    const searchLimit = useMemo(() => {
        if (boxLimit && boxLimit.length === 4) {
            return { north: boxLimit?.[3], south: boxLimit?.[1], east: boxLimit?.[2], west: boxLimit?.[0] }
        }
        return null
    }, [boxLimit])

    const autoCompleteRef = useRef();
    const inputRef = useRef();

    const options = {
        ...(!!searchLimit ? { bounds: searchLimit, strictBounds: true } : {}),
        fields: ["address_components", "geometry", "icon", "name"],
        language: 'fr'
    };

    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState(textFieldProps?.value || '');

    useEffect(() => {
        setInputValue(textFieldProps?.value || '')
    }, [textFieldProps?.value])


    useEffect(() => {
        if (isLoaded) {

            autoCompleteRef.current = new (window as any).google.maps.places.Autocomplete(
                inputRef.current,
                options
            );
            (autoCompleteRef as any).current.addListener("place_changed", async function () {
                const place = await (autoCompleteRef as any).current.getPlace();
                const results: TPlaceResults = {}
                results.latitude = place.geometry.location.lat()
                results.longitude = place.geometry.location.lng()
                results.address = place?.name
                for (let i = 0; i < place.address_components.length; i++) {
                    const addressType = place.address_components[i].types[0];
                    if (addressType === 'street_number') {
                        results.streetNumber = place.address_components[i].long_name
                    }
                    if (addressType === 'route') {
                        results.address = place.address_components[i].long_name
                    }
                    if (addressType === 'postal_code') {
                        results.zipCode = place.address_components[i].long_name
                    }
                    if (addressType === 'locality') {
                        results.city = place.address_components[i].long_name
                        // hard coded because google maps doesn't return this information
                        results.region = results.city
                    }
                    if (addressType === 'country') {
                        results.country = place.address_components[i].long_name
                    }
                }
                onResults?.(results)
                setInputValue(placeResultToString(results));
            });
        }
    }, [autoCompleteRef, (window as any).google, isLoaded]);

    const placeResultToString = useCallback((result: TPlaceResults) => {
        return (result.streetNumber || '') + (result.streetNumber ? ', ' : '')
            + (result.address ? `${result.address} ` : '')
            + (result.zipCode ? `${result.zipCode} ` : '')
            + (result.city ? `${result.city} ` : '')
            + (result.country && result.address !== result.country ? `${result.country} ` : '')
    }, [])

    useEffect(() => {
        loadGoogleMaps()
    }, [])

    const loadGoogleMaps = useCallback(() => {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_AUTOCOMPLETE_API_KEY}&libraries=places&language=fr&region=fr`,
                document.querySelector('head'),
                'google-maps',
                () => setIsLoaded(true)
            );
            return;
        }
        if (document.querySelector('#google-maps') && (window as any).google?.maps?.places) {
            setIsLoaded(true)
        }
    }, [])

    if (renderInput) {
        return React.cloneElement(renderInput, {
            inputRef,
            autoComplete: 'on',
            autoCorrect: 'on',
            ...renderInput?.props,
            value: inputValue,
            onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setInputValue(e.target.value);
                renderInput.props.onChange?.(e)
            },
        })
    }

    return (
        <TextField
            inputRef={inputRef}
            autoComplete="on"
            autoCorrect="on"
            {...props?.textFieldProps}
            value={inputValue}
            onChange={(e) => { setInputValue(e.target.value); }}
        />
    );
}

function loadScript(src: string, position: HTMLElement | null, id: string, callback: () => void) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.onload = callback
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export type TPlaceResults = {
    city?: string
    country?: string
    zipCode?: string
    address?: string
    region?: string
    longitude?: number
    latitude?: number
    streetNumber?: string
}
