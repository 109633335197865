export const GET_ALL_BOOKINGS_BY_ESTABLISHMENT_ID = 'Booking.GetAllStatus'
export const GET_BOOKINGS_ALLSTATUS = 'Booking.GetAllStatus'
export const GET_BOOKING_FILTER = 'Booking.Filter'
export const GET_BOOKING_INBOX_GETDETAILS = 'Booking.Inbox.GetDetails'
export const GET_BOOKING_INBOX_GET_BY_ESTABLISHMENT = 'Booking.Inbox.GetByEstablishmentId'
export const GET_BOOKING_INBOX_GET_BY_CLIENT_ID = 'Booking.Inbox.GetByClientId'
export const GET_BOOKING_INBOX_FILTER = 'Booking.Inbox.Filter'
export const GET_BOOKING_INBOX_MESSAGE_FILE_ASSIGNATIONS = 'Booking.InboxMessage.FileAssignations'
export const GET_BOOKING_ALL_REVIEWS_TITLES = 'Booking.AllReviewsTitles'
export const GET_BOOKING_ALL_REVIEW_BY_ESTABLISHMENT_ID = 'Booking.AllReviewByEstablishmentId'
export const GET_BOOKING_BY_STATUS_ID = 'Booking.GetByStatusId'
export const GET_BOOKING_GET_TOTAL_AMOUNT = 'Booking.GetTotalAmount'
export const GET_BOOKING_GET_BY_GUESTID = 'Booking.GetByGuestId'
export const GET_SATISFACTION_BY_BOOKING_ID = 'Booking.Satisfaction.GetByBookingId'
export const GET_SATISFACTION_BY_ESTABLISHMENT_ID = 'Booking.Satisfaction.GetByEstablishmentId'

export const GET_ESTABLISHMENT_ACCOUNT_BANK_DETAILS = 'Establishment.Account.BanDetails'
export const GET_ESTABLISHMENT_SPOKEN_LANGUAGE = 'Establishment.SpokenLanguage'
export const GET_ESTABLISHMENT_ALL_INTERNET_PARK_OPTIONS = 'Establishment.AllParkOptions'
export const GET_ESTABLISHMENT_ALL_PETTYPES = 'Establishment.AllPettypes'
export const GET_ESTABLISHMENT_ALL_REACH_FROM_ESTABLISHMENT = 'Establishment.AllReach'
export const GET_ESTABLISHMENT_ALL_REACH_TRANSPORTS = 'Establishment.AllReachTransports'
export const GET_ESTABLISHMENT_GENERAL_INFORMATION = 'Establishment.GeneralInformations'
export const GET_ESTABLISHMENT_REACH_INFORMATION = 'Establishment.ReachInformations'

export const GET_ESTABLISHMENT_CANCELLATION_POLICIE_BY_ID = 'Establishment.CancellationPolicie.GetById'
export const GET_ESTABLISHMENT_CANCELLATION_POLICIE_BY_ESTABLISHMENT_ID = 'Establishment.CancellationPolicie.GetByEstablishmentId'

export const GET_ESTABLISHMENT_EQUIPMENT_CATEGORIES = 'Establishment.EquipmentCategories.GetAll'
export const GET_ESTABLISHMENT_EQUIPMENT_ASSIGNATIONS = 'Establishment.EquipmentAssignations.GetAll'
export const GET_ESTABLISHMENT_EQUIPMENT_BY_CATEGORY = 'Establishment.Equipment.GetByCategoryId'
export const GET_ESTABLISHMENT_EQUIPMENT_ALL = 'Establishment.Equipment.GetAll'
export const GET_ESTABLISHMENT_EQUIPMENT_TYPE_ALL = 'Establishment.Equipment.Type.GetAll'

export const GET_ESTABLISHMENT_HEALTH_SECURITY_ALL = 'Establishment.HealthSecurity.GetAll'
export const GET_ESTABLISHMENT_HEALTH_SECURITY_ALL_ASSIGNATIONS = 'Establishment.HealthSecurity.GetAllAssignations'
export const GET_ESTABLISHMENT_HEALTH_SECURITY_BY_ESTABLISHMENT_ID = 'Establishment.HealthSecurity.GetAllByEstablishmentId'

export const GET_ESTABLISHMENT_POLICIE_BY_ESTABLISHMENT_ID = 'Establishment.Policie.GetAllByEstablishmentId'
export const GET_ESTABLISHMENT_POLICIE_TYPES = 'Establishment.Policie.GetTypes'
export const GET_ESTABLISHMENT_POLICIE_METHODS = 'Establishment.Policie.GetMethods'

export const GET_ESTABLISHMENT_MORE_INFORMATION = 'Establishment.MoreInformation'

export const GET_ESTABLISHMENT_PROXIMITY_TYPE = 'Establishment.Proximity.GetTypes'
export const GET_ESTABLISHMENT_PROXIMITY_CATEGORY = 'Establishment.Proximity.GetCategories'
export const GET_ESTABLISHMENT_PROXIMITY = 'Establishment.Proximity.GetAll'

export const GET_ESTABLISHMENT_ROOM = 'Establishment.Room.GetAll'
export const GET_ESTABLISHMENT_ROOM_TYPE = 'Establishment.RoomType.GetAll'
export const GET_ESTABLISHMENT_ROOM_EQUIPMENTS = 'Establishment.RoomEquipments.GetAll'

export const GET_ESTABLISHMENT_ROOM_CONFIGURATION_RANGE = 'Establishment.Room.ConfigurationRange.GetByRoomId'

export const GET_ESTABLISHMENT_ROOM_PRICING = 'Establishment.Room.Pricing.GetByEstablishmentId'

export const GET_ESTABLISHMENT_ROOM_CONFIGURATION_DATE = 'Establishment.Room.ConfigurationDate.GetByRoomId'
export const GET_ESTABLISHMENT_ROOM_CONFIGURATION_DATE_BY_ESTABLISHMENT_ID = 'Establishment.Room.ConfigurationDate.GetByEstablishmentId'

export const GET_ESTABLISHMENT_PLANNING_CONFIGURATION = 'Establishment.Planning.Configuration.GetAll'
export const GET_ESTABLISHMENT_PLANNING_CONFIGURATION_BY_DAY = 'Establishment.Planning.Configuration.GetByDay'
export const GET_ESTABLISHMENT_PLANNING_CONFIGURATION_GET_ROOMS_AVAILABILITY = 'Establishment.Planning.Configuration.GetRoomsAvailability'


export const GET_ESTABLISHMENT_SPOKEN_LANGUAGES_ALL = 'Establishment.SpokenLanguages.GetAll'
export const GET_ESTABLISHMENT_SPOKEN_LANGUAGES_ALL_BY_CULTURE = 'Establishment.SpokenLanguages.GetAllByCulture'
export const GET_ESTABLISHMENT_SPOKEN_LANGUAGES_ALL_BY_ASSIGNATION = 'Establishment.SpokenLanguages.GetAllByAssignationsParentId'

export const GET_ESTABLISHMENT_TVA_GET_BY_ESTABLISHMENT_ID = 'Establishment.Tva.GetByEstablishmentId'

export const GET_ESTABLISHMENT_NOTIFICATION = 'Establishment.Notification.Get'
export const GET_ESTABLISHMENT_CONFIGURATION = 'Establishment.Configuration.Get'
export const GET_ESTABLISHMENT_TVA = 'Establishment.TVA.Get'

export const GET_ESTABLISHMENT_ALL_HOME = 'Establishment.Home.GetAll'

export const GET_FILE_SUBCATEGORIES_BY_CATEGORY_ID = 'File.Subcategories.GetByCategory'
export const GET_FILES_BY_ESTABLISHMENT_ID = 'File.GetFiles.ByEstablishment'
export const GET_FILE_MAIN_BY_ESTABLISHMENT_ID = 'File.GetMainFile.ByEstablishment'

export const GET_SUSTAINABLE_ALL = 'Establishment.Sustainable.GetAll'
export const GET_SUSTAINABLE_ASSIGNATIONS_BY_ESTABLISHMENT_ID = 'Establishment.Sustainable.GetAssignations'

export const GET_ESTABLISHMENT_SUSTAINABLE_ALL = 'Establishment.Sustainable.Item.GetAll'

export const GET_CUSTOMER_NOTIFICATION = 'Customer.Notification'
export const GET_CUSTOMER_FAVORITES = 'Customer.Favorites'

export const GET_MO_LANDING_ITEM_ALL_HOME = 'Mo.LandingItem.GetAllHome'
export const GET_MO_LANDING_ITEM_ALL = 'Mo.LandingItem.GetAll'
export const GET_MO_LANDING_ITEM_ALL_BY_CATEGORY_ID = 'Mo.LandingItem.GetAllByCategoryId'
export const GET_MO_LANDING_ITEM_BY_ID = 'Mo.LandingItem.GetById'


export const GET_MANGO_PAY_CARD_BY_USER_ID = 'MangoPay.CardByUserId'
export const GET_MANGO_PAY_USER_BY_EMAIL = 'MangoPay.UserByEmail'
export const GET_MANGO_PAY_BANK_BY_USER_ID = 'Backoffice.MangoPay.Bank.ByUserId'
export const GET_MANGO_PAY_ALL_KYC_DOCS_BY_USER_ID = 'Backoffice.MangoPay.KYC.Docs.ByUserId'
export const GET_MANGO_PAY_ALL_KYC_DOCS_BY_USER_ID_ALL_STATUS = 'Backoffice.MangoPay.KYC.Docs.ByUserId.AllStatus'
export const GET_MANGOPAY_PAYIN_GET_BY_ID = 'MangoPay.PayIn.GetById'
export const GET_MANGOPAY_TABLE_GET_BY_USER_ID = 'MangoPay.Table.GetByUserId'


export const GET_STATISTIC_HOME = "Home.Statistic.GetAll"

export const GET_ADVERT_BOOKING_GET_BY_ID = "Advert.Booking.GetById"
export const GET_ADVERT_BOOKING = "Advert.GetBooking"
export const GET_ADVERT_CANCELLATION_POLICY = "Advert.CancellationPolicy.GetById"
export const GET_ADVERT_BY_ID = "Advert.GetById"
