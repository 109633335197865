import { TCheckEmailPayload } from "@ephatta/services"
import { useEphattaMutation } from "../.."
import { useLoginService } from "./useLoginService"

export const useCheckEmail = () => {
    const loginService = useLoginService()
    return useEphattaMutation((payload: TCheckEmailPayload) => loginService.checkEmail({
        ...payload,
        applicationId: parseInt(process.env.REACT_APP_ID!)
    }))
}
