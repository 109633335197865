import { TCustomerFavoriteCreatePayload } from '@ephatta/services';
import { useTranslation } from 'react-i18next';
import { useEphattaMutation } from '../../..';
import { useCustomerFavoriteService } from './useCustomerFavoriteService';

export const useCustomerFavoriteCreate = () => {
    const service = useCustomerFavoriteService()
    const { t } = useTranslation()

    return useEphattaMutation(
        (payload: TCustomerFavoriteCreatePayload) => service.create(payload),
        {
            successMessage: t("Pages.Advert.Details.CreateFavoriteList.Success.Message")
        }
    )
} 