import { GET_STATISTIC_HOME } from 'constants/queryKeys';
import { useEphattaQuery } from '../..';
import { useStatisticServices } from './useStatisticServices';

export const useStatisticGetHome = (suspense: boolean = false) => {

    const service = useStatisticServices()
    
    return useEphattaQuery(
        GET_STATISTIC_HOME,
        () => service.getStatHome(),
        { suspense }
    )
} 