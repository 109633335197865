import { Box, Button, Collapse, Stack, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { QuotesIcon } from "../icons/ProfileIcons";
import SquareLabel from "../components/SquareLabel";
import { useCustomerDetails, useEnv, useTranslations } from "@ephatta/components";
import DOMPurify from "dompurify";
import { useState } from "react";
import { MileageTablesIcon } from "../icons/MileageTablesIcon";
import { TClientIdentity } from "@ephatta/services";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@mui/styles";
import { QuotesComponent } from "pages/announcement/components/availability-cancelation/QuotesComponent";
import { ShareAffiliationCodeDialog } from "components/affiliation/ShareAffiliationCodeDialog";

const ConfidenceIndex = () => {

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    // Open the collapse when the screen is small
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const BOX_PADDING = 2;

    const [
        CONFIDENCE_INDEX_TITLE,
        CONFIDENCE_INDEX_DESCRIPTION,
        AMBASADOR_DESCRIPTION,
        SUPER_AMBASADOR_DESCRIPTION,
        SUPER_AMBASADOR_LABEL,
        AMBASADOR_LABEL,
        SHARE_AFFILIATION_CODE
    ] = useTranslations(i18nKeys)

    const env = useEnv<TClientIdentity | null>()
    const { data, invalidateQuery, isLoading } = useCustomerDetails(env?.userId!)
    const classes = useStyle()

    const [openShareCodeDialog, setOpenShareCodeDialog] = useState<boolean>(false)

    return (
        <>
            <ShareAffiliationCodeDialog
                onClose={() => setOpenShareCodeDialog(false)}
                open={openShareCodeDialog}
            />
            <Box
                sx={{
                    height: '100%',
                    boxSizing: "border-box",
                }}
            >
                <Box className={classes.confidentIndexContainer}>
                    <Box display="flex" mt={5}>
                        {/* <Box>
                            <QuotesIcon />
                        </Box>
                        <Typography
                            fontWeight={700}
                            variant='body1'
                            lineHeight={"30px"}
                            fontFamily={"Bradley Hand, sans-serif"}
                            color={"primary"}
                            sx={{
                                fontSize:"24px !important"
                            }}
                        >
                            {CONFIDENCE_INDEX_DESCRIPTION}
                        </Typography> */}
                        <QuotesComponent flexDirection="column" message={CONFIDENCE_INDEX_DESCRIPTION} />
                    </Box>
                    <Typography
                        className={classes.confidentIndexContainerTitle}
                        variant="body1"
                        fontWeight={'bold'}
                        lineHeight={'20px'}
                        align={'center'}
                        color={'primary'}
                        textTransform={'uppercase'}
                        paddingX={1}
                        sx={{ marginTop: { xs: open ? '25px' : '0', sm: '100px' } }}
                        dangerouslySetInnerHTML={
                            { __html: DOMPurify.sanitize(CONFIDENCE_INDEX_TITLE.replace(isSmall ? '<br/>' : '', '')) }
                        }
                        />
                        
                    <Box sx={{ marginTop: { xs: open ? '25px' : '0', sm: '50px' } }}>
                        <Collapse
                            in={isSmall ? open : true}
                        >
                            <Box display="flex" flexDirection="column" sx={{ gap: { xs: '25px', sm: '50px' } }}>
                                {/* <Box display="flex">
                                    <Box>
                                        <QuotesIcon />
                                    </Box>
                                    <Typography
                                        fontWeight={700}
                                        variant='body1'
                                        lineHeight={"30px"}
                                        fontFamily={"Bradley Hand, sans-serif"}
                                        color={"primary"}
                                        sx={{
                                            fontSize:"24px !important"
                                        }}
                                    >
                                        {CONFIDENCE_INDEX_DESCRIPTION}
                                    </Typography>
                                </Box> */}
                                <Box display="flex" justifyContent="center">
                                    {isLoading ? <CircularProgress /> : <MileageTablesIcon angle={Math.min(data?.customer?.trustRate!, 90)} />}
                                </Box>
                                <Box display="flex" flexDirection="column" sx={{ gap: { xs: '25px', sm: '50px' } }}>
                                    <SquareLabel boxColor={theme.palette.secondary.main}>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="700" color={theme.palette.secondary.main}>{AMBASADOR_LABEL}</Typography><br/>
                                            {AMBASADOR_DESCRIPTION}
                                        </Typography>
                                    </SquareLabel>
                                    <SquareLabel boxColor={theme.palette.primary.main}>
                                        <Typography variant="body1">
                                            <Typography component="span" fontWeight="700">{SUPER_AMBASADOR_LABEL}</Typography><br/>
                                            {SUPER_AMBASADOR_DESCRIPTION}
                                        </Typography>
                                    </SquareLabel>
                                </Box>
                                <Button
                                    // variant="contained"
                                    // color="primary"
                                    // fullWidth
                                    // sx={{
                                    //     textTransform: "uppercase"
                                    // }}
                                    // className={classes.shareAffiliationButton}
                                    variant="contained" 
                                    color="primary" 
                                    fullWidth
                                    className={`text-transform-none cta-outlined-large ${classes.shareAffiliationButton}`}
                                    onClick={() => setOpenShareCodeDialog(true)}
                                >
                                    {SHARE_AFFILIATION_CODE}
                                </Button>
                            </Box>
                        </Collapse>
                    </Box>

                    {
                        isSmall &&
                        <Box 
                            textAlign="center" 
                            mt="10px"
                            onClick={() => setOpen(!open)}>
                            <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease-in-out',
                                cursor: 'pointer'
                            }}>
                                <path d="M21.66 0.503906L12.5 9.15708L3.32 0.503906L0.5 3.16787L12.5 14.5039L24.5 3.16787L21.66 0.503906Z" fill="#061566" />
                            </svg>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    confidentIndexContainer: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '5px',
        height: '100%',
        padding: '0 20px 20px 20px',
    },
    confidentIndexContainerTitle: {
        '&.MuiTypography-root': {
            fontSize: '15.5px',
            marginTop: '100px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '17px',
                marginTop: '25px',
            }
        }
    },
    shareAffiliationButton: {
        '&.MuiButton-root': {
            [theme.breakpoints.up('xs')]: {
                padding: "9px 10px"
            }
        }
    }
}))

const i18nKeys = [
    "Page.MyProfile.ConfidenceIndex.Title",
    "Page.MyProfile.ConfidenceIndex.Description",
    "Page.MyProfile.Ambasador.Description",
    "Page.MyProfile.SuperAmbasador.Description",
    "Page.MyProfile.SuperAmbasador.Label",
    "Page.MyProfile.Ambasador.Label",
    "Page.MyProfile.ShareAffiliationCode.Label"
]

export default ConfidenceIndex;