import ToolKit from './Toolkit';
var log = function (level, msg, options) {
    if (!Object.values(LogLevel).includes(level)) {
        return;
    }
    var consoleFn;
    switch (level) {
        case LogLevel.Info:
            consoleFn = console.log; // eslint-disable-line no-console
            break;
        case LogLevel.Warning:
            consoleFn = console.warn; // eslint-disable-line no-console
            break;
        case LogLevel.Error:
            consoleFn = console.error; // eslint-disable-line no-console
            break;
        default:
            return;
    }
    if (!ToolKit.isFunction(consoleFn))
        return;
    if (ToolKit.isUndefinedOrNull(options) || (ToolKit.isUndefinedOrNull(options.method) &&
        ToolKit.isUndefinedOrNull(options.context))) {
        consoleFn.apply(void 0, msg);
    }
};
var Logger = /** @class */ (function () {
    function Logger() {
    }
    Logger.prototype.setConfig = function (config) {
        this.internalConfig = config;
    };
    Logger.prototype.info = function (msg, options) {
        log(LogLevel.Info, msg, options);
    };
    Logger.prototype.warn = function (msg, options) {
        log(LogLevel.Warning, msg, options);
    };
    Logger.prototype.error = function (msg, options) {
        log(LogLevel.Error, msg, options);
    };
    return Logger;
}());
export var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["Info"] = 0] = "Info";
    LogLevel[LogLevel["Warning"] = 1] = "Warning";
    LogLevel[LogLevel["Error"] = 2] = "Error";
})(LogLevel || (LogLevel = {}));
export default new Logger();
