import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const MyFavorites = React.lazy(() => import('./MyFavorites'))
const MyFavoritesDetails = React.lazy(() => import('./MyFavoritesDetails'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/my-favorites',
        element: <MyFavorites />,
    },
    {
        path: '/my-favorites/:id',
        element: <MyFavoritesDetails />,
    },
]

export default Routes
