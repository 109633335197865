import { useCallback } from "react"
import { MangoPayCardRegistrationPayload, useMangoPayCardRegistration } from "./useMangoPayCardRegistration"

export const useMangoPayInit = () => {
    
    const mangoPayRegistration = useMangoPayCardRegistration()

    const registration = useCallback(async (params: MangoPayCardRegistrationPayload): Promise<any> => {
        const response = await mangoPayRegistration({
            CardType: params.CardType,
            Currency: params.Currency,
            UserId: params.UserId,
        })

        return response
    }, [])

    return registration
}
