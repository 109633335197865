import React from "react"
import { InputLabelProps, useTheme } from "@mui/material"
import { InputLabel as MUIInputLabel } from "@mui/material"

export const InputLabel = ({ label, ...props }: Props) => {

    const theme = useTheme()

    return (
        <MUIInputLabel 
            {...props}
            style={{
                fontWeight: 'bold',
                color: theme.palette.text.primary,
                ...props.style,
            }}>
            {label}
        </MUIInputLabel>
    )
}

type Props = InputLabelProps & {
    label: true | React.ReactChild | React.ReactFragment | React.ReactPortal | React.ReactNode
}
