import { useTranslations } from "@ephatta/components"
import { Box, Button, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/styles";
import { useMemo } from "react";


export const PageError = (props: Props) => {

    const [
        TITLE,
        DESCRIPTION,
        RETRY
    ] = useTranslations(i18n)
    const theme = useTheme()
    const isSmall = useMediaQuery("(max-width:600px)");

    const height = useMemo(()=>{
        if(isSmall){
            return 'calc(100vh-254px)'
        }
        return 'calc(100vh-181px)'
    },[isSmall])

    return (
        <Box p={2} pt={5} textAlign="center" m="auto" height={height} >
            <img src="/assets/img/404-error.png" style={{ maxWidth: '100%' }} />
            <Typography
                variant="h1"
                className="font-family-bradley"
                color={"primary"}
                mt="60px"
            >
                { props.title || TITLE }
            </Typography>
            <Typography variant="body1" color="primary">
                { props.description || DESCRIPTION }
            </Typography>
            <Box display="flex" justifyContent="center" mt="30px">
                <Button variant="contained" color="primary" onClick={props.onRetryClick}>
                    { props.retry || RETRY }
                </Button>
            </Box>
        </Box>
    )
}

type Props = {
    title?: string
    description?: string
    retry?: string
    onRetryClick?: () => void
}

const i18n = [
    'Client.Page.Error.Front.Title',
    "Client.Page.Error.Front.Description",
    "Client.Page.Error.Front.Retry.Button.Label"
]
