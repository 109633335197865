import { TBackofficeIdentity, TClientIdentity } from "@ephatta/services"
import { useRuntimeEnv } from ".."
/**
 * 
 * @deprecated use "useRuntimeEnv" instead
 * @returns 
 */
export const useEnv = <TData = TBackofficeIdentity | TClientIdentity | null>() => {
    return useRuntimeEnv<TData>()!
}


