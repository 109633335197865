import { TCustomerRelationshipCreatePayload } from "@ephatta/services";
import { useEphattaMutation, useTranslations } from "hooks";
import { useCustomerRelationshipService } from "./useCustomerRelationshipService";

export const useCustomerRelationshipCreate = () => {
    const [
        SUCCESS_MESSAGE,
        ERROR_MESSAGE,
        NOT_MEMBER_ERROR_MESSAGE,
    ] = useTranslations(i18n)
    const service = useCustomerRelationshipService()
    return useEphattaMutation(
        (payload: TCustomerRelationshipCreatePayload) => service.create(payload) , 
        {
            successMessage : SUCCESS_MESSAGE,
            transformErrorMessage: (error: any) => {     
                if ([601,602].includes(error?.status)) {
                    return NOT_MEMBER_ERROR_MESSAGE
                }
                return ERROR_MESSAGE
            }
        }
    )
}

const i18n =[
    "Pages.MyNetWork.MyAmicasiens.FriendRequestSent.SuccessMessage",
    "Pages.MyNetWork.MyAmicasiens.FriendRequestSent.ErrorMessage",
    "Pages.MyNetWork.MyAmicasiens.FriendRequestSent.NotMember.ErrorMessage"
]