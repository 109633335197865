import { useEphattaMutation } from "hooks/utils"
import { useCallback } from "react"
import { useMangoPayRequest } from "../useMangoPayRequest"


export const useMangopayCreateKycPages = () => {

    const mangoPayRequest = useMangoPayRequest()

    const createKycPages = useCallback((payload: TMangoPayKycPagePayload) => {
        
        return Promise.all(payload.Files?.map((file) =>
            mangoPayRequest<TMangoPayKycPagePayload>({
                apiUrl: `users/${payload.userId}/kyc/documents/${payload.KYCDocumentId}/pages`,
                payload: {
                    File: file,
                }
            })
        ) || [])
    }, [])
    return useEphattaMutation(createKycPages)
}


type TMangoPayKycPagePayload = {
    userId?: string,
    KYCDocumentId?: string,
    File?: string
    Files?: string[]
}