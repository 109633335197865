import { SvgIcon, SvgIconProps } from "@mui/material"

export const AddIcon = (props: SvgIconProps) => {

    return (
        <SvgIcon width="31" height="31" viewBox="0 0 31 31" {...props}>
            <path
                d="M28.985 13.485H17.515V2.015C17.515 0.898999 16.616 0 15.5 0C14.384 0 13.485 0.898999 13.485 2.015V13.485H2.01499C0.898993 13.485 0 14.384 0 15.5C0 16.616 0.898993 17.515 2.01499 17.515H13.485V28.985C13.485 30.101 14.384 31 15.5 31C16.616 31 17.515 30.101 17.515 28.985V17.515H28.985C30.101 17.515 31 16.616 31 15.5C31 14.384 30.101 13.485 28.985 13.485Z"
            />
        </SvgIcon>

    )
}

