import { useEphattaMutation } from '../../..';
import { useCustomerFavoriteService } from './useCustomerFavoriteService';

export const useCustomerFavoriteDeleteAssignement = () => {
    const service = useCustomerFavoriteService()
    return useEphattaMutation(
        ({ id, advertId }: TAssignmentPayload) => service.deleteAssignement(id, advertId)
    )
}

export type TAssignmentPayload = {
    id: number
    advertId: string
}
