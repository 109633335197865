import { useEphattaQuery } from "hooks";
import { useSEOServices } from "./useSEOServices";

export const useSEOGetAll = () => {
    const service = useSEOServices()
    return useEphattaQuery(
        'SEO.GetAll',
        () => service.getAll()
    );
};
