import { TAdminTransactionPayload } from "@ephatta/services"
import { useEphattaQuery } from "@hooks/utils"
import { useAdminTransactionsServices } from "./useAdminTransactionsServices"



export const useAdminTransactions = (payload: TAdminTransactionPayload, suspense?: boolean, active:boolean=true) => {
    const services =  useAdminTransactionsServices()
  return useEphattaQuery(
    ["Get.Admintransaction", payload],
    () => services.getAll(payload), {enabled: active, suspense}
  )
}
