import { useEphattaQuery } from "@hooks/utils"
import { useCallback } from "react"
import { useMangoPayRequest } from "../useMangoPayRequest"
import { TMangoPayKYCDocument } from "./useMangopayCreateKycDocument"


export const useListKycDocumentByUser = (payload: TMangoPayKycListPayload) => {

    const mangoPayRequest = useMangoPayRequest()

    const listKycDocuments = useCallback(() => {
        return mangoPayRequest<TMangoPayKycListPayload , TMangoPayKYCDocument[]>({
            method: 'GET',
            apiUrl: `users/${payload.userId}/kyc/documents`
        })
    }, [payload.userId])
    return useEphattaQuery(['LIST_KYC_DOCUMENT' , payload.userId], listKycDocuments , {
        enabled: payload.userId !== undefined
    })
} 


type TMangoPayKycListPayload = {
    userId?: string,
}