import { useMemo } from 'react'
import { MangopayVault } from '@mangopay/vault-sdk';

export const useMangoPay = () => {
    const options = {
        clientId: process.env.REACT_APP_MONGOPAY_CLIENT_ID!,
        environment: process.env.REACT_APP_MONGOPAY_API_ENV!,
    };
    
    return useMemo(() => {
        const vault = MangopayVault.initialize(options);
        return vault;
    }, [])
}
