import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Login = React.lazy(() => import('./Login'))
const ForgotPassword = React.lazy(() => import('./ForgotPassword'))
const ResetPassword = React.lazy(() => import('./ResetPassword'))
const ResetAccount = React.lazy(() => import('./ResetAccount'))
const EmailSent = React.lazy(() => import('./EmailSent'))
const RegistrationNotMicasa = React.lazy(() => import('./RegistrationNotMicasa'))
const Registration = React.lazy(() => import('./Registration'))
const EmailConfirmation = React.lazy(() => import('./EmailConfirmation'))
const EmailForgotSent = React.lazy(()=> import('./EmailForResetPasswordSent'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />
    },
    {
        path: '/reset-password',
        element: <ResetPassword />
    },
    {
        path: '/reset-account',
        element: <ResetAccount />
    },
    {
        path: '/registration',
        element: <Registration/>
    },
    {
        path: '/email-sent',
        element: <EmailSent/>
    },
    {
        path: '/email-confirm',
        element: <EmailConfirmation/>
    },
    {
        path: '/email-forgot-sent',
        element: <EmailForgotSent />
    }
]
export const RegistrationNotMicasaRoutes = [
{
    path: '/registration-not-micasa',
    element: <RegistrationNotMicasa/>
}
]

export default Routes
