import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const BookingList = React.lazy(() => import('./BookingList'))
const BookingCreate = React.lazy(() => import('./BookingCreate'))
const BookingDetails = React.lazy(() => import('./BookingDetails'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/my-reservations',
        element: <BookingList />,
    },
    {
        path: '/my-reservations/create',
        element: <BookingCreate />,
    },
    {
        path: '/reservation/:id',
        element: <BookingDetails />,
    }
]

export default Routes
