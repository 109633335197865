import { useEnv, useGoogleTagsManagerEvent, useNotificationCount, useTranslations } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";
import { Box, Button, Container, IconButton, Typography, useTheme, Badge, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { EnveloppeIcon } from "../../icons/EnveloppeIcon";
import { SearchIcon } from "../../icons/SearchIcon";
import { NavBarMenu } from "./NavBarMenu";
import { NavBarUserMenu } from "./NavBarUserMenu";

export const NavBar = () => {

    const theme = useTheme();
    const [
        FIND_CASA,
        SUGGEST_CASA,
        INVITE_YOUR_FRIENDS,
        SHOW_MY_ANNOUNCEMENT
    ] = useTranslations(i18nKeys);

    const classes = useStyle()
    const env = useEnv<TClientIdentity>()
    const { notifications, pendingInvitations, invalidateQuery } = useNotificationCount()
    const pushEvent = useGoogleTagsManagerEvent()

    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.primary.light, boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)' }}>
                <Container maxWidth={'lg'} sx={{ paddingLeft: { xs: 0, sm: '16px' }, height: '38px'}} style={{paddingRight: '20px'}}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        height="38px"
                    >
                        <>
                            <Box
                                sx={{ display: { xs: 'flex', sm: 'none' } }} maxWidth="1030px">
                                <NavBarMenu />
                            </Box>
                            {
                                env.isMember &&
                                <Box
                                    alignItems="center"
                                    gap="28px"
                                    sx={{ display: { xs: 'none', sm: 'flex' } }}>
                                    <Link to="/find-casa" onClick={() => pushEvent({ event: 'cta-trouverunecasa' })}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '8px',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <IconButton
                                                color="secondary"
                                                component="span"
                                                className={classes.buttonSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                            <Typography variant="h5" className={classes.menuItem}>
                                                {FIND_CASA}
                                            </Typography>
                                        </Box>
                                    </Link>
                                    <Link to="/advert/create" onClick={() => pushEvent({ event: 'cta-proposervotrecasa' })}>
                                        <Typography variant="h5" className={classes.menuItem}>
                                            {SUGGEST_CASA}
                                        </Typography>
                                    </Link>
                                </Box>
                            }
                            <Box
                                className={classes.buttonContainer}>
                                <Box>
                                    <Link to="/my-network" onClick={() => pushEvent({ event: 'cta-invitervosamis-membre' })}>
                                        <Badge
                                            className={classes.badge}
                                            badgeContent={pendingInvitations || 0}
                                            color="primary"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}>
                                            <Button
                                                component="span"
                                                color={'secondary'}
                                                variant={'contained'}
                                                className={`${classes.buttonAction} ${classes.buttonAddFriend}`}
                                            >
                                                <img src="/assets/img/icon/coopter.svg" />
                                                <Typography component="span" textTransform="uppercase" fontWeight="bold" ml="9px">
                                                    {INVITE_YOUR_FRIENDS}
                                                </Typography>
                                            </Button>
                                        </Badge>
                                    </Link>
                                </Box>
                                {
                                    env.adverts?.length! > 0 &&
                                    <Box>
                                        <Link to="/my-announcements">
                                            <Button
                                                component="span"
                                                color={'secondary'}
                                                variant={'outlined'}
                                                className={`${classes.buttonAction} ${classes.buttonHome}`}>
                                                <img src="/assets/img/icon/home.svg" style={{ color: '#fff' }} />
                                                <Typography component="span" textTransform="uppercase" fontWeight="bold" ml="9px">
                                                    {SHOW_MY_ANNOUNCEMENT}
                                                </Typography>
                                            </Button>
                                        </Link>
                                    </Box>
                                }

                                <Box>
                                    <NavBarUserMenu />
                                </Box>
                                {
                                    env.isMember &&

                                    <Box>
                                        <Link to={"/my-inbox"}>
                                            <IconButton
                                                color="secondary"
                                                style={{ padding: 0 }}
                                                sx={{
                                                    '&:hover': {
                                                        color: theme.palette.primary.main,
                                                        transition: 'all ease 250ms'
                                                    }
                                                }}>

                                                <Badge
                                                    className={classes.badge}
                                                    badgeContent={notifications || 0}
                                                    color="primary"
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    sx={{ left: 5 }}>
                                                    <EnveloppeIcon style={{ width: 33, height: 22 }} />
                                                </Badge>
                                            </IconButton>
                                        </Link>
                                    </Box>
                                }
                            </Box>
                        </>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    navbarContainer: {
        backgroundColor: theme.palette.primary.light,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'
    },
    menuItem: {
        '&.MuiTypography-root': {
            color: theme.palette.primary.main,
            fontWeight: 700,
            textTransform: "uppercase",
            padding: '13px 0'
        }
    },
    buttonContainer: {
        display: 'flex',
        alignItems: "center",
        gap: "18px",
        marginLeft: 'auto',
        padding: '7px 0',
        [theme.breakpoints.down('sm')]: {
            gap: "8px",
            padding: '3px 0'
        }

    },
    buttonSearch: {
        '&.MuiIconButton-root': {
            padding: '0px 9px 0px 11px',
            borderRadius: 0,
            background: theme.palette.secondary.main,
            height: '39px',
            width: '39px',
            pointerEvents: 'none'
        },
    },
    buttonAction: {
        '&.MuiButton-root': {
            '& span': {
                fontSize: 11
            },
            [theme.breakpoints.down('lg')]: {
                '& span': {
                    display: 'none'
                },
            },
        },
    },
    buttonAddFriend: {
        '&.MuiButton-root': {
            padding: '4px 12px',
            '& img': {
                width: 35,
                height: 19,
            },
        }
    },
    buttonHome: {
        '&.MuiButton-root': {
            padding: '3px 10px',
            '& img': {
                // width: 35,
                height: 19,
            },
        }
    },
    badge: {
        '& .MuiBadge-badge': {
            border: `0.5px solid white`,
            fontSize: 10,
            padding: '10px 5px',
            top: 2,
        }
    }
}))

const i18nKeys = [
    "Home.Page.Home.Header.FindCasa.Label",
    "Home.Page.Home.Header.SuggestYourCasa.Label",
    "Home.Page.Home.Header.InviteYourFriend.Label",
    "Home.Page.Home.Header.ShowMyAnnouncement.Label"
]
