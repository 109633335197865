import { TBankDeactivatePayload } from "@ephatta/services"
import { useEphattaMutation } from "../../.."
import { useMangoPayBankService } from "./useMangoPayBankService"

export const useMangoPayDeactivateBank = () => {

    const service = useMangoPayBankService()
    
    return useEphattaMutation<unknown | null>(({ userId, bankId, ...payload }: { userId: string, bankId: string } & TBankDeactivatePayload) => {
        return service.deactivateBank(userId, bankId, payload)
    })
}
