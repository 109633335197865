import { TCustomerRelationshipUpdatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useCustomerRelationshipService } from "./useCustomerRelationshipService";

export const useCustomerRelationshipUpdate = () => {
    const service = useCustomerRelationshipService()
    return useEphattaMutation(
        (payload: TCustomerRelationshipUpdatePayload) => service.update(payload)
    )
}
