import { TAdvertCancellationPolicy } from "@ephatta/services"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useAdvertCancellationPolicy } from "./useAdvertCancellationPolicy"

export const useAdvertCancellationPolicyOptions = () => {

    const { i18n } = useTranslation()
    const { data: options } = useAdvertCancellationPolicy()

    return useMemo((): Array<TAdvertCancelationPolicyOptions> => {
        return options?.map((item) => ({ 
            ...item,
            value: item?.id!, 
            label: item?.label?.find((item1) => item1.culture === i18n.language)?.value || '' 
        })) || []
    }, [options])
} 

export type TAdvertCancelationPolicyOptions = Omit<TAdvertCancellationPolicy, 'label'> & {
    value: number,
    label: string
}

