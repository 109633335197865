import { TAdvertBookingCreate } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useAdvertBookingService } from "./useAdvertBookingService";

export const useAdvertBookingCreate = () => {
    const {t} = useTranslation()
    const service = useAdvertBookingService()
    return useEphattaMutation(
        (payload: TAdvertBookingCreate) => service.create(payload),
        {
            successMessage: t("Booking.actions.createdMessage")
        }
    )
}