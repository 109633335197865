import { TRegisterRequest } from "@ephatta/services"
import { useEphattaMutation } from "../.."
import { useLoginService } from "./useLoginService"

export const useRegister = (isMiddleOffice: boolean = false) => {
    const loginService = useLoginService()

    return useEphattaMutation((payload: TRegisterRequest) => loginService.register(payload), {
        log: (data, variables) => {
            if (!isMiddleOffice) {
                return false
            }
            return `L'utilisateur <a href="/mo-user">${variables?.profil?.firstname} ${variables?.profil?.lastname}<${variables?.email}></a> ajouté.`
        }
    })
}
