import { TChangeUserEmailPayload } from "@ephatta/services"
import { useEphattaMutation } from "hooks/utils/useEphattaMutation"
import { useTranslation } from "react-i18next"
import { useLoginService } from "./useLoginService"



export const useChangeEmail = () => {
    const loginService = useLoginService()
    const {t} = useTranslation()

    return useEphattaMutation(
        (payload : TChangeUserEmailPayload) => loginService.changeUserEmail(payload),
        {
            successMessage: t("Page.MyProfile.ConnexionInformation.ChangeEmail.Success")
        }
    )
}

