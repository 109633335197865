import { Box, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslations } from "@ephatta/components";
import { makeStyles } from "@mui/styles";

const FooterSection = () => {
    const theme = useTheme();

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    // ====== TRANSLATIONS ===========
    const [
        CREDENTIALS_TITLE,
        LEGACY_NOTICE
    ] = useTranslations(i18nKeys);
    // ======= END TRANSLATIONS ======

    const classes = useStyles()

    return (
        <Box
            p={1}
            sx={{
                background: theme.palette.secondary.main,
            }}
        >
            <Stack
                className={classes.whiteLink}
                direction={isSmall ? 'column' : 'row'}
                sx={{
                    fontSize: isSmall ? '10px' : '14px',
                    fontWeight: '600',
                    color: '#fff'
                }}
                divider={isSmall ? null : <Divider color={'white'} orientation={'vertical'} sx={{
                    width: '1px',
                    height: '17px',
                }} />}
                justifyContent={'center'}
                alignContent={'center'}
                spacing={isSmall ? 2 : 3}
            >
                <span>
                    <a href="/legal-notice" >
                        <Typography variant='h5'>
                            {LEGACY_NOTICE}
                        </Typography>
                    </a>
                </span>
                <span>
                    <a href="/cgu">
                        <Typography variant='h5'>
                            CGS
                        </Typography>
                    </a>
                </span>
                <span>
                    <a href="/privacy-policy">
                        <Typography variant='h5'>
                            {CREDENTIALS_TITLE}
                        </Typography>
                    </a>
                </span>
                <span>
                    <Typography variant='h5'>
                        <a href={'mailto:contact@micasaestucasa.site'} style={{
                            fontWeight: '700',
                            color: theme.palette.primary.main,
                            textDecoration: 'none'
                        }}>contact@micasaestucasa.site</a>
                    </Typography>
                </span>
            </Stack>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    whiteLink: {
        '& a': {
            color: 'inherit'
        }
    }
}))

const i18nKeys = [
    "Home.Page.Home.Footer.Contract.Label",
    "Home.Page.Home.Footer.LegalNotice.Label"
]
export default FooterSection;
