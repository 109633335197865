import { useEphattaMutation } from "hooks/utils"
import { useCallback } from "react"
import { useMangoPayRequest } from "../useMangoPayRequest"


export const useMangopayCreateKycDocument = () => {

    const mangoPayRequest = useMangoPayRequest()

    const createKycDocument = useCallback((payload: TMangoPayKYCDocumentPayload) => {
        return mangoPayRequest<TMangoPayKYCDocumentPayload , TMangoPayKYCDocument>({
            apiUrl: `users/${payload.userId}/kyc/documents`,
            payload: {
                Tag: payload.Tag,
                Type: payload.Type,
            }
        })
    }, [])
    return useEphattaMutation(createKycDocument)
}


type TMangoPayKYCDocumentPayload = {
    userId?: string,
    Type?: TypeKycDocument
    Tag?: string
}

export type TMangoPayKYCDocument = {
    CreationDate:number
    id: string
    Flags:string[]
    ProcessedDate:number
    RefusedReasonMessage:string
    RefusedReasonType:string
    tag:string
    Type:string
    UserId:string
    status?:TypeKycDocumentStatus
}

export type TypeKycDocument = 'IDENTITY_PROOF' | 'REGISTRATION_PROOF' | 'ARTICLES_OF_ASSOCIATION' | 'SHAREHOLDER_DECLARATION' | 'ADDRESS_PROOF' 
export type TypeKycDocumentStatus = 'CREATED' | 'VALIDATION_ASKED' | 'VALIDATED' | 'REFUSED' | 'EMPTY' | 'OUT_OF_DATE'