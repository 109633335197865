import { SvgIcon, SvgIconProps } from "@mui/material"

export const EnveloppeIcon = (props: SvgIconProps) => {

    return (
        <SvgIcon width="29" height="21" viewBox="0 0 29 21"  {...props}>
            <path d="M28.1262 20.4961C28.0845 20.4987 28.0424 20.5 28 20.5H2C1.77473 20.5 1.55816 20.4628 1.35611 20.3941L10.22 11.54L12.76 13.94C13.87 14.99 15.53 14.99 16.64 13.94L19.18 11.53L28.1262 20.4961Z"/>
            <path d="M0.803873 0.896952C1.13747 0.647632 1.55149 0.5 2 0.5H28C28.0274 0.5 28.0546 0.50055 28.0818 0.50164C28.331 0.551841 28.5359 0.655834 28.7 0.770012L15.78 13.05C15.15 13.65 14.26 13.65 13.61 13.04L0.803873 0.896952Z"/>
            <path d="M0.0835645 1.92608L9.33 10.69L0.358107 19.6423C0.132372 19.3185 0 18.9247 0 18.5V2.5C0 2.30053 0.0292021 2.10787 0.0835645 1.92608Z"/>
            <path d="M29.44 1.79001L20.08 10.68L29.21 19.82C29.41 19.51 29.52 19.14 29.52 18.76V2.31001C29.52 2.14001 29.5 1.97001 29.44 1.79001Z"/>
        </SvgIcon>
            
    )
}

