import { TAdvertSearchPayload } from "@ephatta/services"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useInfiniteQuery, useQueryClient } from "react-query"
import { useAdvertService } from "./useAdvertService"

export const useFindCasa = (payload: TAdvertSearchPayload, suspense: boolean = false) => {
    const service = useAdvertService()
    const [stopFetching, setStopFetching] = useState<boolean | undefined>(false)
    const queryClient = useQueryClient()

    const query = useInfiniteQuery([cacheKey, payload], (params) => {
        return service.findCasa(params?.pageParam || payload)
    }, { suspense })

    const invalidateQuery = useCallback(() => {
        queryClient.invalidateQueries(cacheKey)
    }, [])

    const data = useMemo(() => _.uniqBy(query?.data?.pages?.flat(), "id"), [query?.data?.pages])

    useEffect(() => {
        let elements = query?.data?.pages
        let lastElement = elements?.[elements?.length - 1]

        if (_.isArray(lastElement) && _.isEmpty(lastElement) && stopFetching === false) {
            setStopFetching(true)
        }
        else {
            setStopFetching(false)
        }
    }, [query?.data?.pages])

    return {
        ...query,
        data,
        invalidateQuery,
        stopFetching
    }
}

const cacheKey = "Adverts.Types"