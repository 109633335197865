import { ButtonSubmit, useChangeUserPassword, useEnv, useTranslations } from "@ephatta/components"
import { TClientIdentity } from "@ephatta/services";
import { Grid, TextField, Typography } from "@mui/material"
import CustomPasswordInput from "components/shared/CustomPasswordInput";
import { FormEvent, useEffect } from "react";
import { useChangePasswordFormData } from "../hooks/useChangePasswordFormData"

export const ChangePasswordForm = (props: Props) => {

    const [
        PASSWORD,
        NEWPASSWORD,
        CONFIRM_PASSWORD,
        VALIDATE,
        EDIT_MY_PASSWORD_LABEL
    ] = useTranslations(i18nKeys);
    const env = useEnv<TClientIdentity | null>()
    const mutation = useChangeUserPassword()
    const { formData, getTextFieldProps, isValid } = useChangePasswordFormData()

    useEffect(() => {
        if (mutation.isSuccess) {
            mutation.reset()
            props.onSubmited?.()
        }
    }, [mutation])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isValid()) {
            mutation.mutate({
                applicationId: 0,
                email: env?.email!,
                newPassword: formData.newPassword!,
                password: formData.password!
            })
        }
    }

    
    return (
        <form noValidate onSubmit={handleSubmit}>
            <Typography variant="h2" color={"secondary"} my={2}>{EDIT_MY_PASSWORD_LABEL}</Typography>
            <Grid container gap={3}>
                { env?.oauthType === "CLASSIC" &&

                    <Grid item xs={12}>
                        <CustomPasswordInput
                                placeholder={PASSWORD}
                                fullWidth
                                {...getTextFieldProps('password')}
                            />
                    </Grid>
                }

                <Grid item xs={12}>
                    <CustomPasswordInput
                            placeholder={NEWPASSWORD}
                            fullWidth
                            {...getTextFieldProps('newPassword')}
                        />
                </Grid>

                <Grid item xs={12}>
                    <CustomPasswordInput
                            placeholder={CONFIRM_PASSWORD}
                            fullWidth
                            {...getTextFieldProps('passwordConfirmation')}
                        />
                </Grid>

                <Grid item xs={12} display="flex" justifyContent="center">
                    <ButtonSubmit 
                        type="submit" 
                        variant="contained" 
                        isSubmit={mutation.isLoading} 
                        color="secondary" 
                        fullWidth={true}>
                        {VALIDATE}
                    </ButtonSubmit>
                </Grid>
            </Grid>
        </form>
    )
}

type Props = {
    onSubmited?: () => void
}

const i18nKeys = [
    "Page.MyProfile.ConnexionInformation.ChangePassord.Password.Label",
    "Page.MyProfile.ConnexionInformation.ChangePassord.NewPassword.Label",
    "Page.MyProfile.ConnexionInformation.ChangePassord.ConfirmPassword.Label",
    "Page.MyProfile.ConnexionInformation.ChangePassord.Validate.Label",
    "Page.MyProfile.ConnexionInformation.ChangePassord.EditMyPassword.Label",
]
 