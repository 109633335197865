import BoxBorderedItem from "../components/BoxBorderedItem";
import DropListInput from "../components/DropListInput";
import CustomizedInput from "../components/CustomizedInput";
import CheckBoxWithLabel from "../components/CheckBoxWithLabel";
import { Box, FormHelperText, Grid, MenuItem, Stack, TextField, Theme, Typography } from "@mui/material";
import { ButtonSubmit, MainLoader, useConfirmPhone, useCustomerDetails, useCustomerNationalities, useCustomerUpdate, useDisplayAlert, useEnv, useEnvForceUpdateSelector, useSendConfirmPhone, useTranslations, usePhoneFormat, useRuntimeEnvInvalidate, useRuntimeEnv, GoogleAutoCompleteInput } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";
import { useCustomerPersonalInformationFormData } from "../hooks/useCustomerPersonalInformationFormData";
import { FormEvent, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { DatePicker } from "@mui/x-date-pickers";
import moment from 'moment';
import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-number-input";
import PhoneNumberComponent from "components/shared/PhoneNumberComponent";


const PersonalInformation = () => {

    const classes = useStyles();

    const [
        MY_PERSONAL_INFORMATION_TITLE,
        CIVILITY_LABEL,
        FIRST_NAME_LABEL,
        LAST_NAME_LABEL,
        ADDRESS_LABEL,
        POSTAL_CODE_LABEL,
        CITY_LABEL,
        COUNTRY_LABEL,
        BIRTH_DATE_LABEL,
        NATIONALITY_LABEL,
        PHONE_LABEL,
        SEND_CODE_VALIDATION,
        CODE_VALIDATION_LABEL,
        ACCEPT_TO_RECEIVE_LABEL,
        SAVE_THE_MODIFICATIONS,
        CONFIRM_CODE_EMPTY_ERROR
    ] = useTranslations(i18nKeys);


    const env = useRuntimeEnv<TClientIdentity | null>()
    const reloadEnv = useRuntimeEnvInvalidate()
    const { data, invalidateQuery, isLoading } = useCustomerDetails(env?.userId!, true)
    const { data: nationalities } = useCustomerNationalities()

    const [confirmationCode, setConfirmationCode] = useState('')
    const [showCodeValidation, setShowCodeValidation] = useState<boolean>(true)
    const { formData, getTextFieldProps, setFormData, handleInputChange, isValid, getError } = useCustomerPersonalInformationFormData(_.omit(
        (data?.customer || {}),
        'relationships',
        'billing',
        'adverts',
        'favorites'
    ))
    
    const displayAlert = useDisplayAlert()
    const updateMutation = useCustomerUpdate()
    const sendConfirmationMutation = useSendConfirmPhone()
    const confirmationMutation = useConfirmPhone()
    const formatPhoneNumber = usePhoneFormat()

    useEffect(() => {
        if (updateMutation.isSuccess) {
            updateMutation.reset()
            reloadEnv()
            invalidateQuery()
        }
    }, [updateMutation])

    useEffect(() => {
        if (confirmationMutation.isSuccess) {
            reloadEnv()
            setShowCodeValidation(false)
        }
    }, [confirmationMutation.isSuccess])

    const nationalityOptions = useMemo(() => {
        const values = nationalities?.map((item) => ({
            key: item?.infoNationalities?.find(info => info.culture === process.env.REACT_APP_CULTURE)?.value || '',
            value: item?.id
        })) || []

        return [
            {
                key: NATIONALITY_LABEL, value: null
            },
            ...values
        ]
    }, [nationalities])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isValid()) {
            updateMutation.mutate({
                userId: env?.userId,
                // ..._.omit(formData, "phone"),
                address: formData.address,
                zipCode: formData.zipCode,
                city: formData.city,
                country: formData.country,
                nationalityId: formData.nationalityId,
                civility: formData.civility,
                firstname: formData.firstname,
                lastname: formData.lastname,
                region: formData.region,
                birthday: moment(formData.birthday).format('DD/MM/YYYY'),
                latitude: formData.latitude?.toString().replace('.', ',') || null,
                longitude: formData.longitude?.toString().replace('.', ',') || null,
                optimNewsletter: Boolean(formData.optimNewsletter),
                ...(Boolean(formData?.phone) && /[^\d]/.test(formData?.phone!) ? { phone: formData?.phone } : {})
            })
        }
    }

    const handleValidationClick = () => {
        if (confirmationCode) {
            return confirmationMutation.mutate({
                id: env?.userId!,
                code: confirmationCode
            })
        }
        displayAlert({
            id: 'Confirmation.Error',
            label: CONFIRM_CODE_EMPTY_ERROR,
            severity: 'error'
        })
    }

    const handleSendValidationClick = () => {
        sendConfirmationMutation.mutate({
            userId: env?.userId!,
            phone: formData?.phone!
        })
    }
    const showPhoneConfirmation = useMemo(() => {
        if (sendConfirmationMutation.isSuccess) {
            if (!!formData.phone === false) { return false } //quand le champ sera vide, on n'affiche pas le CTA
            return sendConfirmationMutation.variables?.phone !== formData.phone //quand il y a un changement sur le champ
        }
        if (Boolean(env?.phoneConfirmed) === false && Boolean(formData.phone) === true) { return true } //au premier chargement de la page, quand le tel de l'user n'est pas confirmé et le champ est rempli, on affiche le CTA
        if (!!formData.phone === false) { return false } //quand le champ sera vide, on n'affiche pas le CTA
        return Boolean(formData.phone) && data?.customer.phone !== formData.phone //quand il y a un changement sur le champ
    }, [env?.phoneConfirmed, data, formData.phone, sendConfirmationMutation.isSuccess])

    if (isLoading) return (<MainLoader />)

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Box>
                <BoxBorderedItem title={MY_PERSONAL_INFORMATION_TITLE} bordered={true}>
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={4}
                    >
                        <Grid item lg={4} xs={12}>

                            <TextField
                                variant={'standard'}
                                {...getTextFieldProps('civility')}
                                select
                                onChange={(e) => handleInputChange('civility', e.target.value)}
                                value={formData.civility === null || formData.civility === undefined ? 'null' : formData.civility}
                                fullWidth={true}
                                sx={{
                                    ".MuiInput-root": {
                                        fontSize: { xs: '12px', sm: '15px' }
                                    }
                                }}
                            >
                                <MenuItem value={'null'} disabled={true}>{CIVILITY_LABEL}</MenuItem>
                                <MenuItem value={1}>Monsieur</MenuItem>
                                <MenuItem value={2}>Madame</MenuItem>

                            </TextField>

                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <CustomizedInput
                                placeholder={FIRST_NAME_LABEL}
                                fullWidth={true}
                                {...getTextFieldProps('firstname')}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <CustomizedInput
                                placeholder={LAST_NAME_LABEL}
                                fullWidth={true}
                                {...getTextFieldProps('lastname')}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <GoogleAutoCompleteInput
                                textFieldProps={{
                                    value: formData.address?.trim()
                                }}
                                onResults={(value) => {
                                    setFormData((v) => ({
                                        ...v,
                                        ...(value || {}),
                                        latitude: value.latitude?.toString(),
                                        longitude: value.longitude?.toString()
                                    }))
                                }}
                                renderInput={
                                    <TextField
                                        variant="standard"
                                        placeholder={ADDRESS_LABEL}
                                        fullWidth
                                        onChange={(e) => handleInputChange('address', e.target.value)}
                                        className={`variant-standard ${classes.geocodingSearchInput} ${getError('address') && classes.geocodingSearchError}`}
                                    />
                                }
                            />
                            {
                                getError('address') &&
                                <FormHelperText error>
                                    {getError('address')}
                                </FormHelperText>
                            }
                        </Grid>

                        <Grid item lg={4} xs={12}>
                            <CustomizedInput
                                placeholder={POSTAL_CODE_LABEL}
                                fullWidth={true}
                                {...getTextFieldProps('zipCode')}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <CustomizedInput
                                placeholder={CITY_LABEL}
                                fullWidth={true}
                                {...getTextFieldProps('city')}
                            />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <CustomizedInput
                                placeholder={COUNTRY_LABEL}
                                fullWidth={true}
                                {...getTextFieldProps('country')}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            lg={8} xs={12}
                            justifyContent={{ md: 'space-between', lg: 'flex-start' }}
                            gap="21px"
                        >
                            <Grid item lg={4} xs={4}>
                                <Typography
                                    variant="h4"
                                    className={classes.typography500}
                                    color={'primary'}
                                    paddingTop={1}
                                    style={{ fontSize: '15px' }}
                                >
                                    {BIRTH_DATE_LABEL}
                                </Typography>
                            </Grid>
                            <Grid item lg={6} xs={8}>
                                <DatePicker
                                    showToolbar={false}
                                    disableFuture
                                    maxDate={moment().subtract(18, 'years').toDate()}
                                    inputFormat="DD/MM/YYYY"
                                    views={['year', 'month', 'day']}
                                    value={
                                        formData.birthday && moment(formData.birthday).isValid()
                                            ? moment(formData.birthday).toDate()
                                            : null
                                    }
                                    onChange={(date) => { handleInputChange('birthday', moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : null); }}
                                    renderInput={(params) => <TextField {...params} variant="standard" />}
                                />
                            </Grid>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <DropListInput
                                {...getTextFieldProps('nationalityId')}
                                items={nationalityOptions}
                                value={String(formData.nationalityId || 'null')}
                                onChange={(e) => handleInputChange('nationalityId', e.target.value)}
                                fullWidth={true}
                            />
                        </Grid>

                        <Grid item
                            container
                            lg={12}
                            paddingRight={{ md: 0, lg: 2 }}
                            xs={12}
                            spacing={{ md: 2, lg: 1 }}>
                            <Grid item xs={6} md={"auto"}>
                                <Typography
                                    variant="h4"
                                    className={classes.typography500}
                                    color={'primary'}
                                    paddingTop={1}
                                    style={{ fontSize: '15px' }}
                                >
                                    {PHONE_LABEL}
                                </Typography>
                            </Grid>
                            <Grid item lg={4} xs={6} sx={{ marginBottom: { xs: '15px', sm: 'auto' } }}>
                                <PhoneInput
                                    defaultCountry="FR"
                                    {...getTextFieldProps('phone')}
                                    onChange={(phoneNumber) => handleInputChange('phone', phoneNumber)}
                                    inputComponent={PhoneNumberComponent}
                                    required
                                    placeholder="0 00 00 00 00"
                                />
                            </Grid>
                            {
                                showPhoneConfirmation &&
                                <Grid item md={"auto"} xs={12} display="flex" justifyContent="center" mt={{ sm: 1, lg: 0 }}>
                                    <Box paddingY={{ md: 2, lg: 0 }} width="100%">
                                        <ButtonSubmit
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            className={classes.buttonResp}
                                            isSubmit={sendConfirmationMutation.isLoading}
                                            color="secondary"
                                            onClick={handleSendValidationClick}>
                                            {SEND_CODE_VALIDATION}
                                        </ButtonSubmit>
                                    </Box>
                                </Grid>
                            }
                        </Grid>

                        {
                            sendConfirmationMutation.isSuccess && showCodeValidation &&
                            <Grid item lg={8}>
                                <Grid container
                                    lg={12}
                                    xs={12}
                                    spacing={{ xs: 1, sm: 2 }}
                                    alignItems="center"
                                >
                                    <Grid item xs={5} md={"auto"}>
                                        <Typography
                                            variant="h4"
                                            className={classes.typography500}
                                            color={'primary'}
                                        >
                                            {CODE_VALIDATION_LABEL}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} xs={4}>
                                        <CustomizedInput
                                            placeholder={'xxxxx'}
                                            value={confirmationCode}
                                            onChange={(e) => setConfirmationCode(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item lg={4} xs={3}>
                                        <ButtonSubmit
                                            type="button"
                                            variant="contained"
                                            isSubmit={confirmationMutation.isLoading}
                                            color="secondary"
                                            className={classes.buttonResp}
                                            sx={{
                                                '&.MuiButton-contained': {
                                                    paddingInline: '10px'
                                                }
                                            }}
                                            onClick={handleValidationClick}>
                                            {'OK'}
                                        </ButtonSubmit>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {formData?.optimNewsletter !== undefined &&
                            <Grid item lg={12} xs={12}>
                                <Stack
                                    spacing={{ md: 0, lg: 2 }}
                                >
                                    <CheckBoxWithLabel
                                        id="optim-newsletter"
                                        label={ACCEPT_TO_RECEIVE_LABEL}
                                        checked={formData?.optimNewsletter!}
                                        onChange={(e, checked) => handleInputChange('optimNewsletter', checked)}
                                    />
                                </Stack>
                            </Grid>
                        }
                        <Grid item lg={12} xs={12}>
                            <ButtonSubmit
                                type="submit"
                                variant="contained"
                                className={classes.buttonResp}
                                fullWidth
                                color="secondary"
                                isSubmit={updateMutation.isLoading}
                            >
                                {SAVE_THE_MODIFICATIONS}
                            </ButtonSubmit>
                        </Grid>
                    </Grid>
                </BoxBorderedItem>
            </Box>
        </form>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    typography500: {
        '&.MuiTypography-h4': {
            fontWeight: '500'
        }
    },
    buttonResp: {
        '&.MuiButton-contained': {
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.875rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.68rem',
                padding: '0.5rem',
            }
        }
    },
    geocodingSearchError: {
        "&.variant-standard": {
            borderColor: "#d32f2f"
        }
    },
    geocodingSearchInput: {
        fontSize: '15px',
        "& input::placeholder": {
            fontSize: "15px",
            lineHeight: "1.571rem",
            color: theme.palette.primary.main,
            opacity: 0.45
        }
    }
}));

const i18nKeys = [
    "Page.MyProfile.PersonalInformation.Title",
    "Page.MyProfile.PersonalInformation.Civility.Label",
    "Page.MyProfile.PersonalInformation.FirstName.Label",
    "Page.MyProfile.PersonalInformation.Lastname.Label",
    "Page.MyProfile.PersonalInformation.Address.Label",
    "Page.MyProfile.PersonalInformation.ZipCode.Label",
    "Page.MyProfile.PersonalInformation.City.Label",
    "Page.MyProfile.PersonalInformation.Country.Label",
    "Page.MyProfile.PersonalInformation.BirthDate.Label",
    "Page.MyProfile.PersonalInformation.Nationality.Label",
    "Page.MyProfile.PersonalInformation.Phone.Label",
    "Page.MyProfile.PersonalInformation.SendCodeValidation.Label",
    "Page.MyProfile.PersonalInformation.CodeValidation.Label",
    "Page.MyProfile.PersonalInformation.AcceptToReceive.Label",
    "Page.MyProfile.PersonalInformation.SaveTheModifications.Label",
    "Page.MyProfile.Identification.ConfirmPhone.Error.Label"
];

export default PersonalInformation;