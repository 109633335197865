import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useTabTheme = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent",
        width: '100%',
        "& div[role=tabpanel]": {
            backgroundColor: "white",
            borderRadius: 10
        },
        "& header button:not(.Mui-disabled)": {
            backgroundColor: "white",
            borderRadius: "10px 10px 0 0",
        },
        "& header button:not(.Mui-selected).MuiTab-textColorPrimary": {
            opacity: 0.4
        },
        "& header button": {
            borderBottom: 0,
            "&.MuiTab-root": {
                color: theme.palette.common.black,
                fontSize: 20,
            },
            "&.Mui-selected": {
                color: theme.palette.primary.main,
                boxShadow: '3px 25px 43px -13px rgba(0,0,0,0.40)'
            },
        }
    },
    indicator: {
        backgroundColor: 'white !important',
    },
    appBar: {
        backgroundColor: 'transparent !important'
    }
}));