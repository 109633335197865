import { useEphattaQuery } from "../../utils/useEphattaQuery"
import { useStaticPageServices } from "./useStaticPageServices"

export const useGetStaticPageByUrl = (url: string, suspense?: boolean) => {
    const service = useStaticPageServices()
    return useEphattaQuery(
        ["MiddleOffice.StaticPage.ByUrl", url], 
        () => service.getByURL(url),
        {
            enabled: !!url,
            suspense,
            retry: 1
        }
    )
}