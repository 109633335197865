import { TConfirmEmailPayload } from "@ephatta/services"
import { useEphattaMutation } from "hooks/utils"
import { useTranslation } from "react-i18next"
import { useLoginService } from "./useLoginService"

export const useConfirmEmail = () => {
    const loginService = useLoginService()
    const {t} = useTranslation()

    return useEphattaMutation(
        (payload: TConfirmEmailPayload) => loginService.confirmEmail(payload), 
        {
            successMessage: t("Client.Login.ConfirmYourEmail.Success")
        }
    )
}
