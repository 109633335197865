import { Box, Grid, Theme, useMediaQuery, useTheme } from "@mui/material"
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { MainLoader, useEnv, useAxeptioSetting } from "@ephatta/components";
import FooterSection from "./components/FooterSection";
import { TClientIdentity } from "@ephatta/services";
import SocialMediaHeaderBox from "./components/SocialMediaHeaderBox";
import _ from "lodash";
import { makeStyles } from "@mui/styles";

export const RegistrationLayout = () => {

    const location = useLocation()
    const env = useEnv<TClientIdentity>()
    const classes = useStyle()
    
    useAxeptioSetting()

    return (
        <>
            <Box>

                <SocialMediaHeaderBox
                    isInsideContainer
                    maxWidth='1100px'
                    hideLogo={_.isEmpty(env) && location.pathname === '/'}
                    hideHelpButton={_.isEmpty(env) && location.pathname === '/'}
                    showSocialMedias={location.pathname === '/'}
                />
            </Box>
            <Box className={classes.registrationLayoutContainer}>
                <Box className={classes.registrationCover}>

                </Box>
                <Box id="registration-layout-container" className={classes.registrationOutlet}>
                    <Box className={classes.registrationOutletWrapper}>
                        <Box sx={{ margin: 'auto' }}>
                            <Box sx={{ marginTop: '40px', marginBottom: '40px' }}>
                                <Suspense fallback={<MainLoader />}>
                                    <Outlet />
                                </Suspense>
                            </Box>
                        </Box>

                        <Box mt="60px" className={classes.registrationBg}></Box>
                    </Box>
                </Box>
            </Box>

            <Box sx={{
                position: { xs: 'relative', sm: 'fixed' },
                bottom: 0,
                right: 0,
                left: 0
            }}>
                <FooterSection />
            </Box>
        </>
    );
}

const useStyle = makeStyles((theme: Theme) => ({
    registrationLayoutContainer: {
        position: 'fixed', 
        left: 0, 
        right: 0, 
        top: '64px', 
        bottom: '35px',
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 0,
            bottom: 0
        }
    },
    registrationCover: {
        flex: 1,
        background: 'url("/assets/img/login/background.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    registrationOutlet: {
        flex: 1,
        overflowY: 'auto' ,
        [theme.breakpoints.down('sm')]: {
            overflowY: 'visible'
        }
    },
    registrationOutletWrapper: {
        width: 360,
        margin: 'auto',
        height: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    registrationBg: {
        background: 'url("/assets/img/login/background.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: '100%',
        height: '306px',
        display: 'none',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginBottom: "180px"
        }
    }
}))
