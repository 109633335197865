import { TAdvertInboxDetailsPayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useAdvertInboxDetailsService } from "./useAdvertInboxDetailsService";

export const useAdvertInboxDetailsCreate = () => {
    const service = useAdvertInboxDetailsService()
    return useEphattaMutation(
        (payload: TAdvertInboxDetailsPayload) => service.create(payload)
    )
}
