import { EphattaRouteProps } from "@components/index"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useDocumentMetadata } from "./useDocumentMetadata"
import { useRouterDeclaration } from "./useRouterDeclaration"
import { useRouterDeepNestedTitle } from "./useRouterDeepNestedTitle"
import { useRouterRoutes } from "./useRouterRoutes"

export const useRouterSetMetadata = () => {

    const routes = useRouterRoutes()
    const getRouteDeclaration = useRouterDeclaration()
    const getNestedRouteTitle = useRouterDeepNestedTitle()
    const { setDocumentMetadata } = useDocumentMetadata()
    const { t } = useTranslation()

    return useCallback(({ nested, ...props }: Params) => {
        const route = getRouteDeclaration(routes, location.pathname)
        if (route) {
            const nestedTitle = getNestedRouteTitle(routes, location.pathname)
            setDocumentMetadata({
                ...route,
                ...props,
                title: [
                    props.title,
                    ...(nested !== false ? nestedTitle.split('-') : [])
                ].map((item) => t(item?.trim() || '')).join(' - ')
            })
        }
    }, [])
}

type Params = EphattaRouteProps & {
    nested?: boolean
}
