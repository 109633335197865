import { useEnv } from "@ephatta/components"
import { TClientIdentity } from "@ephatta/services"
import { useTranslation } from "react-i18next"

export const useProfileMenus = (): Array<TProfileMenu> => {
    const { t } = useTranslation()
    const env = useEnv<TClientIdentity>()
    
    return [
        {
            label: t("Navbar.Menu.Profile.MyProfile.Label"),
            url: `/my-profile`
        },
        {
            label: t("Navbar.Menu.Profile.MyNetwork.Label"),
            url: '/my-network'
        },
        {
            label: t("Navbar.Menu.Profile.MyMessages.Label"),
            url: '/my-inbox'
        },
        {
            label: undefined,
            url: '#'
        },
        {
            label: t("Navbar.Menu.Profile.MyFavorites.Label"),
            url: '/my-favorites'
        },
        {
            label: t("Navbar.Menu.Profile.MyBookings.Label"),
            url: '/my-reservations'
        },
        {
            label: t("Navbar.Menu.Profile.MyCasas.Label"),
            url: '/my-announcements'
        },
        {
            label: t("Navbar.Menu.Profile.MyWallet.Label"),
            url: '/my-wallet'
        },
        {
            label: undefined,
            url: '#'
        },
        {
            label: t("Navbar.Menu.Profile.Logout.Label"),
            url: '/logout'
        }
    ]
}

export type TProfileMenu = {
    label?: string
    url?: string
}