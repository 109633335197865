import { TAdvertUpdatePayload } from "@ephatta/services";
import { useEphattaMutation } from "../../..";
import { useAdvertService } from "./useAdvertService";

export const useAdvertUpdate = () => {
    const service = useAdvertService()
    return useEphattaMutation(
        (payload: TAdvertUpdatePayload) => service.update(payload),
        {
            log: (data, variables) => {
                if (variables?.disabled === false) {
                    return `Annonce <a href="/advert/${variables.id}">${variables.title}</a> publié.`
                }

                if (variables?.disabled === true) {
                    return `Annonce <a href="/advert/${variables.id}">${variables.title}</a> dépublié.`
                }

                if(variables?.facebookPublished === true) {
                    return `Annonce <a href="/advert/${variables.id}">${variables.title}</a> publié sur Facebook.`
                }
                if(variables?.facebookPublished === false) {
                    return `Annonce <a href="/advert/${variables.id}">${variables.title}</a> dépublié sur Facebook.`
                }
                
                return `L'Annonce <a href="/advert/${variables?.id}">${variables?.title || ""} ${variables?.id}</a> a été modifié.`
            }
        }
    )
}