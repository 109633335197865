import { Box, Grid, Typography } from "@mui/material";
import BoxBorderedItem from "../components/BoxBorderedItem";
import CustomizedInput from "../components/CustomizedInput";
import CustomButton from "../components/CustomButton";
import {
    GenericDialog,
    useChangeEmail,
    useEnv,
    useEnvForceUpdateSelector,
    useFormData,
    useTranslations,
} from "@ephatta/components";
import { TChangeUserEmailPayload } from "@ephatta/services";
import { useEffect, useState } from "react";
import { ChangePasswordForm } from "../components/ChangePasswordForm";

const ConnexionInformation = () => {
    const [
        MY_PROFILE_CONNEXION_INFORMATION_TITLE,
        PLACEHOLDER_EMAIL,
        MODIFY_EMAIL,
        PLACEHOLDER_PASSWORD,
        MODIFY_PASSWORD,
        CONFIRM_EMAIL_TITLE,
        CONFIRM_MAIL_MESSAGE,
        CONFIRM_BUTTON_LABEL
    ] = useTranslations(i18nKeys);

    const env = useEnv();
    const reloadEnv = useEnvForceUpdateSelector();
    const [showChangePassword, setShowChangePassword] = useState(false);
    const { formData, getTextFieldProps } =
        useFormData<TChangeUserEmailPayload>({
            formData: {
                applicationId: parseInt(process.env.REACT_APP_ID!),
                newEmail: env?.email || "",
            },
        });
    const emailMutation = useChangeEmail();
    const [showConfirmEmail, setShowConfirmEmail] = useState<boolean>(false);

    useEffect(() => {
        if (emailMutation.isSuccess) {
            emailMutation.reset();
            reloadEnv();
            setShowConfirmEmail(false);
        }
    }, [emailMutation]);

    const handleSubmit = () => {
        emailMutation.mutate({ ...formData, email: env?.email });
        setShowConfirmEmail(false);
    };

    return (
        <>
            <BoxBorderedItem
                title={MY_PROFILE_CONNEXION_INFORMATION_TITLE}
                bordered={true}
            >
                <Grid container rowSpacing={2} columnSpacing={4}>
                    <Grid lg={6} xs={12} item>
                        <CustomizedInput
                            placeholder={PLACEHOLDER_EMAIL}
                            fullWidth={true}
                            {...getTextFieldProps("newEmail")}
                        />
                    </Grid>
                    <Grid lg={6} xs={12} item>
                        <CustomButton
                            label={MODIFY_EMAIL}
                            fullWidth={true}
                            disabled={formData.newEmail === env?.email}
                            loading={emailMutation.isLoading}
                            onClick={() => setShowConfirmEmail(true)}
                        />
                    </Grid>
                    <Grid lg={6} xs={12} item>
                        <CustomizedInput
                            placeholder={PLACEHOLDER_PASSWORD}
                            fullWidth={true}
                            type={"password"}
                            disabled   
                        />
                    </Grid>
                    <Grid lg={6} xs={12} item>
                        <CustomButton
                            label={MODIFY_PASSWORD}
                            fullWidth={true}
                            onClick={() => setShowChangePassword(true)}
                            sx={{
                                padding: '9px 25px !important'
                            }}
                        />
                    </Grid>
                </Grid>
            </BoxBorderedItem>

            <GenericDialog
                open={showChangePassword}
                btnCancel={false}
                btnOk={false}
                onClose={() => setShowChangePassword(false)}
            >
                <ChangePasswordForm
                    onSubmited={() => setShowChangePassword(false)}
                />
            </GenericDialog>
            <GenericDialog
                open={showConfirmEmail}
                onClose={() => setShowConfirmEmail(false)}
                onConfirm={handleSubmit}
                btnOk={{
                    label: CONFIRM_BUTTON_LABEL,
                }}
            >
                <Typography variant="h2" color={"secondary"} mb={3}>
                    {CONFIRM_EMAIL_TITLE}
                </Typography>
                <Typography marginY={2} color={"black"}>
                    {CONFIRM_MAIL_MESSAGE}
                </Typography>
            </GenericDialog>
        </>
    );
};

const i18nKeys = [
    "Page.MyProfile.ConnexionInformation.MyConnexionInformation.Label",
    "Page.MyProfile.ConnexionInformation.PlaceHolder.Mail.Label",
    "Page.MyProfile.ConnexionInformation.PlaceHolder.ModifyMail.Button",
    "Page.MyProfile.ConnexionInformation.PlaceHolder.Password.Label",
    "Page.MyProfile.ConnexionInformation.PlaceHolder.ModifyPassword.Button",
    "Page.MyProfile.ConnexionInformation.ChangeEmail.Confirm.Title",
    "Page.MyProfile.ConnexionInformation.ChangeEmail.Confirm.Message",
    "Page.MyProfile.ConnexionInformation.ChangeEmail.Confirm.BtnConfirm",
];

export default ConnexionInformation;
