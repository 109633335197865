import { useCallback } from "react"

export const useGoogleTagsManagerEvent = () => {

    return useCallback((e: Event) => {
        // @ts-ignore
        return window.dataLayer?.push(e)
    }, [])
}

type Event = {
    event: string
}
