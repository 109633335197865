import { TImageEditCoordonates } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useAdvertService } from "./useAdvertService";

export const useTakePictureId = () => {
    const service = useAdvertService()
    return useEphattaMutation(
        ({File , advertId, coordonates} : TPicturePayload) => service.takePictureId(File, advertId, coordonates)
    )
}
export type TPicturePayload ={
    File : File
    advertId : string | null
    coordonates?: TImageEditCoordonates
}