import { TAdvertInboxUpdate } from "@ephatta/services";
import { useEphattaMutation, useAdvertInboxService } from "hooks";

export const useAdvertInboxUpdate = () => {
    const service = useAdvertInboxService()
    return useEphattaMutation(
        ({ inboxId, payload }: Payload) => service.update(inboxId, payload)
    )
}

type Payload = {
    inboxId: number,
    payload: TAdvertInboxUpdate
}
