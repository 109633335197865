import { useTranslation } from 'react-i18next';
import { TAssignmentPayload, useEphattaMutation } from '../../..';
import { useCustomerFavoriteService } from './useCustomerFavoriteService';

export const useCustomerFavoriteAddAssignment = () => {
    const service = useCustomerFavoriteService()
    const {t} = useTranslation()

    return useEphattaMutation(
        ({ id, advertId }: TAssignmentPayload) => service.addAssignment(id, advertId),
        {
            successMessage: t("Pages.Advert.Details.AddToFavorite.Success.Message"),
        }
    )
}
