export const ProfileContactOutlineIcon = (props:TIconsProps) => (
    <svg width={props.width || "36"} height={props.height || "27"} viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33 0H3C1.365 0.06 0.06 1.365 0 3V24C0.06 25.635 1.365 26.94 3 27H33C34.635 26.94 35.94 25.635 36 24V3C35.94 1.365 34.635 0.06 33 0ZM33 24H3V3H33V24ZM21 21V19.125C21 16.635 15.99 15.375 13.5 15.375C11.01 15.375 6 16.635 6 19.125V21H21ZM13.5 6C12.5054 6 11.5516 6.39509 10.8483 7.09835C10.1451 7.80161 9.75 8.75544 9.75 9.75C9.75 10.2425 9.847 10.7301 10.0355 11.1851C10.2239 11.64 10.5001 12.0534 10.8483 12.4017C11.5516 13.1049 12.5054 13.5 13.5 13.5C13.9925 13.5 14.4801 13.403 14.9351 13.2145C15.39 13.0261 15.8034 12.7499 16.1516 12.4017C16.4999 12.0534 16.7761 11.64 16.9645 11.1851C17.153 10.7301 17.25 10.2425 17.25 9.75C17.25 9.25754 17.153 8.76991 16.9645 8.31494C16.7761 7.85997 16.4999 7.44657 16.1516 7.09835C15.8034 6.75013 15.39 6.47391 14.9351 6.28545C14.4801 6.097 13.9925 6 13.5 6ZM21 6V7.5H30V6H21ZM21 9V10.5H30V9H21ZM21 12V13.5H27V12H21Z"
            fill="#7DCCB2"/>
    </svg>
)

export const ProfileAddOutlineIcon = (props:TIconsProps) => (
    <svg width={props.width || "31"} height={props.height || "31"} viewBox="0 0 31 31" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M28.985 13.485H17.515V2.015C17.515 0.898999 16.616 0 15.5 0C14.384 0 13.485 0.898999 13.485 2.015V13.485H2.01499C0.898993 13.485 0 14.384 0 15.5C0 16.616 0.898993 17.515 2.01499 17.515H13.485V28.985C13.485 30.101 14.384 31 15.5 31C16.616 31 17.515 30.101 17.515 28.985V17.515H28.985C30.101 17.515 31 16.616 31 15.5C31 14.384 30.101 13.485 28.985 13.485Z"
            />
    </svg>
)

export const ProfileWebFilledIcon = (props:TIconsProps) => (
    <svg width={props.width || "34"} height={props.height || "44"} viewBox="0 0 34 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.25 0C3.12283 0 2.04183 0.46357 1.2448 1.28873C0.447766 2.11389 0 3.23305 0 4.4V39.6C0 40.767 0.447766 41.8861 1.2448 42.7113C2.04183 43.5364 3.12283 44 4.25 44H29.75C30.8772 44 31.9582 43.5364 32.7552 42.7113C33.5522 41.8861 34 40.767 34 39.6V4.4C34 3.23305 33.5522 2.11389 32.7552 1.28873C31.9582 0.46357 30.8772 0 29.75 0H4.25ZM17 6.6C19.8179 6.6 22.5204 7.75893 24.513 9.82183C26.5056 11.8847 27.625 14.6826 27.625 17.6C27.625 20.5174 26.5056 23.3153 24.513 25.3782C22.5204 27.4411 19.8179 28.6 17 28.6C14.1821 28.6 11.4796 27.4411 9.48699 25.3782C7.49442 23.3153 6.375 20.5174 6.375 17.6C6.375 14.6826 7.49442 11.8847 9.48699 9.82183C11.4796 7.75893 14.1821 6.6 17 6.6ZM17 8.8C16.1287 10.164 15.4062 11.638 14.96 13.2H19.04C18.5938 11.638 17.8925 10.164 17 8.8ZM14.2375 9.284C12.2825 9.966 10.625 11.374 9.6475 13.2H12.75C13.1325 11.836 13.6 10.516 14.2375 9.284ZM19.7412 9.284C20.3787 10.516 20.8675 11.836 21.25 13.2H24.3525C23.375 11.374 21.6962 9.988 19.7412 9.284ZM8.77625 15.4C8.60625 16.104 8.5 16.83 8.5 17.6C8.5 18.37 8.60625 19.096 8.77625 19.8H12.3675C12.2825 19.074 12.2188 18.348 12.2188 17.6C12.2188 16.852 12.2825 16.126 12.3675 15.4H8.77625ZM14.5138 15.4C14.4075 16.104 14.3438 16.852 14.3438 17.6C14.3438 18.348 14.4075 19.074 14.5138 19.8H19.4862C19.5713 19.074 19.6562 18.348 19.6562 17.6C19.6562 16.852 19.5713 16.104 19.4862 15.4H14.5138ZM21.6325 15.4C21.7175 16.126 21.7812 16.852 21.7812 17.6C21.7812 18.348 21.7175 19.074 21.6325 19.8H25.2237C25.3937 19.096 25.5 18.37 25.5 17.6C25.5 16.83 25.3937 16.104 25.2237 15.4H21.6325ZM9.6475 22C10.625 23.826 12.2825 25.212 14.2375 25.916C13.6 24.684 13.1325 23.386 12.75 22H9.6475ZM14.96 22C15.4062 23.584 16.1287 25.036 17 26.4C17.8925 25.036 18.5938 23.584 19.04 22H14.96ZM21.25 22C20.8675 23.386 20.3787 24.684 19.7412 25.916C21.6962 25.212 23.375 23.826 24.3525 22H21.25ZM6.375 33H27.625V37.4H6.375V33Z"
            fill="#7DCCB2"/>
    </svg>
)

export const ProfileContactFilledIcon = (props:TIconsProps) => (
    <svg width={props.height || "36"} height={props.width || "28" }  viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 0.5H33C34.575 0.5 36 1.925 36 3.5V24.5C36 26.075 34.575 27.5 33 27.5H3C1.425 27.5 0 26.075 0 24.5V3.5C0 1.925 1.425 0.5 3 0.5ZM21 5V6.5H33V5H21ZM21 8V9.5H32.25H33V8H21ZM21 11V12.5H31.5V11H21ZM12 16.865C9 16.865 3 18.5 3 21.5V23H21V21.5C21 18.5 15 16.865 12 16.865ZM12 5C10.8065 5 9.66193 5.47411 8.81802 6.31802C7.97411 7.16193 7.5 8.30653 7.5 9.5C7.5 10.6935 7.97411 11.8381 8.81802 12.682C9.66193 13.5259 10.8065 14 12 14C13.1935 14 14.3381 13.5259 15.182 12.682C16.0259 11.8381 16.5 10.6935 16.5 9.5C16.5 8.30653 16.0259 7.16193 15.182 6.31802C14.3381 5.47411 13.1935 5 12 5Z"
            fill="#7DCCB2"/>
    </svg>
)

export const QuotesIcon = () => (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.37087 3.61461C8.20928 3.0822 8.00001 2.55537 7.69007 2.10436C6.29614 0.0695193 3.76207 -0.404348 2.0741 0.31593C-2.65392 2.67579 1.96231 9.82004 5.79018 6.04415C8.27127 5.95551 5.81825 13.1059 0.51275 14.3725C-0.302626 14.7092 0.110625 16.2228 1.15064 15.9714C5.53799 14.1635 10.3995 10.3034 8.37087 3.61461Z"
            fill="#091A6A"/>
        <path
            d="M18.5941 3.61461C18.4325 3.0822 18.2232 2.55537 17.9133 2.10436C16.5194 0.0695193 13.9858 -0.404348 12.2973 0.31593C7.5693 2.67412 12.1855 9.81837 16.0107 6.04248C18.4924 5.95384 16.0388 13.1042 10.7333 14.3708C9.92006 14.7053 10.3333 16.2223 11.3733 15.9697C15.7612 14.1635 20.6211 10.3034 18.5941 3.61461Z"
            fill="#091A6A"/>
    </svg>
)

export const LoadingIcon = (props:TIconsProps) => (
    <svg width={props.height || "12"} height={props.width || "20"} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 20H0V14L4 10L0 6V0H12V6L8 10L12 14V20ZM2 5.5L6 9.5L10 5.5V2H2V5.5ZM6 10.5L2 14.5V18H10V14.5L6 10.5ZM8 16H4V15.2L6 13.2L8 15.2V16Z" fill="#071964"/>
    </svg>
)

export const SuccessIcon = (props:TIconsProps) => (
    <svg width={props.height ||"20"} height={props.width || "16"} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.14645 14.7636C6.34171 14.9588 6.65829 14.9588 6.85355 14.7636L18.8536 2.76355C19.0488 2.56829 19.0488 2.25171 18.8536 2.05645L17.4436 0.646447C17.2484 0.45125 16.9319 0.451175 16.7366 0.64628L6.49973 10.8735L2.76312 7.14601C2.56779 6.95116 2.25154 6.95135 2.05645 7.14645L0.646447 8.55645C0.451184 8.75171 0.451184 9.06829 0.646447 9.26355L6.14645 14.7636Z" fill="#7DCCB2" stroke="#7DCCB2" stroke-linejoin="round"/>
    </svg>

)


type TIconsProps = {
    width?: number,
    height?: number
}