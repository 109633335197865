import { GET_ADVERT_CANCELLATION_POLICY } from "constants/queryKeys"
import { useEphattaQuery } from "hooks"
import { useAdvertService } from "./useAdvertService"

export const useAdvertCancellationPolicy = () => {
    const service = useAdvertService()

    return useEphattaQuery(
        GET_ADVERT_CANCELLATION_POLICY,
        () => service.getCancellationPolicy()
    )
}