import { GET_ADVERT_BY_ID } from "constants/queryKeys"
import { useEphattaQuery } from "../../.."
import { useAdvertService } from "./useAdvertService"

export const useAdvertGetById = (id: string, suspense: boolean = false, refetchOnWindowFocus: boolean = false) => {
    const service = useAdvertService()

    return useEphattaQuery(
        [GET_ADVERT_BY_ID, id],
        () => service.getDetailsById(id),
        { enabled: !!id, suspense, refetchOnWindowFocus}
    )
}