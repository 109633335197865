import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

export const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#061566',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#061566',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#061566',
      },
      '&:hover fieldset': {
        borderColor: '#061566',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#061566',
      },
    },
  },
})(TextField);