
import JumbotronMyProfile from "./widgets/JumbotronMyProfile";
import {Box, Container, Grid, Stack, useMediaQuery} from "@mui/material";
import PersonalInformation from "./widgets/PersonalInformation";
import ConnexionInformation from "./widgets/ConnexionInformation";
import Identification from "./widgets/Identification";
import ConfidenceIndex from "./widgets/ConfidenceIndex";

const Dashboard = () => {
    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    return (
        <>
            <Container maxWidth={'lg'}>
                <JumbotronMyProfile/>
                <Box
                    mt="50px"
                    sx={{ marginTop: { xs: '0', sm: '50px' }, paddingBottom: { xs: '380px', sm: '250px' } }}
                >
                    <Box display="flex" sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: { xs: '30px', sm: "25px" } }}>
                        <Box flex={1} display="flex" flexDirection="column" sx={{ gap: { xs: '30px', sm: "50px" }}}>
                            <PersonalInformation/>
                            <ConnexionInformation/>
                            <Identification/>
                        </Box>
                        <Box sx={{ marginTop: { xs: '30px', sm: '46px' }, width: { xs: "100%", sm: "310px" } }}>
                            <ConfidenceIndex/>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Dashboard;