import { useGoogleTagsManagerEvent, useTranslations } from "@ephatta/components"
import { Box, Button, ClickAwayListener, Collapse, Divider, Grid, ListItemText, Menu, MenuItem, MenuList, Paper, Stack, Theme, Typography, useTheme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useState } from "react"
import { Link } from "react-router-dom"
import { BurgerMenuIcon } from "../../icons/BurgerMenuIcon"
import { CloseIcon } from "../../icons/CloseIcon"
import { FacebookIcon, InfoIcon, InstagramIcon, LinkedInIcon, MagIcon } from "../../icons/mobile"
import { SocialMediaContents } from "./SocialMediaContents"

export const NavBarMenu = () => {


    const [
        FIND_CASA,
        SUGGEST_CASA,
    ] = useTranslations(i18nKeys);

    const theme = useTheme()
    const classes = useStyle()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const pushEvent = useGoogleTagsManagerEvent()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                className={classes.buttonMenu}
                onClick={handleClick}>
                {
                    open &&
                    <img src="/assets/img/icon/close.svg" />
                }
                {
                    !open &&
                    <img src="/assets/img/icon/burger.svg" />
                }
            </Button>
            <Menu
                className={classes.menu}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <MenuItem onClick={handleClose}>
                    <Link to="/find-casa" onClick={() => pushEvent({ event: 'cta-trouverunecasa' })}>
                        <Typography
                            variant="body2"
                            color="primary"
                            fontWeight="bold"
                            textTransform="uppercase"
                        >
                            {FIND_CASA}
                        </Typography>
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link to="/advert/create" onClick={() => pushEvent({ event: 'cta-proposervotrecasa' })}>
                        <Typography
                            variant="body2"
                            color="primary"
                            fontWeight="bold"
                            textTransform="uppercase">
                            {SUGGEST_CASA}
                        </Typography>
                    </Link>
                </MenuItem>
                <MenuItem sx={{ backgroundColor: theme.palette.primary.main }} onClick={handleClose}>
                    <SocialMediaContents />
                </MenuItem>
            </Menu>
        </>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    menu: {
        '& .MuiMenu-paper': {
            width: 240,
            marginLeft: '-16px',
            //left:'0 !important'
        },
        '& .MuiList-root': {
            padding: 0,
            marginRight: '-15px'
        },
        '& .MuiMenuItem-root': {
            padding: '9px 10px',
            minHeight:'40px'
        },
        '& .socialMedia': {
            marginLeft: 'unset'
        }
    },
    buttonMenu: {
        '&.MuiButton-root': {
            padding: '10px 8px',
            borderRadius: 0,
            '& img': {
                height: 18
            },
        }
    },
    socialMedia: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
    }
}))

const i18nKeys = [
    "Home.Page.Home.Header.FindCasa.Label",
    "Home.Page.Home.Header.SuggestYourCasa.Label",
]
