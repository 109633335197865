import { useDetectScreenSize } from "@ephatta/components";
import {Box, Stack, Typography, useTheme} from "@mui/material";

type TSquareLabelProps = {
    boxColor: string;
    children: React.ReactNode;
}
const SquareLabel = (props: TSquareLabelProps) => {
    const theme = useTheme();
    const isSmOrDown = useDetectScreenSize("sm")

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
        >
            <Box
                sx={{
                    backgroundColor: props.boxColor || 'white',
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '5px',
                    width: '80px',
                    height: '30px',
                }}
            >
            </Box>
            <Typography
                fontWeight={500}
                fontSize={isSmOrDown ? "12px" :"18px"}
                lineHeight={isSmOrDown ? "14.63px" :"21px"}
                color={'primary'}
            >
                {props.children}
            </Typography>
        </Stack>
    )
}

export default SquareLabel;