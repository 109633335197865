import { EphattaRouteProps } from "@ephatta/components"
import React from "react"
import Dashboard from "./Dashboard"


const Routes: EphattaRouteProps[] = [
    {
        path: '/my-profile',
        element: <Dashboard />,
    }
]

export default Routes
