import { TAdvertBookingPaymentPayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useAdvertBookingService } from "./useAdvertBookingService";

export const useAdvertBookingPayment = () => {
    const {t} = useTranslation()
    const service = useAdvertBookingService()
    return useEphattaMutation(
        (payload: TAdvertBookingPaymentPayload) => service.payment(payload),
        {
            successMessage: t("Booking.actions.paymentSentMessage")
        }
    )
}