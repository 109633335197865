import { TAdvertBookingAcceptationPayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useAdvertBookingService } from "./useAdvertBookingService";

export const useAdvertBookingOwnerAcceptation = () => {
    const {t} = useTranslation()
    const service = useAdvertBookingService()
    return useEphattaMutation(
        (payload: TAdvertBookingAcceptationPayload) => service.ownerAcceptation(payload),
        {
            successMessage: t("Booking.actions.updated.acceptedMessage")
        }
    )
}