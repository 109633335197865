import { useEphattaQuery } from "../../.."
import { useMangoPayService } from "../useMangoPayService"

export const useMangoPayDetails = (userId?: string, suspense?: boolean) => {
    const service = useMangoPayService()
    
    return useEphattaQuery(
        ['MangoPay.Details', userId], 
        () => service.details(userId!),
        { enabled: Boolean(userId), suspense }
    )
}
