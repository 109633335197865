import React from "react";
import { useEffect, useState } from "react";
import { Button, Box, Tooltip, Typography } from "@mui/material";
import { usePitctureUploaderStyle } from "hooks";
import { Help } from "@mui/icons-material";

const ImageEditor: React.FC<Props> = (props) => {    
    const [newPicture, setNewPicture] = useState<TPicture | undefined>(props?.picture);
    const pictureUploaderClasses = usePitctureUploaderStyle()

    useEffect(() => {
        if(newPicture?.isPushed) {
            props?.onChange?.(newPicture?.src)
        }
    }, [newPicture])    

    useEffect(() => {
        setNewPicture(props.picture)
    }, [props.picture])

    return (
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start" className="content-center">
            <Box display="flex" flexDirection="column" alignItems="center" className="content-center">
                {
                    newPicture?.src && newPicture.src !== '' ? (
                        <div
                            // style={{
                            //     width: props.width || 250,
                            //     height: props.height || 250,
                            //     backgroundImage: `url(${newPicture?.src})`,
                            //     backgroundSize: 'cover',
                            //     backgroundRepeat: 'no-repeat',
                            //     backgroundPosition: 'center'
                            // }}
                        >
                            <img src={newPicture.src} style={{
                                maxWidth: '80%',
                                maxHeight: 300
                            }}/>
                        </div>
                    ) : (
                        !props.hideBlankImg && (
                            <div
                                style={{
                                    width: props.width || 250,
                                    height: props.height || 250,
                                }}
                            >
                            </div>
                        )
                    )
                }                    
                <div>
                    <Button size='small' className={pictureUploaderClasses.inputPicture}>
                        { newPicture?.src && newPicture.src !== '' ? 'Modifier l\'image' : 'Ajouter une image' }
                        
                    </Button>
                </div>
            </Box>
            <Tooltip 
                title={
                    <>
                        <Typography>
                            Dimensions : environ {props.longueur && props.largeur ? `${props.longueur}x${props.largeur}` : '1920 x 706'}
                        </Typography>
                        <Typography>
                            Taille : maximum 500Ko
                        </Typography>
                    </>
                }
                placement="top"
                style={{fontSize: '20px !important'}}
                arrow
            >
                <Help />
            </Tooltip>
        </Box>
    )
}

export default ImageEditor

export type TPicture = {
    src?: string, 
    isPushed?: boolean
}

type Props = {
    picture?: TPicture
    required?: boolean
    width?: number
    longueur?: number
    largeur?: number
    height?: number
    hideBlankImg?: boolean
    onChange?: (src?: string) => void
}
