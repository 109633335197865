import { ButtonSubmit, useTranslations, useConfirmEmailRequestbyApplicationId } from "@ephatta/components"
import { Box, TextField, Typography } from "@mui/material"
import { FormEvent, useEffect } from "react"
import { useEmailResendFormData } from "../hooks/useEmailResendFormData"

export const EmailResend = () => {
   
    const [
        MESSAGE,
        EMAIL,
        SUBMIT,
    ] = useTranslations(i18n)

    const { formData, getTextFieldProps, isValid } = useEmailResendFormData()
    const mutation = useConfirmEmailRequestbyApplicationId()

    useEffect(() => {
        if (mutation.isSuccess) {
            
            mutation.reset()
        }
    }, [mutation])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isValid()) {
            mutation.mutate({
                email:formData.email,
                applicationId: parseInt(process.env.REACT_APP_ID!)
            })
        }
    }

    return (
        
        <form noValidate onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={3}>
                <Typography variant="h3" color="primary" fontWeight="bold">{MESSAGE}</Typography>

                <Box>
                    <TextField 
                        label={EMAIL}
                        fullWidth
                        {...getTextFieldProps('email')}
                        />
                </Box>

                <ButtonSubmit
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="secondary"
                    isSubmit={mutation.isLoading}>
                    {SUBMIT}
                </ButtonSubmit>
            </Box>
        </form>
    )
}

const i18n = [
    'Client.Login.ConfirmYourEmail.Message',
    'Client.Login.ConfirmYourEmail.Form.Email.Label',
    'Client.Login.ConfirmYourEmail.Form.Submit.Label',
]
