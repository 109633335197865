import { PublicClientApplication } from "@azure/msal-browser";
import { useCallback } from "react";
import { useMsalConfig } from "./useMsalConfig";

export const useMsalInstance = () => {
    const msalConfig = useMsalConfig()
    return useCallback(() => {
        return new PublicClientApplication(msalConfig);
    }, [])
}
