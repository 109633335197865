import { Avatar, Box, Button, CircularProgress, IconButton, Stack, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { PictureUploader, useCustomerDetails, useCustomerUpdate, useEnv, useEnvForceUpdateSelector, useTranslations } from "@ephatta/components";
import { TClientIdentity } from "@ephatta/services";
import { useEffect, useState } from "react";
import { EditIcon } from "../../../components/icons/EditIcon";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import AvatarWithTitle from "../../../components/shared/widgets/AvatarWithTitle";
import { ShareAffiliationCodeDialog } from "components/affiliation/ShareAffiliationCodeDialog";

const JumbotronMyProfile = () => {

    const classes = useStyles();
    const reloadEnv = useEnvForceUpdateSelector()

    const theme = useTheme();
    const env = useEnv<TClientIdentity | null>()
    const { data, invalidateQuery } = useCustomerDetails(env?.userId!)
    
    // ======= TRANSLATIONS =========
    const [
        AMICASIEN_LABEL,
        AMICASIENS_LABEL,
        MY_PROFILE_LABEL,
        CASA_LABEL,
        SEE_MY_PUBLIC_PROFILE_LABEL,
        SHARE_AFFILIATION_CODE
    ] = useTranslations(i18nKeys);
    // ======= END TRANSLATIONS =====

    const updateMutation = useCustomerUpdate()

    useEffect(() => {
        if (updateMutation.isSuccess) {
            updateMutation.reset()
            invalidateQuery()
            reloadEnv()
        }
    }, [updateMutation])

    const getFile = async (url: string, fileName: string) => {
        const blob = await fetch(url!)
            .then(res => res.blob())

        return new File([blob], fileName, { type: "image/png" })
    }

    const handleAddPicture = async (value: string, fileName: string) => {
        const file = await getFile(value, fileName)
        updateMutation.mutate({
            userId: env?.userId,
            picture: file
        })
    }

    const [open, setOpen] = useState<boolean>(false)

    return (
        <>
            <ShareAffiliationCodeDialog
                onClose={() => setOpen(false)}
                open={open}
            />
            <Box mt="20px">
                <AvatarWithTitle
                    title={MY_PROFILE_LABEL}
                    />
            </Box>
            <Box
                mt="30px"
            >
                <Stack
                    justifyContent={'space-between'}
                    spacing={{xs: 3 , sm: 0}}
                    sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems:{ xs:'flex-start', sm:'center' },
                    }}
                >
                    <Box display="flex" gap="15px">
                        <Box>
                            <PictureUploader
                                PictureCropperProps={{ cropShape: 'rect' }}
                                onChange={handleAddPicture}>
                                <Box
                                    sx={{
                                        position: 'relative'
                                    }}
                                >
                                    <Avatar
                                        src={data?.customer.pictureUrl || '/assets/img/avatar/avatar-default.png'}
                                        sx={{
                                            width: "100px",
                                            height: "100px",
                                        }}
                                    />

                                    <span
                                        className={'cursor-pointer transition-all zoom-in-on-hover zoom-in-on-press'}
                                        style={{
                                            position: 'absolute',
                                            top: '0px',
                                            right: '2px',
                                        }}
                                    >
                                        {
                                            updateMutation.isLoading &&
                                            <CircularProgress style={{ width: 24, height: 24 }} />
                                        }
                                        {
                                            !updateMutation.isLoading &&
                                                <IconButton
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        '&:hover': {
                                                            backgroundColor: 'white',
                                                        },
                                                        width: '32px',
                                                        height: '32px'
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className={classes.editSvg} viewBox="0 0 21 21" fill="none">
                                                        <path d="M20.4539 4.86447C20.903 4.41538 20.903 3.6669 20.4539 3.24084L17.7594 0.546291C17.3333 0.0971999 16.5848 0.0971999 16.1358 0.546291L14.017 2.65356L18.3352 6.97175L20.4539 4.86447ZM0.0606079 16.6214V20.9396H4.37879L17.1145 8.19235L12.7964 3.87417L0.0606079 16.6214Z" fill="#7DCCB2" />
                                                    </svg>
                                                </IconButton>
                                        }
                                    </span>
                                </Box>
                            </PictureUploader>
                        </Box>
                        <Box>
                            <Stack spacing={{xs:0 , sm: 0.5}}>
                                <Typography
                                    variant="h3"
                                    color={'primary'}
                                    fontWeight={'bold'}
                                >
                                    {data?.customer.firstname} {data?.customer.lastname}
                                </Typography>
                                <Link to="/my-network">
                                    <Typography
                                        variant="body1"
                                        color={'secondary'}
                                        fontWeight={'500'}
                                        lineHeight={'21px'}
                                        >
                                        {data?.relationship.totalCount} {data && data.relationship.totalCount > 1 ? AMICASIENS_LABEL : AMICASIEN_LABEL}
                                    </Typography>
                                </Link>
                                <Link to="/my-announcements">
                                    <Typography
                                        variant="body1"
                                        color={'secondary'}
                                        fontWeight={'500'}
                                        lineHeight={'21px'}
                                        >
                                        {data?.customer.adverts?.length || 0} {`“${CASA_LABEL}”`}
                                    </Typography>
                                </Link>
                            </Stack>
                        </Box>
                    </Box>
                    <Box
                        display={"grid"}
                        gap={2}
                        sx={{
                            width:{xs: '100%' , sm : 'auto'},
                            fontSize: "14px"
                        }}
                    >
                        <Link to={`/public-profile/${data?.customer.userId}`} >
                            <Button
                                variant="outlined" 
                                color="secondary" 
                                fullWidth
                                className="text-transform-none cta-outlined-large"
                            >
                                {SEE_MY_PUBLIC_PROFILE_LABEL}
                            </Button>
                        </Link>
                        <Button
                            // variant="contained" 
                            // color="primary" 
                            // fullWidth
                            // className={`cta-outlined-large ${classes.shareAffiliationButton}`}
                            onClick={() => setOpen(true)}
                            variant="contained" 
                            color="primary" 
                            fullWidth
                            className="text-transform-none cta-outlined-large"
                        >
                            {SHARE_AFFILIATION_CODE}
                        </Button> 
                    </Box>
                </Stack>
            </Box>
        </>
    )
}
const useStyles = makeStyles((theme: Theme) => ({
    buttonOutlined: {
        '&.MuiButton-outlined': {
            textTransform: 'none',
            borderRadius: '5px',
            paddingX: 4,
            paddingY: 1,
            fontWeight: 500,
            border: '1px solid #7DCCB2',
            color: theme.palette.secondary.main,
            '&:hover': {
                borderColor: theme.palette.secondary.main,
                backgroundColor:theme.palette.secondary.main,
                color:'white'
            },
            [theme.breakpoints.down('sm')]: {
                width: "100%"
            },
        }
    },
    profileAvatar: {
        width: "39px",
        height: '39px',
        [theme.breakpoints.down('sm')]: {
            width: "30px",
            height: '30px'
        }
    },
    editSvg: {
        width: "21px",
        height: '21px',
        [theme.breakpoints.down('sm')]: {
            width: "15",
            height: '15'
        }
    },
    shareAffiliationButton: {
        '&.MuiButton-root': {
            [theme.breakpoints.up('xs')]: {
                padding: "9px 10px"
            }
        }
    }
}))


const i18nKeys = [
    "Pages.MyNetWork.SuggestedAmicasien.AmiCasien.Label",
    "Pages.MyNetWork.SuggestedAmicasien.AmiCasiens.Label",
    "Page.MyProfile.Jumbotron.Title",
    "Page.MyProfile.Jumbotron.Casa.Label",
    "Page.MyProfile.Jumbotron.SeeMyPublicProfile.Label",
    "Page.MyProfile.ShareAffiliationCode.Label"
]

export default JumbotronMyProfile;