import { FormHelperText } from "@mui/material"
import _ from "lodash"
import React, { useCallback } from "react"
import { useGlobalFormDataError } from "./useGlobalFormDataError"

export const useFormDataErrorHelper = (id: string) => {
    const [errors] = useGlobalFormDataError(id)

    const getErrorHelper = useCallback((name: string) => {
        const error = errors?.[name]
        if (!error) {
            return;
        }

        return (
            <FormHelperText error>
                {error}
            </FormHelperText>
        )
    }, [errors])

    return {
        getErrorHelper,
    }
}
