import { TAdvert } from "@ephatta/services"
import { useEphattaMutation } from "hooks/utils"
import { useAdvertService } from "./useAdvertService"

export const useAdvertDelete = () => {
    const service = useAdvertService()
    return useEphattaMutation(
        (payload: TAdvert) => service.delete(payload.id!),
        {
            log: (data, variables) => `Annonce <a href="/advert/${variables?.id}">${variables?.title}</a> supprimé.`
        }
    )
}
