import React from "react";
import { useTheme, FormHelperText, TextFieldProps, useMediaQuery } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useMemo } from "react";
import { InputLabel } from "./InputLabel";
import './style/text-field.css'

export const InputWrapper = (props: InputWrapperProps) => {

    const { label, labelMerged, labelVerticalMerged, children } = props
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));

    const labelStyle = useMemo(() => {
        if ((labelMerged || labelVerticalMerged) && !isSmScreen) {
            return {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.light,
                borderColor: theme.palette.primary.main,
                padding: '0 20px'
            }
        }
        return undefined
    }, [labelMerged, labelVerticalMerged, isSmScreen])

    return (
        <div className="control-wrapper">
            <div className={
                    `${ labelVerticalMerged ? 'text-field-wrapper-vertical' : 'text-field-wrapper'} 
                    ${props.className} 
                    ${labelMerged && !isSmScreen && 'text-field-label-merged'}`}>
                {
                    label && 
                    <InputLabel 
                        required={props.required} 
                        style={labelStyle}
                        label={label} />
                }
                { children }
            </div>
            {
                props.helperText && props.error && <FormHelperText error>{props.helperText}</FormHelperText>
            }
        </div>
    )
}

export const useInputDefaultStyle = makeStyles((theme) => {
    return {
        border: {
            '&.ephatta-seed-MuiTextField-root, & .ephatta-seed-MuiSelect-root': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
                borderStyle: 'solid'
            },
            '& .ephatta-seed-MuiInputBase-input': {
                '& MuiSelect-selectMenu': {
                    borderColor: theme.palette.primary.main,
                },
            },
            "&:before": {
                borderColor: "red"
              }
        }
    }
})

export type InputWrapperProps = {
    className?: string
    label?: string
    required?: boolean
    labelMerged?: boolean
    labelVerticalMerged?: boolean
    error?: TextFieldProps['error']
    helperText?: TextFieldProps['helperText']
    children?: React.ReactNode
}
