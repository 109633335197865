import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "react-query";
import { useEphattaMutationLogger } from "./useEphattaMutationLogger";
import {
  QueryErrorType,
  QueryLogType,
  QuerySuccessType,
  useMonitorQuery,
} from "./useMonitorQuery";

/**
 * Ephatta implemenation of useMutation of react-query
 */
export const useEphattaMutation = <
  TData = any,
  TVariables = Record<string, any>,
  TError = unknown
>(
  fn: MutationFunction<TData, TVariables>,
  config?: useEphattaMutationOptions<TData, TError, TVariables>
) => {
  const mutationInstance = useMutation(fn, config);
  useMonitorQuery<TData, TError>(
    mutationInstance as UseMutationResult<TData, TError>,
    config
  );
  const mutationInstanceLogger = useEphattaMutationLogger<TData, TError>(
    mutationInstance,
    config
  );
  return { ...mutationInstance, mutationInstanceLogger };
};

export type useEphattaMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
> = UseMutationOptions<TData, TError, TVariables, TContext> &
  QueryErrorType<TError> &
  QuerySuccessType<TData> &
  QueryLogType<TData, TVariables>;