import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const MyNetwork = React.lazy(() => import('./MyNetWork'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/my-empty-network',
        element: <MyNetwork />,
    },
    {
        path: '/my-network',
        element: <MyNetwork />,
    }
]

export default Routes
