import { TCustomerContactGoogleImport } from '@ephatta/services';
import { useEphattaMutation } from '../../..';
import { useCustomerContactImportService } from './useCustomerContactImportService';

export const useCustomerContactImportGoogle = () => {
    const service = useCustomerContactImportService()
    return useEphattaMutation(
        (payload: TCustomerContactGoogleImport) => service.importGoogle(payload)
    , {
        successMessage: 'Import réalisé avec succès'
    })
} 