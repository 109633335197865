import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Dashboard = React.lazy(() => import('./Dashboard'))
const MyChats = React.lazy(() => import('./MyChats'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/my-inbox',
        element: <Dashboard />,
    },
    {
        path: '/my-inbox/:id',
        element: <MyChats />,
    }
]

export default Routes
