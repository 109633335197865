import { TConfirmPhonePayload } from "@ephatta/services"
import { useEphattaMutation } from "hooks/utils/useEphattaMutation"
import { useTranslation } from "react-i18next"
import { useLoginService } from "./useLoginService"

export const useConfirmPhone = () => {
    const loginService = useLoginService()
    const {t} = useTranslation()

    return useEphattaMutation(
        (payload : TConfirmPhonePayload) => loginService.confirmPhone(payload),
        {
            successMessage: t("Page.MyProfile.Identification.ConfirmPhone.Success.Label")
        }
    )
}

