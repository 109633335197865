import { TAdvertCreateAvailability } from "@ephatta/services"
import { useEphattaMutation } from "hooks"
import { useTranslations } from "hooks/translation"
import { useAdvertAvailabilityService } from "./useAdvertAvailabilityService"

export const useAdvertAvailabilityCreate = () => {
    const service = useAdvertAvailabilityService()
    const [
        SUCCESS_MESSAGE,
        ERROR_MESSAGE
    ] = useTranslations(i18n)
    
    return useEphattaMutation(
        (payload: TAdvertCreateAvailability) => service.create(payload),
        {
            successMessage: SUCCESS_MESSAGE,
            errorMessage: ERROR_MESSAGE
        }
    )
}

const i18n = [
    "Advert.Availability.Success.Message",
    "Submit.Error.Message"
]