import { TClientIdentity } from "@ephatta/services"
import { useEnv } from "hooks/env/useEnv"
import { useEphattaQuery } from "hooks/utils"
import { useLoginService } from "./useLoginService"


export const useNotificationCount = () =>{
    const loginServices = useLoginService()
    const env = useEnv<TClientIdentity>()
    const { data, invalidateQuery } = useEphattaQuery(
        ["Identity.Get.Notification.Count"],
        () => loginServices.identity(),
        {
            refetchInterval: Number(process.env.REACT_APP_NOTIFICATION_INTERVAL),
            hideErrorMessage: true,
            enabled: env !== null
        }
    )
    return {
        notifications: (data as any)?.notifications as number,
        pendingInvitations: (data as any)?.pendingInvitations as number,
        invalidateQuery
    }
}