import {Box, Container, Theme} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMemo } from "react";
import { Link, matchPath } from "react-router-dom";
import { SocialMediaContents } from "./SocialMediaContents";



const SocialMediaHeaderBox = (props: TSocialMediaHeaderBox) => {

    const classes = useStyles()

    const path = useMemo(() => window.location.pathname, [window.location.pathname])

    const disableLinks = useMemo(()=> NoLinkRoutes?.some((item: string)=>matchPath?.(item, path)),[NoLinkRoutes, path])

    const LinkState = useMemo(()=>disableLinks ? 'div' : Link, [disableLinks])

    const insideContainer = useMemo(()=>(
        <Box
            className={classes.mainNavContainer}>

            {
                props.hideLogo !== true &&
                <LinkState to="/" >
                    <img 
                        className={classes.mainLogo}
                        src={'/assets/img/logo/mi-casa-logo2.png'} 
                        alt={process.env.REACT_APP_NAME}
                        />
                </LinkState>
            }

            <Box sx={{ display: { xs: props.showSocialMedias ? 'block' : 'none', sm: 'block' }, marginLeft: 'auto', }}>
                <SocialMediaContents noLink={disableLinks}/>
            </Box>
        </Box>
        
    ),[props?.hideLogo, props?.showSocialMedias, LinkState])

    return (
        <>
        <Box
            className={classes.mainNav}
            sx={{ padding: props.isInsideContainer ? '0' : '0 20px' }}>

            <Box sx={{ maxWidth: props.maxWidth, margin: 'auto' }}>
                {
                    !props.isInsideContainer &&
                    <>
                        {insideContainer}
                    </>
                }
                {
                    props.isInsideContainer &&
                    <Container maxWidth={'lg'}>
                        {insideContainer}
                    </Container>
                }
            </Box>
        </Box>
        </>
    )
}


const NoLinkRoutes = [
    '/maintenance'
]


type TSocialMediaHeaderBox = {
    isInsideContainer?: boolean
    hideLogo?: boolean
    hideHelpButton?: boolean
    showSocialMedias?: boolean
    maxWidth?: string
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        mainNav: {
            background: theme.palette.primary.main,
        },
        mainNavContainer: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 64,
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center'
            }
        },
        mainLogo: {
            height: '40px'
        },
    }
})

export default SocialMediaHeaderBox;