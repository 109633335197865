
import { useEphattaMutation } from "@hooks/utils"
import { useAdvertInboxService } from ".."


export const useGetAdvertInboxId = () => { 
    const services = useAdvertInboxService()
    return useEphattaMutation(
        ({advertId, guestId}:Payload) => services.getAdvertInboxId(advertId, guestId)
    )
}


type Payload = {
    advertId: string,
    guestId: string
}