import { PopupRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useCallback } from "react"

export const useAzureOAuth = () => {

    const { instance } = useMsal();

    return useCallback((loginRequest: PopupRequest) => {
        return instance.loginPopup(loginRequest)
    }, [])
}
