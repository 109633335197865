
export const usePasswordStandardValidation = () => {

    return ({password, requiredLength = 8 }: Params) => {
        const isLengthValid = Boolean(requiredLength && password.length >= requiredLength)
        const hasLowerCase = /[a-z]/.test(password)
        const hasUpperCase = /[A-Z]/.test(password)
        const hasNumber = /\d/.test(password)
        // const hasSpace = /\s/.test(password)
        const hasSpecialChar = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password)
        const hasError = !isLengthValid || !hasLowerCase || !hasUpperCase || !hasNumber || !hasSpecialChar //|| hasSpace
        
        return {
            hasError,
            message: `
                ${ hasError ? 'Le format de mot de passe est incorrect' : '' }
                ${ hasError ? '<br/>Votre mot de passe : ' : '' }
                ${ !isLengthValid ? `<br/>- Ne contient pas au minimum ${requiredLength} caractères` : '' }
                ${ !hasLowerCase ? '<br/>- Ne contient pas de minuscule' : '' }
                ${ !hasUpperCase ? '<br/>- Ne contient pas de majuscule' : '' }
                ${ !hasNumber ? '<br/>- Ne contient pas de chiffre' : '' }
                ${ !hasSpecialChar ? '<br/>- Ne contient pas de caractère spécial' : '' }
                
                `
        }
    };
}
    
    // ${ !hasSpecialChar ? '<br/>- Ne contient pas de caractère spéciale' : '' }
type Params = {
    password: string
    requiredLength?: number
}
