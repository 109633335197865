import { TAdvertShared } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useAdvertSharedService } from "./useAdvertSharedService";

export const useAdvertSharedCreate = () => {
    const service = useAdvertSharedService()
    const {t} = useTranslation()
    
    return useEphattaMutation(
        (payload: TAdvertShared) => service.create(payload),
        {
            successMessage: t("Pages.Advert.Details.ShareDialog.Success.Message")
        }
    )
}