import { ForceReloadEnv } from '../../state/env/ForceReloadEnvAtom';
import { useSetRecoilState } from 'recoil';

/**
 * Hooks to force a selector to update.
 */
export const useEnvForceUpdateSelector = () => {
    const setRecoilUpdate = useSetRecoilState(ForceReloadEnv)
    const forceUpdate = () => setRecoilUpdate((n) => n + 1)

    return forceUpdate
}