import { useCallback } from "react"
import { useMangoPayUrl } from "./useMangoPayUrl"
import { getAccessToken } from "@ephatta/services"

export const useMangoPayRequest = () => {

    const apiUrl = useMangoPayUrl()
    const accessToken = getAccessToken();
    
    return useCallback(async <TData = Record<string, any>, TResponse = unknown>(payload: Request<TData>): Promise<TResponse> => {

        return fetch(`${apiUrl}/${payload.apiUrl}`, {
            method: payload.method || 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': accessToken ? `Bearer ${accessToken}` : ''
            },
            body: JSON.stringify(payload.payload)
        }).then((response) => 
            {
                if (response.status !== 204 ) {
                    return response.json()
                }

                return
            }
        )
    }, [])
}

export type Request<TData = Record<string, any>> = {
    method?: string
    apiUrl: string
    payload?: TData
}
