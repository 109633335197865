export var convertJSONToFormData = function (data) {
    var formData = new FormData();
    Object.keys(data).forEach(function (key) {
        if (Array.isArray(data[key])) {
            data === null || data === void 0 ? void 0 : data[key].forEach(function (value) {
                formData.append("".concat(key), value);
            });
            return;
        }
        formData.append(key, data[key]);
    });
    return formData;
};
