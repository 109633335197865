import { GenericDialog, GenericDialogType, useDisplayAlert, useRuntimeEnv, useTranslations, useUrlParamsEncode } from "@ephatta/components"
import { Box, Link, OutlinedInput, Theme, Typography } from "@mui/material"
import FileCopyIcon from '@mui/icons-material/FileCopy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CopyToClipboard from "react-copy-to-clipboard";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TClientIdentity } from "@ephatta/services";
import { InviteFriendByEmailDialog } from "pages/registration/components/InviteFriendByEmailDialog";

export const ShareAffiliationCodeDialog = (props: Props) => {
    const [
        TITLE,
        DESCRIPTION,
        ON_COPY_LABEL,
        EMAIL_SUBJECT
    ] = useTranslations(i18n)

    const {t} = useTranslation()

    const encodeURI = useUrlParamsEncode()

    const env = useRuntimeEnv<TClientIdentity | null>()

    const whatsAppDeepLink = useMemo(() => {
        const link = `https://wa.me/`
        const params = {
            text: t("Generic.Components.Affiliation.ShareCode.ByWhatsapp.Content.Text", {
                code: env?.affiliationCode,
                registrationLink: `${process.env.REACT_APP_URL}/registration?affiliationCode=${env?.affiliationCode}`,
                interpolation: { escapeValue: false }
            } )
        }

        return `${link}?${encodeURI(params)}`
    }, [env])

    const displayAlert = useDisplayAlert()

    const handleOnCopyToClipboard = useCallback(() => {
        displayAlert({
            label: ON_COPY_LABEL,
            AlertProps: {
                severity: "success"  
            }
        })
    }, [])

    const [openDialogInviteYourFriendByMail, setOpenDialogInviteYourFriendByMail] = useState<boolean>(false)

    return (
        <GenericDialog {...props} btnCancel={false} btnOk={false}>
            <Box display={"grid"} gap={0.5}>
                <Typography variant="h3">
                    {TITLE}
                </Typography>
                <Typography>
                    {DESCRIPTION}
                </Typography>
                <Box display={"flex"} mt={1.5} alignItems={"center"} gap={1}>
                    <OutlinedInput
                        readOnly
                        sx={{
                            "&.MuiOutlinedInput-input": {
                                borderRadius: "100px"
                            }
                        }}
                        onFocus={(event) => {
                            event.target.select()
                        }}
                        fullWidth
                        inputProps={{
                            style: {
                                textAlign: "center",
                                // maxWidth: "500px",
                            }
                        }}
                        value={env?.affiliationCode}
                    />
                    <CopyToClipboard text={env?.affiliationCode || ""} onCopy={handleOnCopyToClipboard}>
                        <FileCopyIcon color="secondary"/>
                    </CopyToClipboard>
                    <Link underline="none" href={whatsAppDeepLink} target="_blank">
                        <WhatsAppIcon color="secondary"/>
                    </Link>
                    <AttachEmailIcon color="secondary" onClick={() => setOpenDialogInviteYourFriendByMail(true)} />
                </Box>
            </Box>
            <InviteFriendByEmailDialog
                open={openDialogInviteYourFriendByMail}
                onSubmited={() => setOpenDialogInviteYourFriendByMail(false)}
                onClose={() => setOpenDialogInviteYourFriendByMail(false)}
            />
        </GenericDialog>
    )
}

ShareAffiliationCodeDialog.defaultProps = {
    whatsappNumber: ""
}

const i18n = [
    "Generic.Components.Affiliation.ShareCode.Title",
    "Generic.Components.Affiliation.ShareCode.Description",
    "Generic.Components.Affiliation.ShareCode.OnCopy.Label",
    "Generic.Components.Affiliation.ShareCode.ByEmail.Subject.Label"
]

type Props = Omit<GenericDialogType, "btnCancel" | "btnOk">