import {MenuItem, TextField, TextFieldProps, useMediaQuery} from "@mui/material";

type TProps = {
    items: {key: string, value: number | string | null}[]
} & Partial<TextFieldProps>

const DropListInput = (props: TProps) => {

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    const { items, ...other} = props;

    return (
        <>
            <TextField
                variant={'standard'}
                select
                value={0}
                sx={{
                    ".MuiInput-root": {
                        fontSize: isSmall ? '12px' : '15px'
                    }
                }}
                {...other}
            >
                {
                    items.map((item, i) => (
                        <MenuItem key={i} disabled={item.value === null} value={String(item.value)}>
                            {item.key}
                        </MenuItem>
                    ))
                }
            </TextField>
        </>
    )
}

export default DropListInput;