import { ButtonSubmit, GenericDialog, GenericDialogType, useDisplayAlert, useFileBase64Converter, useMangopayCreateKycDocument, useMangopayCreateKycPage, useMangopayCreateKycPages, useMangopaySubmitKycDocument, useTranslations } from "@ephatta/components"
import { Check } from "@mui/icons-material"
import { Box, CircularProgress, Divider, FormHelperText, Grid, IconButton, Theme, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { TypePiece } from "../widgets/Identification"

export const PopInDowloadIdentity = ({ userId, ...props }: Props) => {
    const classes = useStyles();
    const { getBase64 } = useFileBase64Converter()

    const [openInfo, setOpenInfo] = useState(false)
    const [documentId, setDocumentId] = useState<string | undefined>(undefined)

    const [
        DOWNLOAD_IDENTITY,
        DOWNLOAD_IDENTITY_MESSAGE_1,
        DOWNLOAD_IDENTITY_MESSAGE_2,
        ONE_PIECE,
        MANY_PIECE,
        PAGE_ONE,
        PAGE_TWO,
        DOWNLOAD,
        VALIDATION_IN_PROGRESS,
        SMALL_FILE_SIZE_ERROR,
        FILE_SIZE_MIN,
        FILE_SIZE_MAX,
        FILE_EMPTY,
    ] = useTranslations(i18nKeys)

    const displayAlert = useDisplayAlert()
    const createMangopayKycMutation = useMangopayCreateKycDocument()
    const uploadMangopayKycMutation = useMangopayCreateKycPage()
    const uploadMangopayKycsMutation = useMangopayCreateKycPages()
    const submitMangopayKycMutation = useMangopaySubmitKycDocument()

    useEffect(() => {
        if (!props.documentId) {
            createMangopayKycMutation.mutate({ userId, Type: "IDENTITY_PROOF", Tag: props.type })
            return
        }
        setDocumentId(props.documentId)

    }, [])

    useEffect(() => {
        if (createMangopayKycMutation.isSuccess) {
            setDocumentId(createMangopayKycMutation.data?.id)
            createMangopayKycMutation.reset()
        }
    }, [createMangopayKycMutation.isSuccess])

    const [file1, setFile1] = useState<File>()
    const [file2, setFile2] = useState<File>()
    const [files, setFiles] = useState<string[]>([])
    const [validationError, setValidationError] = useState('')


    const setFile = async (file: File | undefined, index: number) => {

        if (validateFile(file!) !== '') {
            return;
        }

        if (file) {
            index === 1 ? setFile1(file) : setFile2(file)
            const base64 = await getBase64(file)
            setFiles([...files, base64.split(',')[1]])
        }
    }

    const validateFile = useCallback((file?: File) => {
        let validation = ''

        if (file?.size! < 32_000) {
            validation = FILE_SIZE_MIN
        }

        if (file?.size! > 10_000_000) {
            validation = FILE_SIZE_MAX
        }

        if (!file) {
            validation = FILE_EMPTY
        }

        setValidationError(validation)
        return validation
    }, [FILE_SIZE_MIN])

    const displayMessage = (type: "ERROR" | "SUCCESS") => {
        displayAlert({
            label: type === "SUCCESS" ? VALIDATION_IN_PROGRESS : SMALL_FILE_SIZE_ERROR,
            AlertProps: {
                severity: type === "SUCCESS" ? 'success' : 'error'
            },
            autoHideDuration: 5000
        })
    }

    useEffect(() => {
        if (files.length === 2) {
            uploadMangopayKycsMutation.mutate({
                userId,
                Files: files,
                KYCDocumentId: documentId!
            })
        }
    }, [files])

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {

        if (validateFile(e.target.files?.[0]) !== '') {
            return;
        }

        if (e.target.files && e.target.files.length > 0 && documentId) {
            const file = await getBase64(e.target.files[0])
            uploadMangopayKycMutation.mutate({
                userId,
                File: file.split(',')[1],
                KYCDocumentId: documentId
            })
        }
    }

    useEffect(() => {
        if (uploadMangopayKycMutation.isSuccess) {
            submitMangopayKycMutation.mutate({
                Status: "VALIDATION_ASKED",
                userId,
                KYCDocumentId: documentId
            })
        }

        if (uploadMangopayKycsMutation.isSuccess) {
            setFiles([])
            submitMangopayKycMutation.mutate({
                Status: "VALIDATION_ASKED",
                userId,
                KYCDocumentId: documentId
            })
        }

    }, [uploadMangopayKycMutation.isSuccess, uploadMangopayKycsMutation.isSuccess])

    useEffect(() => {
        if (submitMangopayKycMutation.isSuccess) {
            const isUploaded = Boolean((submitMangopayKycMutation?.data as any)?.UserId)
            displayMessage(isUploaded ? "SUCCESS" : "ERROR")
            submitMangopayKycMutation.reset()
            props.onKycSubmitted?.()
        }
    }, [submitMangopayKycMutation.isSuccess])

    const isLoading = submitMangopayKycMutation.isLoading ||
        createMangopayKycMutation.isLoading ||
        uploadMangopayKycMutation.isLoading ||
        uploadMangopayKycsMutation.isLoading

    if (createMangopayKycMutation.isLoading) {
        return (
            <GenericDialog
                title={DOWNLOAD_IDENTITY}
                content={
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <CircularProgress />
                    </Box>
                }
                {...props}
            />
        )
    }

    return (
        <>
            <GenericDialog
                {...props}
                btnCancel={false}
                btnOk={false}
                PaperProps={{
                    sx: {
                        maxWidth: '1108px'
                    }
                }}
            >
                <Grid container mb={5} spacing={3}>
                    <Grid item xs={12} sm={12} m="auto">
                        <Typography variant="body1" fontWeight="bold" color="primary" marginBottom={1}>
                            {DOWNLOAD_IDENTITY}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} m="auto" >
                        <Typography>
                            {DOWNLOAD_IDENTITY_MESSAGE_1}
                            <br />
                            {DOWNLOAD_IDENTITY_MESSAGE_2}
                            <IconButton onClick={() => setOpenInfo(true)}>
                                <InfoIcon color="primary" />
                            </IconButton>
                        </Typography>
                    </Grid>
                </Grid>

                {
                    props.type === "PASSPORT" &&
                    <Grid container mb={3} spacing={1} justifyContent={"center"} >
                        <Grid item xs={12} sm={6} >
                            <Typography variant="body1" fontWeight="bold" color="primary" textAlign='left' paddingTop={1}>
                                {ONE_PIECE}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} textAlign="center">
                            <label htmlFor="FileUpload">
                                <ButtonSubmit
                                    component="span"
                                    variant="contained"
                                    className={classes.buttonResp}
                                    color="secondary"
                                    fullWidth
                                    disabled={isLoading}
                                    isSubmit={isLoading}
                                >
                                    {DOWNLOAD}
                                </ButtonSubmit>
                            </label>

                        </Grid>
                    </Grid>
                }

                {
                    ["CIN", "DRIVER_LICENSE"].includes(props?.type || "") &&
                    <Grid container mb={5} spacing={1} justifyContent={"center"} >
                        <Grid item xs={12} sm={6}>
                            <Typography variant="body1" fontWeight="bold" color="primary" textAlign='left' paddingTop={1}>
                                {MANY_PIECE}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3} textAlign="center">
                            <Grid container spacing={4} justifyContent='space-around'>
                                <Grid item xs={6} sm={6}>
                                    <label htmlFor="FileUpload1">
                                        <ButtonSubmit
                                            component="span"
                                            variant="contained"
                                            className={classes.buttonResp}
                                            color="secondary"
                                            fullWidth
                                            disabled={isLoading}
                                            isSubmit={isLoading}
                                            endIcon={file1 && <Check />}>
                                            {PAGE_ONE}
                                        </ButtonSubmit>
                                    </label>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <label htmlFor="FileUpload2">
                                        <ButtonSubmit
                                            component="span"
                                            variant="contained"
                                            className={classes.buttonResp}
                                            color="secondary"
                                            fullWidth
                                            disabled={isLoading}
                                            isSubmit={isLoading}
                                            endIcon={file2 && <Check />}>
                                            {PAGE_TWO}
                                        </ButtonSubmit>
                                    </label>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {
                    validationError &&
                    <Grid>
                        <FormHelperText error>
                            {validationError}
                        </FormHelperText>
                    </Grid>
                }

                <input
                    id="FileUpload"
                    type="file"
                    accept="image/*,.pdf"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />

                <input
                    id="FileUpload1"
                    type="file"
                    accept="image/*,.pdf"
                    style={{ display: 'none' }}
                    onChange={(e) => setFile(e.target.files?.[0], 1)}
                />

                <input
                    id="FileUpload2"
                    type="file"
                    accept="image/*,.pdf"
                    style={{ display: 'none' }}
                    onChange={(e) => setFile(e.target.files?.[0], 2)}
                />

            </GenericDialog>
            <GenericDialog
                open={openInfo}
                btnCancel={false}
                btnOk={false}
                maxWidth="md"
                fullWidth
                onClose={() => setOpenInfo(false)}>
                <img src="/assets/img/profile/identity/infos-identity.png" style={{ objectFit: "contain", width: "100%" }} />
            </GenericDialog>
        </>

    )
}

type Props = GenericDialogType & {
    userId: string
    onKycSubmitted?: () => void,
    type?: TypePiece
    documentId?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    typography500: {
        '&.MuiTypography-h4': {
            fontWeight: '500'
        }
    },
    buttonResp: {
        '&.MuiButton-contained': {
            padding: '0.5rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.875rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.68rem',
                padding: '0.5rem',
            }
        }
    }
}));

const i18nKeys = [
    "Page.MyFavorites.DownloadIdentity.Label",
    "Page.MyFavorites.DownloadIdentity.Message1",
    "Page.MyFavorites.DownloadIdentity.Message2",
    "Page.MyFavorites.DownloadIdentity.OnePiece.Label",
    "Page.MyFavorites.DownloadIdentity.ManyPiece.Label",
    "Page.MyFavorites.DownloadIdentity.PageOne.Label",
    "Page.MyFavorites.DownloadIdentity.PageTwo.Label",
    "Page.MyFavorites.DownloadBtn.Label",
    "Page.MyFavorites.DownloadIdentity.ValidationInProgress",
    "Page.MyFavorites.DownloadIdentity.FileToTight.Error",
    'Page.MyFavorites.DownloadIdentity.File.Size.Min.Error',
    'Page.MyFavorites.DownloadIdentity.File.Size.Max.Error',
    'Page.MyFavorites.DownloadIdentity.File.Empty.Error'
]