import { useMemo } from "react"

export const useMangoPayBrowserInfo = () => {
    return useMemo(() => ({
        screenHeight: window.screen?.availHeight || window.outerHeight,
        screenWidth: window.screen?.availWidth || window.outerWidth,
        javaEnabled: false,
        javascriptEnabled: true,
        timeZoneOffset: (new Date()).getTimezoneOffset().toString(),
        colorDepth: window.screen?.colorDepth || 0,
    }), [])
}
