import { TCustomerFavoriteSearchPayload } from "@ephatta/services/dist/models/Customer"
import { useEphattaQuery } from "../../../utils/useEphattaQuery"
import { useCustomerFavoriteService } from "./useCustomerFavoriteService"

export const useCustomerFavoriteSearch = (payload: TCustomerFavoriteSearchPayload, suspense?: boolean) => {
    const service = useCustomerFavoriteService()
    return useEphattaQuery(
        ["FavoriteList.Search", payload], 
        () => service.search(payload),
        {
            suspense,
            enabled: payload.UserId !== undefined
        }
    )
}