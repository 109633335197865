import { SVGProps } from "react"

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => {

    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M12.6591 6.25103C12.6591 2.94222 9.92341 0.245605 6.55013 0.245605C3.18427 0.245605 0.441162 2.93493 0.441162 6.25103C0.441162 9.55984 3.17685 12.2565 6.55013 12.2565C7.75858 12.2565 8.92254 11.9066 9.93082 11.2507L10.2348 11.0539L15.0241 15.7037C15.0315 15.711 15.0538 15.7256 15.1056 15.7256C15.2465 15.7256 15.506 15.6381 15.7506 15.3904C15.9879 15.1571 16.0917 14.902 16.0917 14.749C16.0917 14.7053 16.0843 14.6761 16.0769 14.6761L11.295 10.0409L11.5174 9.73476C12.2662 8.70713 12.6591 7.50459 12.6591 6.25103ZM6.63909 11.2142C3.85151 11.2142 1.58289 8.98408 1.58289 6.24374C1.58289 3.5034 3.85151 1.28052 6.63909 1.28052C9.42668 1.28052 11.6879 3.51069 11.6879 6.24374C11.6879 8.97679 9.42668 11.2142 6.63909 11.2142Z" fill="white"/>
        </svg>
    )
}
