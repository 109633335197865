import React from "react"
import { QueryErrorResetBoundary } from "react-query" 
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { GenericSuspenseError, GenericSuspenseErrorProps } from "./GenericSuspenseError"
import { GenericSuspense } from "./GenericSuspense"

export const GenericSuspenseWrapper = ({ children, fallback, fallbackError, GenericSuspenseErrorProps }: GenericSuspenseWrapperProps) => {

    const handleFallbackRender = ({ error, resetErrorBoundary, ...rest }: FallbackProps) => {
        // Todo : Should send this error information into the backend
        console.error(error)

        if (fallbackError) {
            return fallbackError({ error, resetErrorBoundary, ...rest })
        }
        
        return (
            <GenericSuspenseError 
                {...GenericSuspenseErrorProps}
                onRetryClick={() => resetErrorBoundary()}
                />
        )
    }

    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <ErrorBoundary
                    onReset={reset}
                    fallbackRender={handleFallbackRender}>
                    <GenericSuspense fallback={fallback}>
                        { children }
                    </GenericSuspense>
                </ErrorBoundary>

            )}
        </QueryErrorResetBoundary>
    )
}

export type GenericSuspenseWrapperProps = {
    children: React.ReactNode
    fallback?: React.ReactNode
    fallbackError?: ({ error, resetErrorBoundary }: FallbackProps) => React.ReactElement
    /**
     * Retry click cannot be override from here
     */
    GenericSuspenseErrorProps?: GenericSuspenseErrorProps
}
