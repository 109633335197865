import { useEnv } from "@ephatta/components"
import { TClientIdentity } from "@ephatta/services"
import { useTranslation } from "react-i18next"

export const useProfileMenusNotMember = (): Array<TProfileMenu> => {
    const { t } = useTranslation()
    const env = useEnv<TClientIdentity>()
    
    return [
        {
            label: t("Navbar.Menu.Profile.MyProfile.Label"),
            url: `/my-profile`
        },
        {
            label: t("Navbar.Menu.Profile.MyNetwork.Label"),
            url: '/my-network'
        },
        {
            label: t("Navbar.Menu.Profile.Logout.Label"),
            url: '/logout'
        }
    ]
}

export type TProfileMenu = {
    label?: string
    url?: string
}