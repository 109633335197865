import { useEphattaQuery } from "@hooks/utils";
import { useLoginService } from "./useLoginService";
import { TBackofficeIdentity, TClientIdentity } from "@ephatta/services"

export const useRuntimeEnv = <TData= TBackofficeIdentity | TClientIdentity | null>(suspense: boolean = true) => {
    const service = useLoginService()
    const { data } = useEphattaQuery(
        ['User.RuntimeEnv'],
        async () => {
            try {
                const data = await service.identity()
                return data as unknown as TData
            } catch (e) {
                return null
            }
        }, { 
            suspense, 
            refetchOnMount: false,
            // 3 minutes
            refetchInterval: 1000 * 60 * 3  
        }
    )
    return data
}