import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Home = React.lazy(() => import('./Home'))
const Complaint = React.lazy(() => import('./Complaint'))
const Comments = React.lazy(() => import('./Comments'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/opinion/:id',
        element: <Home />,
    },
    {
        path: '/opinion/complaint/:id',
        element: <Complaint />,
    },
    {
        path: '/opinion/comment/:id',
        element: <Comments />,
    },
]

export default Routes
