import { TextField } from '@mui/material'
import { forwardRef } from 'react'

export const PhoneNumberComponent = forwardRef((props, ref) => {

    return (
        <TextField
            {...props}
            color="secondary"
            inputRef={ref}
            fullWidth
            variant='standard'
            sx={{
                '& input' :  {
                    paddingTop: '5px',
                    paddingBottom: '4px',
                    fontSize: {xs:'12px', md : '15px'},
                }
            }}
        />
    )
})
export default PhoneNumberComponent
