import { useFormData, useTranslations } from "@ephatta/components"
import { TCustomerUpdatePayload } from "@ephatta/services"

export const useCustomerPersonalInformationFormData = (defaultValue: any) => {
console.log('useCustomerPersonalInformationFormData', defaultValue)
    const [ REQUIRED_FIELD ] = useTranslations(["Generic.FormData.RequiredField.label"])
    return useFormData<TPayload>({
        formData : {
            address: '',
            birthday: '',
            city: '',
            civility: 0,
            country: '',
            firstname: '',
            lastname: '',
            nationalityId: '',
            optimNewsletter: undefined,
            phone: '',
            zipCode: '',
            latitude: null,
            longitude: null,
            ...defaultValue
        },
        required: ['civility','nationalityId', 'address', 'zipCode', 'city', 'country', 'birthday', 'firstname', 'lastname'],
        validate(name, value, formData) {
            if(name === 'address' && value?.trim() === ""){
                return REQUIRED_FIELD
            }
        },
    })
}

type TPayload = TCustomerUpdatePayload & {
    phoneVerified?: boolean,
}
