import { useEffect, useState } from "react";
import moment from "moment";
import { useSEOGetAll } from ".";
import { TSEO } from "@ephatta/services";

export const useGetLocalSEO = () => {

    const localSeo = JSON.parse(localStorage.getItem(localStorageKey) || '{}')
    const { data } = useSEOGetAll()

    const [seo, setSEO] = useState<Array<TSEO>>(localSeo.data)

    useEffect(() => {
        if (data) {
            setSEO(data)
            localStorage.setItem(localStorageKey, JSON.stringify({
                date: moment().format('YYYY-MM-DD'),
                data
            }))
        }
    }, [data])

    return seo
}

const localStorageKey = 'MiCasa-Client-SEO'

