import { TCustomerContactImport } from '@ephatta/services';
import { useEphattaMutation } from '../../..';
import { useCustomerContactImportService } from './useCustomerContactImportService';

export const useCustomerContactImport = () => {
    const service = useCustomerContactImportService()
    return useEphattaMutation(
        (payload: TCustomerContactImport[]) => service.create(payload)
    , {
        successMessage: 'Import réalisé avec succès'
    })
} 