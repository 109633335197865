import React, { useEffect, createRef, UIEventHandler } from "react"
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, IconButton, useTheme} from "@mui/material";
import { ButtonSubmit, ButtonSubmitProps, GenericButton } from "../button";
import { CloseTwoTone } from "@mui/icons-material";

export const GenericDialog: React.FC<GenericDialogType> = ({ title,disableScrollTop, ...props }) => {

    const theme = useTheme()
    const outerElt = createRef<HTMLDivElement>()

    useEffect(() => {
        if(outerElt && outerElt.current && !disableScrollTop) {
            outerElt.current?.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [outerElt])

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...props}
            content=""
            sx={{
                '& .MuiDialog-paper': {
                    overflow: 'unset',
                    borderRadius:'5px',
                },
                
                ...props.sx,
            }}>

            {
                props.closable !== false &&
                <IconButton 
                    style={{ float: 'right' }}
                    sx={{
                        position: 'absolute',
                        right: {xs: -25, md: -35},
                        top: {xs: -30, md: -35},
                        color: theme.palette.primary.light
                    }}
                    onClick={() => props.onClose?.()}>
                    <CloseTwoTone />
                </IconButton>
            }

            {
                title &&
                <DialogTitle sx={{borderTopLeftRadius : '5px',borderTopRightRadius : '5px'}} id="alert-dialog-title" fontWeight={'bold'}>
                    {title}
                </DialogTitle>
            }
            
            <DialogContent ref={outerElt} onScroll={props.onBodyScroll}>
                <DialogContentText id="alert-dialog-description" color={'primary'}>
                    <Box p={(props.message || props.content) ? 2 : 0}>
                        { props.message || props.content }
                    </Box>
                </DialogContentText>
                
                { props.children }
            </DialogContent>

            {
                (props.btnCancel !== false || props.btnOk !== false) &&
                <DialogActions sx={{
                    borderBottomLeftRadius : '5px',
                    borderBottomRightRadius : '5px', 
                    '&.MuiDialogActions-root': {
                        justifyContent:{xs:'space-between', md:'center'},
                    }
                    }}>
                    {
                        props.btnCancel !== false && 
                        <Button 
                            color="primary" 
                            autoFocus 
                            {...props.btnCancel}
                            sx={{
                                backgroundColor: '#DEE2E5',
                                color: '#8A8A8A',
                                '&:hover':{
                                    backgroundColor: '#8A8A8A',
                                    color: '#DEE2E5',
                                },
                                ...props.btnCancel?.sx
                            }}
                            onClick={() => props.onClose?.()}>
                            { props.btnCancel?.label || 'Annuler' }
                        </Button>
                    }
                    {
                        props.btnOk !== false &&
                        <ButtonSubmit
                            color="secondary"
                            variant="contained"
                            {...props.btnOk}
                            onClick={() => props.onConfirm?.()}>
                            { props.btnOk?.label || 'Ok' }
                        </ButtonSubmit>
                    }
                    
                </DialogActions>
            }
            
        </Dialog>
    )
}

export type GenericDialogType = Omit<DialogProps, 'content'> & {
    title?: string;
    message?: string | React.ReactNode;
    disableScrollTop?: boolean;
    content?: React.ReactNode | string;
    open: boolean;
    btnOk?: ButtonSubmitProps | false
    btnCancel?: GenericButton | false
    closable?: boolean
    onClose?: () => void
    onConfirm?: () => void,
    onBodyScroll?: UIEventHandler<HTMLDivElement>
};
