import {Box, Button, Grid, Typography, useMediaQuery, useTheme} from "@mui/material"
import React, { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, Outlet, useLocation, useNavigate } from "react-router";
import { MainLoader, useUrlFilterValue, GenericDialog, useEnv, useGetAllStaticPage, useAxeptioSetting, useEmailConfirmCheck, useDebounce, useRuntimeEnv, useTranslations, useRedirectHard } from "@ephatta/components";
import { SignUpPartial } from "../../pages/account/components/SignUpPartial";
import { LoginPartial } from "../../pages/account/components/LoginPartial";
import { CompleteProfilPartial } from "../../pages/account/components/CompleteProfilPartial";
import FooterSection from "./components/FooterSection";
import { TClientIdentity } from "@ephatta/services";
import { EmailResend } from "../../pages/registration/widgets/EmailResend";
import HomeJumbotron from "../../pages/home/widgets/HomeJumbotron";
import { NavBar } from "./components/NavBar";
import { useHideFooterForCertainRoutes } from "./hooks/useHideFooterForCertainRoutes";
import SocialMediaHeaderBox from "./components/SocialMediaHeaderBox";
import _ from "lodash";
import { PageError } from "./components/PageError";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

export const MainLayout = () => {

    const { login, signup, completeProfil, ...rest } = useUrlFilterValue()
    const path = useMemo(() => window.location.pathname, [window.location.pathname])
    const location = useLocation()
    const navigate = useNavigate()
    const redirect = useRedirectHard()
    const theme = useTheme()
    const env = useRuntimeEnv<TClientIdentity>()
    const [openMailPopup, setOpenMailPopup] = useState(false)
    const {data: staticPages} = useGetAllStaticPage(true)
    const {emailConfirmed} = useEmailConfirmCheck()
    const debounceScroll = useDebounce()
    useAxeptioSetting()

    const [openAdvertFlag, setOpenAdvertFlag] = useState(false)

    const [
        FIRST_CONNECTION_TITLE,
        FIRST_CONNECTION_EMPTY_ADVERTS,
        FIRST_CONNECTION_NON_EMPTY_ADVERTS,
        FIRST_CONNECTION_BUTTON,
        FLAG_AVAILABILITY_MESSAGE,
        FLAG_AVAILABILITY_UPDATE_BUTTON
    ] = useTranslations(i18n)

    const shouldFixFooter = () => {
        return document.documentElement.scrollHeight <= document.documentElement.clientHeight
    }
    const [footerFixed, setFooterFixed] = useState(shouldFixFooter())

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");

    const classes = useStyle()

    const redirectToRegisterNotMicasa = useMemo(() => {

        if (!env || env.isMember) {
            return true
        }

        if (!(nonMemberRoutes as Array<string>)?.some((item: string)=>matchPath?.(item, location.pathname))
            && !/^\/landing/.test(location.pathname)
            && !staticPages?.some((item) => `/${item.url}` === location.pathname)) {
            return false
        }

        return true
    }, [env, location.pathname, staticPages])

    const isFooterHidden = useMemo(()=>{
        return ((NoFooterMobileRoutes as Array<string>)?.some((item: string)=>matchPath?.(item, path)) && isSmall)
              || (NoFooterRoutes as Array<string>)?.some((item: string)=>matchPath?.(item, path))
    },[NoFooterMobileRoutes, path, isSmall, NoFooterRoutes])

    const isHeaderHidden = useMemo(()=>{
        return (NoHeaderRoutes as Array<string>)?.some((item: string)=>matchPath?.(item, path))
    },[NoHeaderRoutes, path])

    useEffect(() => {
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        if(env && emailConfirmed === false && location.pathname !== '/email-confirm') {
            setOpenMailPopup(true)
        }
        if(env && emailConfirmed === true){
            window.location.reload()
        }
        if(location.pathname === '/email-confirm'){
            setOpenMailPopup(false)
        }
    }, [env, location.pathname, emailConfirmed])

    useEffect(()=>{
        if(env && env.adverts?.some(item => item.flagAvailability) && RoutesWithAdvertFlag?.some((item: string)=>matchPath?.(item, path))){
            setOpenAdvertFlag(true)
        }
    },[env, path, RoutesWithAdvertFlag])

    useEffect(() => {
        if (!redirectToRegisterNotMicasa) {
            navigate('/registration-not-micasa')
        }
    }, [redirectToRegisterNotMicasa])

    const regulateFooter = useCallback(() => {
        setTimeout(() => {
            const footerFixed = shouldFixFooter()
            setFooterFixed(footerFixed)
        }, 250)
    }, [])

    const handleFallbackRender = ({ error, resetErrorBoundary }: FallbackProps) => {
        // Todo : Should send this error information into the backend
        console.error(error)
        
        return (
            <PageError onRetryClick={() => resetErrorBoundary()} />
        )
    }

    const handleSuspenseFinished = () => {
        regulateFooter()
    }

    const handleScroll = useCallback(() => {
        debounceScroll(regulateFooter, 100)
    }, [])

    const handleWelcomeDialogClose = () => {
        localStorage.setItem(FIRST_CONNECTION_KEY, 'true')
    }

    return (
        <React.Fragment>
            <Box sx={{
                position: 'sticky',
                top: 0,
                zIndex: 999,
            }}>

                <SocialMediaHeaderBox
                    isInsideContainer={location.pathname !== '/' || !_.isEmpty(env)}
                    hideLogo={_.isEmpty(env) && location.pathname === '/'}
                    hideHelpButton={_.isEmpty(env) && location.pathname === '/'}
                    showSocialMedias={_.isEmpty(env) && location.pathname === '/'}
                />

                {
                    env && !isHeaderHidden &&
                    <NavBar />
                }
            </Box>

            {
                !env && location.pathname === '/' &&
                <HomeJumbotron />
            }

            <Box>
                <ErrorBoundary
                    fallbackRender={handleFallbackRender}>
                    <Suspense fallback={<MainLoader />}>
                        <div ref={(ref) => {
                            handleSuspenseFinished()
                        }}>
                            <Outlet />
                        </div>
                    </Suspense>
                </ErrorBoundary>
            </Box>
            <Box className={`${footerFixed && classes.footerPinned}`} display={isFooterHidden ? "none": "block"}>
                <FooterSection />
            </Box>


            <GenericDialog 
                open={openMailPopup}
                onClose={() => setOpenMailPopup(false)}
                btnOk={false}
                btnCancel={false}>
                <EmailResend />
            </GenericDialog>

            <GenericDialog
                open={env?.firstConnection === true && !localStorage.getItem(FIRST_CONNECTION_KEY)}
                title={FIRST_CONNECTION_TITLE}
                message={env?.adverts?.length === 0 ? FIRST_CONNECTION_EMPTY_ADVERTS : FIRST_CONNECTION_NON_EMPTY_ADVERTS}
                onConfirm={() => {
                    handleWelcomeDialogClose()
                    redirect('/my-profile')
                }}
                onClose={() => {
                    handleWelcomeDialogClose()
                    navigate(location.pathname)
                }}
                btnCancel={false}
                btnOk={{
                    label: FIRST_CONNECTION_BUTTON
                }}
            />
            <GenericDialog open={openAdvertFlag} onClose={() =>setOpenAdvertFlag(false)} btnOk={false} btnCancel={false}>
                <Box display="flex" alignItems="center" flexDirection={"column"} rowGap="20px">
                        <Typography variant="button" textTransform={"none"} color="primary" sx={{ textAlign: "center" }}>
                            {FLAG_AVAILABILITY_MESSAGE}
                        </Typography>
                        <Link to="/my-announcements">
                            <Button variant="contained" onClick={() =>setOpenAdvertFlag(false)}  color="primary" sx={{ alignSelf: "center" }}>
                                {FLAG_AVAILABILITY_UPDATE_BUTTON}
                            </Button>
                        </Link>
                </Box>    
            </GenericDialog>   
            
        </React.Fragment>
        
    );
}

const FIRST_CONNECTION_KEY = 'FirstConnection'

const i18n = [
    'Page.MainLayout.User.FirstConnection.title',
    'Page.MainLayout.User.FirstConnection.EmptyAdverts.description',
    'Page.MainLayout.User.FirstConnection.NonEmptyAdverts.description',
    'Page.MainLayout.User.FirstConnection.Button.label',
    'Page.MainLayout.User.FLag.Availability.Message',
    'Page.MainLayout.User.FLag.Availability.UpdateButton.Label'
]

const useStyle = makeStyles(() => ({
    footerPinned: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0
    }
}))

const nonMemberRoutes = [
    '/logout',
    '/registration-not-micasa',
    '/email-confirm',
    '/my-profile',
    '/my-network',
    '/public-profile/:id',
    '/maintenance'
]

const RoutesWithAdvertFlag = [
    '/find-casa',
    '/advert/create',
    '/my-network',
    '/my-inbox'
]

const NoFooterMobileRoutes = [
    '/my-inbox/:id',
]

const NoFooterRoutes = [
    '/maintenance'
]

const NoHeaderRoutes = [
    '/maintenance'
]
