import { useEphattaMutation } from "@hooks/utils"
import { useAdminTransactionsServices } from "./useAdminTransactionsServices"

export const useAdminSendLog = () => {

    const service = useAdminTransactionsServices()
    
    return useEphattaMutation((payload: TLogPayload) => {
        return service.sendLog(payload)
    })
}

type TLogPayload = {
    message: string,
    level: string,
    application: string
}
