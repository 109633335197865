import { useEphattaMutation } from "hooks/utils"
import { useCallback } from "react"
import { useMangoPayRequest } from "../useMangoPayRequest"


export const useMangopayCreateKycPage = () => {

    const mangoPayRequest = useMangoPayRequest()

    const createKycPage = useCallback((payload: TMangoPayKycPagePayload) => {
        return mangoPayRequest<TMangoPayKycPagePayload>({
            apiUrl: `users/${payload.userId}/kyc/documents/${payload.KYCDocumentId}/pages`,
            payload: {
              File: payload.File,
            }
        })
    }, [])
    return useEphattaMutation(createKycPage)
}


type TMangoPayKycPagePayload = {
    userId?: string,
    KYCDocumentId?: string,
    File?: string,
}