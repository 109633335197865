import { TResetPasswordPayload } from "@ephatta/services"
import { useTranslation } from "react-i18next"
import { useEphattaMutation } from "../.."
import { useLoginService } from "./useLoginService"

export const useResetPassword = () => {
    const loginService = useLoginService()
    const { t } = useTranslation()

    return useEphattaMutation(
        (payload: TResetPasswordPayload) => loginService.resetPassword({
            ...payload,
            applicationId: parseInt(process.env.REACT_APP_ID!)
        })
    , {
        successMessage: t('Client.Reset.Success.Label')
    })
}
