import { useTranslation } from "react-i18next"
import { useEphattaMutation } from "../.."
import { useLoginService } from "./useLoginService"

export const useForgotPasswordRequest = () => {
    const loginService = useLoginService()
    const { t } = useTranslation()
    
    return useEphattaMutation(({email,applicationId}:ForgotPasswordPayload) => loginService.forgotPassword({
        email,
        applicationId
    }), {
        successMessage: t('Client.ForgotPassword.EmailSent.Label')
    })
}
type ForgotPasswordPayload = {
    email: string
    applicationId: number
}