import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const MyPublic = React.lazy(() => import('./Dashboard'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/public-profile/:id',
        element: <MyPublic />,
    }
]

export default Routes
