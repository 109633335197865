import { TCreateBankPayload } from "@ephatta/services"
import { useEphattaMutation, useTranslations } from "hooks"
import { useMangoPayBankService } from "./useMangoPayBankService"

export const useMangoPayCreateBank = () => {

    const service = useMangoPayBankService()
    const [ERROR_MESSAGE] = useTranslations(['Create.Error.Message'])
    
    return useEphattaMutation(
        (data: TCreateBankPayload) => {
            return service.createBank(data)
        },
        {
            errorMessage: ERROR_MESSAGE
        }
    )
}
