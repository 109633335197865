import { EphattaRouteProps } from "@ephatta/components";
import React from "react"
import AdvertDetailsAccesLayer from "./AdvertDetailsAccesLayer";

const AdvertAccessLayer = React.lazy(() => import("./AdvertAccessLayer"))
const AdvertCreate = React.lazy(() => import("./AdvertCreate"))
const AdvertAvailability = React.lazy(() => import("./AdvertAvailability"))
const AdvertSubscription = React.lazy(() => import("./AdvertSubscription"))
const AdvertDetails = React.lazy(() => import('./AdvertDetails'))
const MyAnnouncements = React.lazy(() => import('./MyAnnouncements'))

const Routes: Array<EphattaRouteProps> = [

    {
        path: '/',
        element: <AdvertAccessLayer />,
        children: [
            {
                path: '/advert/create/:id',
                element: <AdvertCreate />
            },
            {
                path: '/advert/availability/:id',
                element: <AdvertAvailability />
            },
            {
                path: '/advert/subscription/:id',
                element: <AdvertSubscription />
            },
        ]
    },
    {
        path: '/advert/create',
        element: <AdvertCreate />
    },
    {
        path: '/my-announcements',
        element: <MyAnnouncements />
    },
    {
        path: '/',
        element: <AdvertDetailsAccesLayer />,
        children: [
            {
                path: '/advert/:id',
                element: <AdvertDetails />
            }
        ]
    },
]

export default Routes