import { TAdvertSubscriptionAssignationUpdatePayload } from '@ephatta/services'
import { useEphattaMutation } from '../../..'
import { useAdvertSubscriptionAssignmentService } from './useAdvertSubscriptionAssignmentService'

export const useAdvertSubscriptionAssgignmentUpdate = () => {
    const service = useAdvertSubscriptionAssignmentService()
    return useEphattaMutation(
        ({id, payload}: updatePayload) => service.update(id, payload),
        {
            log(data?, variables?) {
                return `Abonnement ${variables?.id} modifié pour l'annonce <a href="/advert/${variables?.payload?.advertId}">${variables?.payload?.advertId}</a> à la date ${variables?.payload?.endDate} et la renouvellement a été ${variables?.payload?.repetionEnabled ? "activé" : "désactivé"}`
            },
        }
    )
}
type updatePayload ={
    id: number,
    payload: TAdvertSubscriptionAssignationUpdatePayload
}