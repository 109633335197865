import { useTranslations } from "@ephatta/components"
import { Box, Grid, Typography } from "@mui/material"
import './styles/quotes.css'


export const QuotesComponent = (props: Props) => {

    const [
        MESSAGE,
    ] = useTranslations(i18n)

    return (
        <Box display="flex" flexDirection={props?.flexDirection || "row"} alignItems="center">
            <Box>
                <img src="/assets/img/announcement/quotes_avatar.png" />
            </Box>
            <Box ml="13px">
                {
                    props?.message &&
                    <Typography color="primary" className="quote-text" fontWeight="bold">
                        <span dangerouslySetInnerHTML={{ __html: props?.message }}></span>
                    </Typography>
                }


                {
                    !props?.message &&
                    <Typography color="primary" className="quote-text" fontWeight="bold">
                        {MESSAGE}
                    </Typography>
                }
            </Box>
        </Box>
    )
}
type Props = {
    message?: string
    flexDirection?: "row" | "column"
}
const i18n = [
    "Announcement.AvailabilityAndCancelation.Message",
]