import React from 'react'
import { StaticDateRangePicker as MUIStaticDateRangePickers, StaticDateRangePickerProps } from "@mui/lab";
import { styled } from "@mui/styles";

export const StaticDateRangePicker = styled((props: StaticDateRangePickerProps<Date>) => (
    <MUIStaticDateRangePickers {...props} />
))(({ theme }) => ({
    '& .css-1xhj18k': {
        justifyContent: 'center'
    },
    '& button.MuiDateRangePickerDay-dayInsideRangeInterval': {
        background: '#DEE2E5 !important',
    },
    '& button.Mui-selected': {
        background: theme.palette.secondary.main,
    },
    '& button': {
        borderRadius: '0px !important'
    },
    '& .MuiIconButton-edgeStart, & .MuiIconButton-edgeEnd': {
        border: '1px solid #DEE2E5'
    },
    '& .MuiTypography-subtitle1': {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    '& .css-1tape97:not(:last-of-type)': {
        borderRight: 'none'
    },
    '& .css-170k9md': {
        minHeight: '255px !important',
    },
    '& .css-f7iyql .MuiTypography-root': { // month year 
        fontSize: '17px'
    }
}));