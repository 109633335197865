import React, { useCallback, useEffect } from 'react'
import { useMemo } from 'react'
import { useLocation } from "react-router-dom"
import { useDebounce, useEmailConfirmCheck, useMetadata, useNotificationCount, useRedirectHard, useRuntimeEnv, useRuntimeEnvInvalidate } from '../../hooks'
import { Redirection } from '../redirection/Redirection'
import { SnackbarList } from '../snackbar/SnackbarList'
import { RouteObject, matchPath, useNavigate } from "react-router-dom"
import { Routes } from './Routes'
import { useGetLocalSEO } from 'hooks/data/seo/useGetLocalSEO'
import { useRouterMetadataSync } from '@hooks/url/useRouterMetadataSync'

/**
 * Component that initialize user environments
 */
export const Shell = (props: ShellProps) => {

    const path = useMemo(() => window.location.pathname, [window.location.pathname])
    const env = useRuntimeEnv()
    useRouterMetadataSync()
    const { invalidateQuery: invalidateNotificationQuery } = useNotificationCount()
    const { invalidateQuery: invalidateEmailConfirmCheckQuery } = useEmailConfirmCheck()
    const location = useLocation()
    const navigate = useNavigate()
    const redirectHard = useRedirectHard()
    const debounce = useDebounce()
    const { setDocumentMetadata } = useMetadata()
    const seo = useGetLocalSEO()
    const invalidateEnv = useRuntimeEnvInvalidate()

    useEffect(() => {

        checkMappingRedirection()

        const route: EphattaRouteProps = props.routes[0].children?.find((item) => matchPath(item.path!, location.pathname)) || {}
        route?.disableScrollTopOnChange !== true && window.scroll({ top: 0, behavior: 'smooth' })
        route?.path && checkAutorizedRoute(route?.path as string)
        checkMetadata(location.pathname as string)
        invalidateNotificationQuery()
        invalidateEmailConfirmCheckQuery()
        invalidateEnv()
        if (location.hash) {
            debounce(() => {
                const el = document.getElementById(location.hash.replace('#', ''))
                el && window.scrollTo({ top: el?.offsetTop - HASH_SCROLL_POSITION, behavior: 'smooth' })
            }, 750)
        }
    }, [location])

    useEffect(() => {
        checkAutorizedRoute(path)
    }, [env, path])

    const checkMetadata = (path?: string) => {
        const seoData = seo?.find((item) => matchPath(`/${item.url}`, path!))
        setDocumentMetadata({
            path: seoData?.url || '',
            description: seoData?.metaDescription || process.env.REACT_APP_META_DESCRIPTION,
            title: seoData?.metaTitle || process.env.REACT_APP_META_TITLE,
            keywords: seoData?.metaKeywords || process.env.REACT_APP_META_KEYWORDS
        })
    }

    const checkMappingRedirection = useCallback(() => {
        if (!props.mappingRoutes) {
            return;
        }
        Object.keys(props.mappingRoutes).forEach((path) => {
            const to = props.mappingRoutes?.[path]
            const match = matchPath(path, location.pathname)
            if (match) {
                let newUrl = to
                Object.keys(match.params).forEach((key) => {
                    const value = match.params[key]
                    newUrl = newUrl?.replace(`:${key}`, value || '')
                })
                navigate(newUrl!, { replace: true })
            }
        })
    }, [props, location])

    const checkAutorizedRoute = (_path: string) => {
        const isAuthorized = isRouteAuthorized(_path)

        if (process.env?.REACT_APP_MAINTENANCE === "true" && _path !== "/maintenance") {
            navigate("/maintenance")
        }

        if (env === null && !isAuthorized) {
            if (_path !== props.loginPath) {
                redirectHard(`${props.loginPath}?redirectUrl=${location.pathname}${location.search}`)
            }
            return;
        }

        if (!isAuthorized) {
            navigate(props.initialPath || '/')
            return;
        }
    }

    const isRouteAuthorized = useCallback((_path: string) => {

        if (
            props.loggedRoutes === '*'
            && !props.loggedOutRoutes?.includes(_path)
            && env === null) {
            return false
        }

        if (props.loggedRoutes !== '*' &&
            (props.loggedRoutes as Array<string>)?.some((item: string) => matchPath?.(item, _path))
            && env === null) {
            return false
        }

        if (env && props.loggedUnAuthorizedRootes?.some((item) => _path.includes(item))) {
            return false
        }

        return true
    }, [env])

    if (env === null && !isRouteAuthorized(path)) {
        return (<React.Fragment></React.Fragment>)
    }

    return (
        <React.Fragment>
            <Routes routes={props.routes} />
            <SnackbarList limit={3} />
            <Redirection />
        </React.Fragment>
    )
}
const HASH_SCROLL_POSITION = 250
export type ShellProps = {
    routes: Array<EphattaRouteProps>
    /** 
     * Routes that authorized when user is logged 
     */
    loggedRoutes?: Array<string> | '*'
    /** 
     * Routes that authorized when user is logged out
     * It work if loggedRoutes is "*"
     */
    loggedOutRoutes?: Array<string>
    /** Routes that's are not allowed when user is logged */
    loggedUnAuthorizedRootes?: Array<string>
    /** Initial routes */
    initialPath?: string
    /** Path where redirect if current route is unAuthorized */
    loginPath: string
    /**
     * Redirection mapping for route
     * example : 
     * {
     *      "estate/:id": "advert/:id"
     * }
     */
    mappingRoutes?: Record<string, string>
}

export type EphattaRouteProps = RouteObject & AppRouteMetadata & {
    disableScrollTopOnChange?: boolean
    /** Flag telling that the route is private */
    private?: boolean

    forceSEO?: boolean
    children?: EphattaRouteProps[]
}

export type AppRouteMetadata = {
    title?: string
    description?: string
    type?: string
    keywords?: string
    image?: string
}
