import { TClientIdentity } from "@ephatta/services"
import { useEnv } from "hooks/env"
import { useEphattaQuery } from "hooks/utils"
import { useLoginService } from "./useLoginService"


export const useEmailConfirmCheck = () =>{
    const loginServices = useLoginService()
    const env = useEnv<TClientIdentity>()
    const { data, invalidateQuery } = useEphattaQuery(
        ["Identity.Get.EmailConfirmed"],
        () => loginServices.identity(),
        {
            refetchOnWindowFocus: true,
            hideErrorMessage: true,
            enabled: env?.emailConfirmed === false
        }
    )
    return {
        emailConfirmed: (data as any)?.emailConfirmed as boolean,
        invalidateQuery
    }
}

