import { useEphattaQuery } from "../../../utils/useEphattaQuery"
import { useCustomerFavoriteService } from "./useCustomerFavoriteService"

export const useCustomerFavoriteList = (id: number, suspense?: boolean) => {
    const service = useCustomerFavoriteService()
    return useEphattaQuery(
        ["FavoriteList.List", id], 
        () => service.list(id),
        {
            suspense
        }
    )
}