import {Box, InputBase, InputBaseProps, Stack} from "@mui/material";
import {ReactElement} from "react";

const CustomInput = (props: { icon?: ReactElement, borderColor?: string } & Partial<InputBaseProps>) => {
    return (
        <>
            <Box
                sx={{
                    borderRadius: '3px',
                    border: `solid 1.5px ${props?.borderColor || '#DFE2E6'}`,
                }}
                paddingX={2}
                paddingY={0.5}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                >
                    <InputBase
                        {
                            ...props
                        }
                    />
                    {
                        props.icon
                    }
                </Stack>
            </Box>
        </>
    )
}


export default CustomInput;