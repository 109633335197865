import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Details = React.lazy(() => import('./Details'))
const Adverts = React.lazy(() => import('./Adverts'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/landing/advert/:name',
        element: <Adverts />,
    },
    {
        path: '/landing/content/:name',
        element: <Details />,
    },
]

export default Routes
