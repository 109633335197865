import {Box, Checkbox, CheckboxProps, Stack, Typography, useMediaQuery} from "@mui/material";

type TProps = CheckboxProps & {
    label: string
    checked: boolean
}
const CheckBoxWithLabel = ({label, ...props} : TProps) => {

    // ====== MEDIA QUERY ===========
    const isSmall = useMediaQuery("(max-width:600px)");
    const isMedium = useMediaQuery("(max-width:900px)");
    // ======= END MEDIA QUERY ======

    return (
        <Box>
            <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
            >
                <Checkbox
                    color={'secondary'}
                    sx={{
                        paddingLeft: 0
                    }}
                    {...props}
                />
                <Typography
                    fontSize={isSmall ? '12px' : '18px'}
                    className={'display-inline-block'}
                    fontWeight={'500'}
                    color={'primary'}
                    component={'label'}
                    htmlFor={props.id}
                    sx={{cursor:"pointer", userSelect:"none"}}
                >
                    {label}
                </Typography>
            </Stack>
        </Box>
    )
}

export default CheckBoxWithLabel;