import {
    Box,
    Dialog,
    DialogContent, DialogProps,
    DialogTitle, Divider, Grid, Stack, Typography, TypographyProps,
    useMediaQuery,
    useTheme
} from "@mui/material";

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

export type CustomDialogProps = {
    title?: string;
    children: React.ReactNode;
    titleProps?: TypographyProps & { alignFlex: 'flex-start' | 'center' | 'flex-end' , fontSize?:string};
    onClose?: () => void;
} & Partial<DialogProps>

const CustomDialog = (props: CustomDialogProps) => {
    const { title, children, open, onClose, titleProps, ...others } = props;
    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            open={open || false}
            aria-labelledby="responsive-dialog-title"
            {...others}
            sx={{
                '& [role=dialog]': {
                    overflowY: 'inherit'
                }
            }}
        >
            <Box>
                <Box
                    className={'transition-all-2s'}
                    sx={{
                        position: 'relative',
                        boxSizing: 'border-box',
                        border: `4px solid rgba(0,0,0,0)`,
                        '&:hover': {
                            borderRadius: '5px',
                            // border: isFullScreen ? 'none' : `4px solid ${theme.palette.primary.main}`
                        }
                    }}
                >
                    <DialogTitle id="responsive-dialog-title"
                        sx={{
                            position: 'relative',
                            paddingX: 2,
                            paddingBottom: 2.5,
                            backgroundColor: 'white'
                        }}
                        align={'center'}
                    >
                        <Stack
                            direction={'row'}
                            justifyContent={titleProps?.alignFlex || 'center'}
                            alignItems={'center'}
                        >
                            <Typography
                                fontSize={isFullScreen ? "17px" : "25px"}
                                fontWeight={'bold'}
                                {...titleProps}
                            >
                                {title}
                            </Typography>
                            {
                                isFullScreen &&
                                <Box
                                    className={'transition-all-2s zoom-in-on-hover zoom-in-on-press'}
                                    sx={{
                                        position: 'absolute',
                                        right: '-30px',
                                        top: '-30px',
                                        color: 'white'
                                    }}
                                    onClick={onClose}
                                >
                                    <CloseTwoToneIcon/>
                                </Box>
                            }
                        </Stack>
                    </DialogTitle>
                    <Divider sx={{ borderColor: '#DEE2E5' }}/>
                    <Grid paddingY={3} paddingX={1}>
                        {
                            children
                        }
                    </Grid>
                </Box>
                {
                    !isFullScreen &&
                    <Box
                        className={'transition-all-2s zoom-in-on-hover zoom-in-on-press'}
                        sx={{
                            position: 'absolute',
                            right: '-30px',
                            top: '-30px',
                        }}
                        onClick={onClose}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.3536 3.56212L14.7071 3.20857L14.3536 2.85502L13.145 1.64645L12.7914 1.29289L12.4379 1.64645L8 6.08432L3.56212 1.64645L3.20857 1.29289L2.85502 1.64645L1.64645 2.85502L1.29289 3.20857L1.64645 3.56212L6.08432 8L1.64645 12.4379L1.29289 12.7914L1.64645 13.145L2.85502 14.3536L3.20857 14.7071L3.56212 14.3536L8 9.91568L12.4379 14.3536L12.7914 14.7071L13.145 14.3536L14.3536 13.145L14.7071 12.7914L14.3536 12.4379L9.91568 8L14.3536 3.56212Z" fill="white" stroke="white" />
                        </svg>

                    </Box>
                }
            </Box>
        </Dialog>
    )
}

export default CustomDialog;