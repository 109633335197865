import { useEnv, useFormData } from "@ephatta/components"
import { TClientIdentity, TCustomerCooptationCreatePayload } from "@ephatta/services"

export const useCustomerCooptationFormData = () => {

    const env = useEnv<TClientIdentity>()

    return useFormData<TCustomerCooptationCreatePayload>({
        formData: {
            fromId: env.userId,
            toEmail: '',
            type: env.isMember ? 'Invitation' : 'Demande'
        },
        required: [
            'toEmail',
        ]
    })
}
