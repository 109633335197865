import { useEnv } from "@hooks/env";
import { useMutation } from "react-query";
import { useEventService } from "./useEventService";

export const useEventInsert = () => {
    const env = useEnv() as any
    const services = useEventService()
    return useMutation(
        ({ message, userId }: { message: string; userId?: string }) => services.insert(userId || env?.userId, message)
    );
};
